import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrderState, OrderAdapter } from '../state/order.state';

export const selectOrderState = createFeatureSelector<OrderState>('Order');

export const getOrderEntities = (state: OrderState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getOrderLoading = (state: OrderState) => state.loading;

export const getOrderUploading = (state: OrderState) => state.uploading;
export const getOffset = (state: OrderState) => state.offset;
export const getLimit = (state: OrderState) => state.limit;
export const getDataLength = (state: OrderState) => state.dataLength;
export const getSearch = (state: OrderState) => state.search;


const {
  
  selectEntities,
  selectAll,
  selectTotal
} = OrderAdapter.getSelectors();


export const selectAllData = createSelector(
  selectOrderState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectOrderState,
  selectEntities
);

export const selectOffset = createSelector(
  selectOrderState,
  getOffset
);
export const selectDataLength = createSelector(
  selectOrderState,
  getDataLength
);

export const selectLimit = createSelector(
  selectOrderState,
  getLimit
);

export const selectDataOrderedById = createSelector(
  selectAllData,
  (data: any) => {
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);
export const selectOrderById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    return data.filter(Order=>Order.id == id);
  }
);
export const selectSearch = createSelector(
  selectOrderState,
  getSearch
);