import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class SharedService {
  public innerWidth: number;
  public productDomian :any=[];
  public selectedStoreValue : any;
  public userSharedLink : string;
  public companyTheme : string;
  public templateType : string;
  public companyInfo: any;
  public subHeaderTheme : string;
  public removeBanner : boolean;
  landingPagetemplate: any;
  public loginInfo: { user_info: any; jwt_token: any; context_info: any; };
  constructor(){
    this.productDomian = [['delivery_user_id.storex_sub_domain', '=',  environment.shopUrl]]
  }
}