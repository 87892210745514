import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderLineState } from 'src/app/stores/order-line/store';
import * as OrderLineSelector from 'src/app/stores/order-line/store/selectors'
import * as OrderLineActions from 'src/app/stores/order-line/store/actions'
import { SharedService } from 'src/app/services/sharedService';
import { ModalController, NavController } from '@ionic/angular';
import { QuickOrderFormComponent } from 'src/app/components/quick-order-form/quick-order-form.component';
import { SettingPage } from '../setting/setting.page';
import { ModernTemplateLoginComponent } from 'src/app/components/modern-template/modern-template-login/modern-template-login.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.page.html',
  styleUrls: ['./cart.page.scss'],
})
export class CartPage implements OnInit,OnDestroy {
  destroyed$ = new Subject<any>();
  cartItems = [];
  lang: any;
  companyTheme: string;
  productVariantsIds = []
  @Input() showBackButton
  constructor(
    private orderLineStore : Store<OrderLineState>,
    private translate : TranslateService,
    public sharedService: SharedService,
    public navCtrl: NavController,
    private modalCtrl: ModalController,

  ) { }
  ngOnDestroy(): void {
    this.destroyed$.next({})
    this.destroyed$.unsubscribe()
  }

  ngOnInit() {
    this.lang = this.translate.currentLang;
    this.companyTheme = this.sharedService.companyTheme

  }

  ionViewWillEnter(){
    this.orderLineStore.select(OrderLineSelector.selectCartOrderLines).pipe(takeUntil(this.destroyed$)).subscribe(data =>{
      if(data && data.length > 0){
          for(let cartItem of data){
            if(cartItem.qty == 0){
              this.removeProduct(cartItem)
            }
          }
          data = data.filter(cart => cart.qty != 0)
          this.cartItems = data
          if(this.cartItems && this.cartItems.length > 0){
            for(let cartItem of this.cartItems){
              this.productVariantsIds.push(cartItem.product_id)
            }
          }
      }
    })
  }

  removeProduct(cartItem){
    this.cartItems=this.cartItems.filter(cart=>cart.id!=cartItem.id)
    this.orderLineStore.dispatch(new OrderLineActions.RemoveLocalHttp([cartItem.id]))
   
  }
  addOrder(){
    if(this.showBackButton){
      this.modalCtrl.dismiss()
      const modal = this.modalCtrl.create({
        component: QuickOrderFormComponent,
        cssClass: 'fullscreen',
        componentProps: {
          productIds: this.cartItems,
          
        }
      })
      modal.then(mod => mod.present())
    }
    else{

      const modal = this.modalCtrl.create({
        component: QuickOrderFormComponent,
        cssClass: 'quick-order-form-style',
        componentProps: {
          productIds: this.cartItems,
          
        }
      })
      modal.then(mod => mod.present())
    }
  }

  openHome(){
    if(this.showBackButton){
      this.modalCtrl.dismiss()
      
    }
    this.navCtrl.navigateRoot('')
  }

  openLoginForm(){

    const modal = this.modalCtrl.create({
      component: ModernTemplateLoginComponent,
      componentProps:{segmantValue:'login'}
    })
    modal.then(mod => {
      mod.present()
    })
  }

  login(){
    if(this.showBackButton){
      this.modalCtrl.dismiss()
      const modal = this.modalCtrl.create({
        component: SettingPage,
        mode:'ios',
        cssClass: 'fullscreen',
        componentProps:{
          showBackButton: true
        }
      })
      modal.then(mod => mod.present())
    }
    else{
      this.navCtrl.navigateRoot('/settings')
    }
  }

  back(){
    this.modalCtrl.dismiss()
  }
}
