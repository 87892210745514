import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { CategoryState } from 'src/app/stores/category/store';
import * as categorySelector from 'src/app/stores/category/store/selectors'
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss'],
})
export class CategoriesPage implements OnInit {
  categories: any;
  env = environment
  selectedCategoryId: any;
  @Input() showBackButton
  constructor(
    private categoryStore: Store<CategoryState>,
    private modalCtrl: ModalController,
    public platform: Platform,
    public sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.categoryStore.select(categorySelector.selectParentCategory()).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.categories = data
    })
  }

  openProducts(categoryId){
    this.selectedCategoryId = categoryId
  }

  back(category?){
    this.selectedCategoryId = null
    if(category){
      this.modalCtrl.dismiss()
    }
  }

}
