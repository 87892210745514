<ion-toolbar mode="ios">
  <ion-title slot="start">{{title | translate}}</ion-title>
  <ion-buttons  slot="start">
    <ion-button (click)="openHomePage()" slot="start">
      <ion-icon [src]="getIconSrc()"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons  slot="end" *ngIf="showCartButton">
    <ion-button (click)="openCartPage()" slot="end">
      <div class="circle">
        <ion-icon src="../../../../assets/icon/cart_icon.svg"></ion-icon>
      </div>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end" *ngIf="showBackButton">
    <ion-button (click)="back()" slot="end">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
