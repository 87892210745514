import * as fromOrderLineActions from '../actions';

import { OrderLineState, initialState, OrderLineAdapter } from '../state/order-line.state';

export function OrderLineReducer(
  state = initialState,
  action: fromOrderLineActions.OrderLineActions
): OrderLineState {
  switch (action.type) {   
    
    case fromOrderLineActions.OrderLineActionsType.ADD_LOCAL_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrderLineActions.OrderLineActionsType.ADD_LOCAL_SUCCESS_HTTP: {
      if(state.entities[action.payload.id]){
        let changes={
          qty:action.payload.qty+state.entities[action.payload.id].qty
        }
        return {
          ...OrderLineAdapter.updateOne({id:action.payload.id,changes:changes},state),
          loading: false,
        };
      }
        
      else
        return {
          ...OrderLineAdapter.upsertOne(action.payload,state),
          loading: false,
        };
    }

    case fromOrderLineActions.OrderLineActionsType.ADD_LOCAL_FAIL_HTTP: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOrderLineActions.OrderLineActionsType.REMOVE_LOCAL_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrderLineActions.OrderLineActionsType.REMOVE_LOCAL_SUCCESS_HTTP: {
        return {
          ...OrderLineAdapter.removeMany(action.removedIds,state),
          loading: false,
        };
    }

    case fromOrderLineActions.OrderLineActionsType.REMOVE_LOCAL_FAIL_HTTP: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOrderLineActions.OrderLineActionsType.CLEAR_CART_LOCAL: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrderLineActions.OrderLineActionsType.CLEAR_CART_LOCAL_SUCCESS: {
        return {
          ...OrderLineAdapter.removeAll(state),
          loading: false,
        };

    }

    case fromOrderLineActions.OrderLineActionsType.CLEAR_CART_LOCAL_FAIL: {
      return {
        ...state,
        loading: false
      };
    }


    case fromOrderLineActions.OrderLineActionsType.LOAD_LOCAL_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrderLineActions.OrderLineActionsType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrderLineActions.OrderLineActionsType.LOAD_SUCCESS_HTTP: {
      return {
        ...OrderLineAdapter.addMany(action.payload,state),
        loading: false,
      };
    }

    case fromOrderLineActions.OrderLineActionsType.LOAD_FAIL_HTTP: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOrderLineActions.OrderLineActionsType.LOAD_LOCAL_SUCCESS_HTTP: {
        return {
          ...OrderLineAdapter.addMany(action.payload,state),
          loading: false,
        };
    }

    case fromOrderLineActions.OrderLineActionsType.LOAD_LOCAL_FAIL_HTTP: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOrderLineActions.OrderLineActionsType.UPDATE_LOCAL: {
      return {
        ...state,
        loading: true
      };
    }

    case fromOrderLineActions.OrderLineActionsType.UPDATE_LOCAL_SUCCESS: {
      if(state.entities[action.payload.id]){
        let changes={
          qty:state.entities[action.payload.id].qty + action.payload.qty
        }
        return {
          ...OrderLineAdapter.updateOne({id:action.payload.id,changes:changes},state),
          loading: false,
        };
      }
        
      else
        return {
          ...OrderLineAdapter.upsertOne(action.payload,state),
          loading: false,
        };
    }

    case fromOrderLineActions.OrderLineActionsType.UPDATE_LOCAL_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
  }
  return state;
}
