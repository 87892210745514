import { Injectable } from '@angular/core';


@Injectable()
export class VersionService {
  version: any;
  constructor(
   
  ){}
  async getVersion() {
    const packageJson = await import('../../../package.json');
    this.version=packageJson.version
    return this.version
  }

}