<ion-header *ngIf="!platform.is('desktop') && sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'">
    <ion-toolbar >
      <ion-buttons slot="start" *ngIf="selectedCategoryId">
        <ion-button (click)="back()">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons slot="start" *ngIf="!selectedCategoryId && showBackButton">
        <ion-button (click)="back('category')">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title style="text-align: center;" *ngIf="!selectedCategoryId">{{'CATEGORY' | translate}}</ion-title>
      <ion-title style="text-align: center;" *ngIf="selectedCategoryId">{{'PRODUCTS' | translate}}</ion-title>
    </ion-toolbar>
</ion-header>

<ion-header collapse="fade" mode="ios" *ngIf="!platform.is('desktop') && sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'">
  <app-modern-template-header iconSrc="category" title="{{'CATEGORY' | translate}}"></app-modern-template-header>
</ion-header>

<ion-content class="ion-text-center">
  <ng-container *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'">
    <ion-grid class="ion-no-padding" *ngIf="!selectedCategoryId">
        <ion-col (click)="openProducts(category.id)" *ngFor="let category of categories;let i = index"
          style="--color-checked: #f93946; font-weight: bold;  --indicator-color: transparent !important; --indicator-color-checked: transparent !important;">
          <ion-card [style]="'background-repeat: no-repeat; background-size: cover; background-position: center;font-size:30px'">
            {{category.name}}
          </ion-card>
        </ion-col>
  
    </ion-grid>
    <ion-grid *ngIf="selectedCategoryId">
      <app-default-products  style="margin: 20px;" [selectedCategoryId]="selectedCategoryId" [showBackButton]="showBackButton"></app-default-products>
    </ion-grid>
  </ng-container>
  
  <ng-container *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'">
    <app-modern-template-categories [categories]="categories"></app-modern-template-categories>
  </ng-container>

</ion-content>