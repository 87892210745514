import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ClientConfigurationActionsType } from '../actions';
import * as clientConfigurationActions from '../actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';

@Injectable()
export class ClientConfigurationEffects {
  constructor(private actions$: Actions, private odooAPI: OdooAPI) {}

  // @Effect()
  // loadHttp$ = this.actions$.pipe(
  //   ofType(ClientConfigurationActionsType.LOAD_HTTP),
  //   switchMap((loadHTTPAction: clientConfigurationActions.LoadHttp) => {
  //     return of(loadHTTPAction.payload);
  //   }),
  //   switchMap((payload: any) => {
  //     return this.odooAPI
  //       .loadClientConfiguration(payload.uid, payload.offset, payload.ids, payload.limit)
  //       .pipe(
  //         map(
  //           (data: any) => {
  //             return new clientConfigurationActions.LoadHttpSuccess(data.records);
  //           },
  //           err => {
  //             return new clientConfigurationActions.LoadHttpFail(err);
  //           }
  //         ),
  //         catchError(err => {
  //         return of(new clientConfigurationActions.LoadHttpFail(err));
  //       })
  //       );
  //   })
  // );
  @Effect()
  loadPublicHttp$ = this.actions$.pipe(
    ofType(ClientConfigurationActionsType.LOAD_PUBLIC_HTTP),
    switchMap((loadHTTPAction: clientConfigurationActions.LoadPublicHttp) => {
      return of(loadHTTPAction);
    }),
    switchMap((payload: any) => {
      return this.odooAPI.getClientConfigPublic().pipe(
        map(
          (data: any) => {
            return new clientConfigurationActions.LoadHttpSuccess(data);
          },
          err => {
            return new clientConfigurationActions.LoadHttpSuccess(err);
          }
        ),
        catchError((err: any) => {
          return of(new clientConfigurationActions.LoadHttpSuccess(err));
        })
      );
    })
  );
}
