import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductVariantState, productVariantAdapter } from '../state/product-variant.state';

export const selectProductVariantState = createFeatureSelector<ProductVariantState>('product-variant');

export const getProductVariantEntities = (state: ProductVariantState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getProductVariantLoading = (state: ProductVariantState) => state.loading;

export const getProductVariantUploading = (state: ProductVariantState) => state.uploading;
export const getOffset = (state: ProductVariantState) => state.offset;
export const getLimit = (state: ProductVariantState) => state.limit;
export const getDataLength = (state: ProductVariantState) => state.dataLength;
export const getSearch = (state: ProductVariantState) => state.search;


const {
  selectEntities,
  selectAll,
  selectTotal
} = productVariantAdapter.getSelectors();

export const selectAllData = createSelector(
  selectProductVariantState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectProductVariantState,
  selectEntities
);

export const selectOffset = createSelector(
  selectProductVariantState,
  getOffset
);
export const selectDataLength = createSelector(
  selectProductVariantState,
  getDataLength
);

export const selectLimit = createSelector(
  selectProductVariantState,
  getLimit
);

export const selectDataProductVariantedById = createSelector(
  selectAllData,
  (data: any) => {
    
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);

export const selectSearch = createSelector(
  selectProductVariantState,
  getSearch
);
export const selectProductVariantByProductId =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    return data.filter(product=>product.product_id[0] == id);
  }
);

export const selectProductVariantById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    
    return data.filter(product=>product.id == id);
  }
);