import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { SharedService } from 'src/app/services/sharedService';
import { Storage } from '@ionic/storage';
import { FormValidationService } from 'src/app/services/formValidation.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {

  logInFormGroup: FormGroup;
  signUpFormGroup: FormGroup;
  validation_messages: any;
  submitAttempt :Boolean;
  showLogInForm: boolean ;
  showSignUpForm: boolean;
  @Input() segmantValue
  areas: any[];
  selectedAreaId: any;
  loading: boolean;
  constructor(
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private odooAPI: OdooAPI,
    private toastCtrl: ToastController,
    private SharedService: SharedService,
    private storage:Storage,
  ) { }

  ngOnInit() {
    this.loadAreas()
    this.initForm()
    this.signUpForm()
    if(this.segmantValue == 'login'){
      this.showLogInForm = true
      this.showSignUpForm = false
    }
    else if(this.segmantValue == 'signUp'){
      this.showSignUpForm = true
      this.showLogInForm = false
    }
  }

  initForm() {
    this.logInFormGroup = this.formBuilder.group({
      mobile_number: new FormControl(
        '',
        Validators.compose([Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10)])
      ),
     
      password: new FormControl(
        '',
        Validators.compose([ Validators.minLength(8)])
      ),
    });


    this.validation_messages = {

      mobile_number: [{ type: 'required', message: this.translate.instant('THIS_FIELD_IS_REQUIRED') },
      {
        type: 'minlength',
        message: this.translate.instant('PHONE_MUST_BE_AT_LEAST_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
      },
      {
        type: 'maxlength',
        message: this.translate.instant('PHONE_MUST_BE_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
      },
      {
        type: 'pattern',
        message: this.translate.instant('YOUR_PHONE_MUST_CONTAIN_ONLY_NUMBERS')
      },],

      password: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' },
                  {
                    type: 'minlength',
                    message: this.translate.instant('PASSWORD_MUST_BE_AT_LEAST_') + ' ' + 8 + ' ' + this.translate.instant('NUMBERS')
                  },
    ],
    }
  }

  signUpForm() {
    this.signUpFormGroup = this.formBuilder.group({
      username: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      email: new FormControl(
        '',
        Validators.compose([Validators.required,Validators.email])
      ),
      mobile_number: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10)
        ])
      ),

      area_id: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      password: new FormControl(
        '',
        Validators.compose([ Validators.minLength(8)])
      ),
      address: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),

      confirm_password: new FormControl(
        '',
        Validators.compose([
          Validators.minLength(8),
          FormValidationService.duplicatePassword
        ])
      ),
    });


    this.validation_messages = {
      username: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],

      email: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' },
               {type: 'email', message: 'INVALID_EMAIL_ADDRESS'}          
    ],

      mobile_number: [
        { type: 'required', message: this.translate.instant('THIS_FIELD_IS_REQUIRED') },
        {
          type: 'minlength',
          message: this.translate.instant('PHONE_MUST_BE_AT_LEAST_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
        },
        {
          type: 'maxlength',
          message: this.translate.instant('PHONE_MUST_BE_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
        },
        {
          type: 'pattern',
          message: this.translate.instant('YOUR_PHONE_MUST_CONTAIN_ONLY_NUMBERS')
        },],

      area_id: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],

      address: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      
      password: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' },
                  {
                    type: 'minlength',
                    message: this.translate.instant('PASSWORD_MUST_BE_AT_LEAST_') + ' ' + 8 + ' ' + this.translate.instant('NUMBERS')
                  },
    ],

      confirm_password: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' },
                        {
                          type: 'minlength',
                          message: this.translate.instant('CONFIRM_PASSWORD_MUST_BE_AT_LEAST_') + ' ' + 8+ ' ' + this.translate.instant('NUMBERS')
                        },
                        {
                          type: 'mismatchedPassword',
                          message: FormValidationService.getValidatorErrorMessage(
                            'mismatchedPassword'
                          )
                        },
    ],
    }
  }

  back() {
    this.modalCtrl.dismiss()
  }

  async logIn(){
    this.loading=true
    this.submitAttempt = true;
    if (this.logInFormGroup.valid) {
      let userInfo =  this.logInFormGroup.value
       this.odooAPI.logIn(userInfo).then((data:any) =>{
        this.loading=false
        this.checkAndSaveLoginInfo(data)
        })
    }
  }
  
  openRegistrationForm(){
    this.segmantValue = 'signUp'
    this.showSignUpForm = true
    this.showLogInForm = false
  }

  segmentChanged(event){
    if (event && event.detail && event.detail.value) {
      const value = event.detail.value;
      if(value == 'login'){
        this.segmantValue = 'login'
        this.showLogInForm = true
        this.showSignUpForm = false
      }
      else if (value == 'signUp'){
        this.segmantValue = 'signUp'
        this.showSignUpForm = true
        this.showLogInForm = false
      }
    }
  }

  async signUp(){
    this.loading=true
    this.submitAttempt = true;
    if (this.signUpFormGroup.valid) {
      let userInfo =  this.signUpFormGroup.value
      userInfo['is_storex_commerce_user'] = true
      userInfo['state'] = 'confirmed'
      ;(await this.odooAPI.register(userInfo)).subscribe(data =>{
        if(data && data['user_info']){
          this.odooAPI.logIn({mobile_number:userInfo.mobile_number,password:userInfo.password}).then((data:any) =>{
            this.loading=false
            this.checkAndSaveLoginInfo(data)
            })
        }
        else{
          this.loading=false
          const toast = this.toastCtrl
            .create({
              message:this.translate.instant(data),
              duration: 10000,
              position: 'top',
              mode:'ios'
            })
            .then(tst => {
              tst.present();
            });
        }
      })
    }
  }
  checkAndSaveLoginInfo(data) {
    if(data && data['rb_delivery_user'] && data['context'] && data['token']){  
      let loginInfo={
        user_info:data['rb_delivery_user'][0],
        jwt_token:data['token'],
        context_info:data['context'].user_context
      }
      this.storage.set('login_info',loginInfo)
      this.SharedService.loginInfo = loginInfo
      const toast = this.toastCtrl
        .create({
          message:this.translate.instant('YOU_HAVE_BEEN_LOGGED_SUCCESSFULLY'),
          duration: 1000,
          position: 'top',
          mode:'ios'
        })
        .then(tst => {
          tst.present();
        });
        this.modalCtrl.dismiss({loggedIn:true})
        window.location.reload()
      
    }
      else{
        const toast = this.toastCtrl
        .create({
          message:this.translate.instant(data),
          duration: 1000,
          position: 'top',
          mode:'ios'
        })
        .then(tst => {
          tst.present();
        });
      }
  }

  loadAreas() {
    this.odooAPI.loadAreaPublic(this.odooAPI.countryCode).pipe(take(1)).subscribe((areas: any[]) => {
      this.areas = areas
    })
  }

  selectArea(ev) {
    this.selectedAreaId = ev.detail.value.id
    this.signUpFormGroup.get('area_id').setValue(this.selectedAreaId)
  }
}
