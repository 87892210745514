import { Component, Input, OnInit, Output ,EventEmitter, OnDestroy,} from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { OrderLineState } from 'src/app/stores/order-line/store';
import { ProductVariantState } from 'src/app/stores/product-variant/store';
import * as productVariantSelector from 'src/app/stores/product-variant/store/selectors'
import { environment } from 'src/environments/environment';
import * as orderLineActions from 'src/app/stores/order-line/store/actions';
import { SharedService } from 'src/app/services/sharedService';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as productVariantActions from 'src/app/stores/product-variant/store/actions'
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { productVariantFields } from 'src/app/stores/product-variant/model';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-modern-template-cart',
  templateUrl: './modern-template-cart.component.html',
  styleUrls: ['./modern-template-cart.component.scss'],
})
export class ModernTemplateCartComponent implements OnInit {

  @Input() cartItem
  @Output() removeProduct = new EventEmitter<any>();
  @Input() productVariantsIds
  product: any;
  env = environment
  companyTheme: any;
  hideCartItem: boolean;
  destroyed$ = new Subject<any>();

  constructor(
    private productVariantStore: Store<ProductVariantState>,
    private orderLineStore : Store<OrderLineState>,
    public sharedService: SharedService,
    private alertCtrl: AlertController,
    private translate: TranslateService,

  ) { }
  ngOnDestroy(): void {
    this.destroyed$.next({})
    this.destroyed$.unsubscribe()
  }

  ngOnInit() {

    let requestData: PublicOdooRequest = {
      domain: [['id', 'in', this.productVariantsIds]],
      fields: productVariantFields,
      limit: 10,
      model: "storex_modules.product_variant",
      offset: 0
    }
    this.productVariantStore.dispatch(new productVariantActions.LoadHttp(requestData))
    this.productVariantStore.select(productVariantSelector.selectProductVariantById(this.cartItem.product_id)).pipe(filter(data=>data&&data.length>0),take(1)).subscribe(data =>{
      if(data && data.length > 0){
        this.product = data[0]
      }
      })
    this.companyTheme = this.sharedService.companyTheme
  }

  qtyPlus(){
    this.orderLineStore.dispatch(new orderLineActions.AddLocalHttp({id:this.cartItem.id,product_id:this.cartItem.product_id,qty : 1}))

  }

  qtyMenus(){
    if(this.cartItem.qty <= 1){
      this.alertCtrl.create({
        message: this.translate.instant("YOU_ARE_GOING_TO_DELETE_PRODUCT_ARE_YOU_SURE_!"),
        mode:'ios',
        buttons: [{
          text: this.translate.instant('OK'),
          handler: () => {
            this.removeProduct.emit({value:this.cartItem})
          }
        },{
          text:this.translate.instant('CANCEL'),
          handler: () => {
            this.cartItem.qty = 1
            this.hideCartItem = false
          }
        }]
      }).then(alert => {
        alert.present()
      })
      
    }
    else{
      this.orderLineStore.dispatch(new orderLineActions.UpdateLocal({id:this.cartItem.id,product_id:this.cartItem.product_id,qty:-1}))
    }
  }

}
