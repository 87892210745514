import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { CategoryState } from 'src/app/stores/category/store';
import { ProductState } from 'src/app/stores/product/store';
import * as productSelector from 'src/app/stores/product/store/selectors';
import { environment } from 'src/environments/environment';
import * as  CategorySelector from 'src/app/stores/category/store/selectors';
import { QuickOrderFormComponent } from '../quick-order-form/quick-order-form.component';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { productVariantFields } from 'src/app/stores/product-variant/model';
import * as productVariantSelector from 'src/app/stores/product-variant/store/selectors';
import * as productVariantActions from 'src/app/stores/product-variant/store/actions';
import { ProductVariantState } from 'src/app/stores/product-variant/store/state';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { SharedService } from 'src/app/services/sharedService';
import { productFields } from 'src/app/stores/product/model';
import * as productActions from 'src/app/stores//product/store/actions';
import { OrderLineState } from 'src/app/stores/order-line/store';
import * as orderLineActions from 'src/app/stores/order-line/store/actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-default-quick-order-template',
  templateUrl: './default-quick-order-template.component.html',
  styleUrls: ['./default-quick-order-template.component.scss'],
})
export class DefaultQuickOrderTemplateComponent implements OnInit {

  @Input() productId: number;
  product: any;
  destroyed$ = new Subject<any>();
  env = environment
  categories: any;
  userId:number;
  customerPhone:any;
  whatsappMessage: any;
  generalConfigration: any;
  isReadMoreDesc:boolean;
  direction:string;
  productVariants:any;
  selectedVariant: any;
  categoryProducts: any;
  imgSource: string;
  quantity: number = 1;
  disableInput: boolean = false;
  companyTheme: string;
  showHeader=true
  constructor(
    private route: ActivatedRoute,
    private productStore: Store<ProductState>,
    private categoryStore: Store<CategoryState>,
    private modalCtrl: ModalController,
    private productVarientStore: Store<ProductVariantState>,
    private odooApi: OdooAPI,
    public sharedService:SharedService,
    private router: Router,
    public platform: Platform,
    public navCtrl: NavController,
    private orderLineStore: Store<OrderLineState>,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    public shared:SharedService

  ) { }

  ngOnInit() {
    this.getGeneralConfiguration()
    if(!this.productId){
      this.route.params.subscribe(params => {
        this.productId = params['id']
        this.imgSource = this.env.url+'/web/image/storex_modules.product/'+this.productId+'/image/400x400'
        this.loadProduct(this.productId)
        this.loadProductVariants(this.productId)
      })
    }else{
      this.imgSource = this.env.url+'/web/image/storex_modules.product/'+this.productId+'/image/400x400'
      this.loadProduct(this.productId)
      this.loadProductVariants(this.productId)
      this.showHeader=false
    }
    
    this.companyTheme = this.sharedService.companyTheme
    this.direction = document.documentElement.dir
  }

  loadProduct(id) {
    this.productStore.select(productSelector.selectProductById(id)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.product = data[0]
      this.userId = this.product['delivery_user_id'][0]
      this.loadUserInfo(this.userId)
      this.loadCategories()

    })
  }

  loadCategories() {
    if (this.product && this.product.category_ids) {
      this.categoryStore.select(CategorySelector.selectCategoryByIds(this.product.category_ids)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
        this.categories = data
        if(this.categories && this.categories.length > 0){
          let ProductIds = []
          for(let category of this.categories){
            if(category.product_ids.length > 0){
              ProductIds = ProductIds.concat(category.product_ids)
            }
          }
          if(ProductIds.length > 0){
            var domain = [['delivery_user_id.storex_sub_domain', '=', environment.shopUrl],['id','in',ProductIds]]
            let requestData: PublicOdooRequest = {
              domain: domain,
              fields: productFields,
              limit: 10,
              model: "storex_modules.product",
              offset: 0
            }
            this.productStore.dispatch(new productActions.LoadHttp(requestData))
            this.productStore.select(productSelector.selectProductByIds(ProductIds)).pipe(filter(data => data && data.length > 0), takeUntil(this.destroyed$)).subscribe(data => {
              this.categoryProducts = data.filter(product => product.id != this.productId)
            })
          }
        }
      })
    }
  }
  back() {
    this.navCtrl.navigateForward(['/search'])
  }
  openOrderForm(id) {

    const modal = this.modalCtrl.create({

      component: QuickOrderFormComponent,
      cssClass: 'quick-order-form-style',
      componentProps: {
        productId: id,
        selectedVariant: this.selectedVariant,
        quantity: this.quantity
      }
    })
    modal.then(mod => mod.present())
  }
loadProductVariants(productId) {
  let requestData: PublicOdooRequest = {
    domain: [['product_id.id', '=', productId]],
    fields: productVariantFields,
    limit: 10,
    model: "storex_modules.product_variant",
    offset: 0
  }


  this.productVarientStore.dispatch(new productVariantActions.LoadHttp(requestData));
  this.productVarientStore.select(productVariantSelector.selectProductVariantByProductId(productId))
    .pipe(
      filter(data => data && data.length > 0), 
      take(1) 
    )
    .subscribe(data => {
      this.productVariants = data.map(variant => {
        let newName = variant.name.split('_');
        if (newName.length > 1 && newName[0] === this.product.name) {
          newName.shift(); 
        }
        return { ...variant, name: newName.join(' ') }; 
      });

      if (this.productVariants.length === 1) {
        this.selectedVariant = this.productVariants[0];
      }
    });
}


  loadUserInfo(userId){
    let requestData: PublicOdooRequest = {
      domain: [['id', '=', userId]],
      fields: ['xstore_support_phone','xstore_support_message'],
      limit: 1,
      model: "rb_delivery.user",
      offset: 0
    }
    
    this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(user =>{
      
      if(user[0].xstore_support_phone){
        this.customerPhone = user[0].xstore_support_phone
      }
      else if (this.generalConfigration[0].xstore_support_phone){
        this.customerPhone = this.generalConfigration[0].xstore_support_phone
      }
      if(user[0].xstore_support_message){
        if(user[0].xstore_support_message.includes('{product}')){
          this.whatsappMessage = user[0].xstore_support_message.replace('{product}', this.product.name) + '  https://' + this.env.shopUrl + '/quick-order/'+this.product.id
  
        }
        else{
          this.whatsappMessage = user[0].xstore_support_message + '  https://' + this.env.shopUrl + '/quick-order/'+this.product.id

        }
      }
      else if(this.generalConfigration[0].xstore_support_message){
        if(this.generalConfigration[0].xstore_support_message.includes('{product}')){
          this.whatsappMessage = this.generalConfigration[0].xstore_support_message.replace('{product}', this.product.name) + '  https://' + this.env.shopUrl + '/quick-order/'+this.product.id
  
        }
        else{
          this.whatsappMessage = this.generalConfigration[0].xstore_support_message +'  https://' +this.env.shopUrl + '/quick-order/'+this.product.id
  
        }
      }
     
    })
  }

  getGeneralConfiguration(){
    let requestData: PublicOdooRequest = {
      domain: [],
      fields: ['xstore_support_phone','xstore_support_message'],
      limit: 1,
      model: "rb_delivery.general_configuration",
      offset: 0
    }
    this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(data =>{
      this.generalConfigration = data
    })
  }

  selectVariant(ev) {
    this.selectedVariant = ev.detail.value
    if(this.selectedVariant.virtual_quantity<=0){
      this.disableInput = true
    }
    else{
      this.disableInput =  false
    }
    
    let imgUrl = this.selectedVariant.image_url.replace('/50x50','')
    this.imgSource = this.env.url+imgUrl+'/400x400'
  }

  openQuickOrder(id) {
    this.router.navigate(['quick-order/' + id])
}

  selectImage(id,type){
    if(type == 'variant'){
      this.imgSource = this.env.url+'/web/image/storex_modules.variant_image/'+id+'/image/400x400'
    }
    else{
      this.imgSource = this.env.url+'/web/image/storex_modules.product/'+id+'/image/400x400'
    }
  }

  qtyPlus() {
      this.quantity = this.quantity + 1
  }

  qtyMenus() {
    if(this.quantity>0)
      this.quantity = this.quantity - 1
    
  }

  onInput(ev){
    
    this.quantity = Number(ev.target.value)
  }

  addToCart(){
    this.orderLineStore.dispatch(new orderLineActions.AddLocalHttp({id:this.selectedVariant.id,product_id:this.selectedVariant.id,qty : this.quantity}))
    this.toastCtrl.create({
              message:this.translate.instant('PRODUCT_ADDED_TO_CART_SUCCESSFULLY'),
              duration: 1000,
              position: 'bottom',
              mode:'ios'
            })
            .then(tst => {
              tst.present();
            });
  }

  addToWishlist() {

        this.odooApi.addProductToWishlist(this.shared.loginInfo.user_info.id,this.product.id).subscribe(response=>{
        if (response) {
            let updatedProduct = {
                ...this.product,
                wishlist_receipients: [...this.product.wishlist_receipients, this.shared.loginInfo.user_info.id]
            };
            this.product = updatedProduct;
        }

    });
  }

  removeFromWishlist(){
    this.odooApi.removeProductFromWishlist(this.shared.loginInfo.user_info.id, this.product.id).subscribe(response => {
      if(response){
        let index = this.product.wishlist_receipients.findIndex(x => x == this.shared.loginInfo.user_info.id);
        if (index != -1){
          this.product.wishlist_receipients.splice(index, 1); 
        }
      }
    })
  }
}
