<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="openProductsPage()">
        <ion-icon name="caret-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ng-container *ngIf="order">
    <ion-img style="height: 100px;" src="../../../../assets/icon/checkmark.gif"></ion-img>
    <ion-row style="display: flex;justify-content: center;flex-direction: column; text-align: center;">
      {{'YOUR_ORDER_ADDED_SUCCESSFULLY' | translate}}
      <br/>
      {{'YOU_WILL_BE_CONTACTED_SOON' | translate}}
      <br/>
      <span *ngIf="companyMobile">{{'OR_YOU_CAN_CALL_US_NOW' | translate}} : <a [href]="'tel:'+companyMobile">{{companyMobile}}</a></span>
      <br/>
      <br/>
      <span>{{'ORDER_NUMBER' | translate}} : <strong>{{order.sequence}}</strong></span>
    </ion-row>
  </ng-container>
  <ng-container *ngIf="!order">
    <ion-row style="display: flex;justify-content: center;margin-top: 150px;padding: 20px">
      {{'FAILED_IN_ADDING_YOUR_ORDER' | translate}}
    </ion-row>
    <ion-img src="../../../../assets/icon/failed_process.gif"></ion-img>
  </ng-container>
  <ion-row style="display: flex;justify-content: center;margin-top: 20px;position: fixed;right: 0px;left: 0px;bottom: 20px;">
    <ion-button style="width: 100%;height: 50px;border-radius: 8px;" (click)="openProductsPage()">
      {{'CONTINUE_SHOPPING' | translate}}
    </ion-button>
  </ion-row>
</ion-content>