import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoginFormComponent } from 'src/app/components/login-form/login-form.component';
import { Storage } from '@ionic/storage';
import { LanguagePage } from 'src/app/components/language/language.page';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Share } from '@capacitor/share'
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { SharedService } from 'src/app/services/sharedService';
import { OrdersPage } from 'src/app/components/orders/orders.page';
import { WishlistPage } from 'src/app/components/wishlist/wishlist.page';
import { VersionService } from 'src/app/services/versionService';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.page.html',
  styleUrls: ['./setting.page.scss'],
})
export class SettingPage implements OnInit {
  userName: any;
  userId: any;
  userMobile: any;
  loginStatus: string;
  whatsappMessage: any;
  @Input() showBackButton
  env = environment
  template: any;
  version: string;
  constructor(
    private storage: Storage,
    private modalCtrl: ModalController,
    private odooApi: OdooAPI,
    public sharedService : SharedService,
    private versionService:VersionService
  ) { }

  ngOnInit() {
    this.getGeneralConfiguration()
    this.getVersion()
  }

  openLoginForm(){
    if(this.showBackButton){
      this.modalCtrl.dismiss()
    }
    const modal = this.modalCtrl.create({
      component: LoginFormComponent,
      componentProps:{segmantValue:'login'}
    })
    modal.then(mod => {
      mod.present()
    })
  }

  logOut(){
    this.odooApi.mobileLogoutAction().subscribe(response=>{
      if(response)
      this.storage.clear().then(() =>{
        window.location.reload()
      })
    })
  }

  openLanguageModal(){
    if(this.showBackButton){
      this.modalCtrl.dismiss()
    }
    const modal = this.modalCtrl.create({
      component: LanguagePage,
    })
    modal.then(mod=>{
      mod.present()
    })
  }

  async shareApp(){
    let title = this.whatsappMessage
    let url=this.env.url
    await Share.share({
      title: title,
      url: url,
    }
  )
  }

  getGeneralConfiguration(){
    let requestData: PublicOdooRequest = {
      domain: [],
      fields: ['share_link_message'],
      limit: 1,
      model: "rb_delivery.general_configuration",
      offset: 0
    }
    this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(data =>{
      if(data && data.length > 0 && data[0].share_link_message){
        this.whatsappMessage = data[0].share_link_message
      }
    })
  }
  openOrdersModal(){
    const modal = this.modalCtrl.create({
      component: OrdersPage,
    })
    modal.then(mod=>{
      mod.present()
    })
  }
  openWishlistsModal(){
    const modal = this.modalCtrl.create({
      component: WishlistPage,
    })
    modal.then(mod=>{
      mod.present()
    })
  }
  openPage(x){

  }
  openAccountPage(){
    
  }
  openProductsPage(){

    
  }

  async getVersion() {
    this.version= await this.versionService.getVersion()
  }

  back(){
    this.modalCtrl.dismiss()
  }
  
}
