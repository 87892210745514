<ion-item lines="full">
  <ion-row style="width:100%;display: flex;align-items: center;">
    <ion-col size="auto" text-start style="font-size:13px; padding: 5px; margin: 10px 0 5px 0; border-radius: 15px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);" *ngIf="product&&product.id">
      <img style="border-radius: 15px; width: 100px;" [src]="env.url+'/web/image/storex_modules.product_variant/'+product.id+'/image/220x220'" />
  </ion-col>
  


    <ion-col  text-center style="font-size:13px; margin: 10px 0 5px 0;">

      <ion-row>
        <ion-col size="12" *ngIf="product&&product.id" text-start style="font-size:13px ;margin: 10px 0 5px 0;white-space: nowrap; 
        width: 50px; 
        overflow: hidden;
        text-overflow: ellipsis; 
        border: 1px solid white;padding-inline: 20px;">
          <ion-row
            style="font-family: 'Tajawal';color: #1E2329;text-transform: capitalize; overflow: hidden;text-overflow: ellipsis;height: 44px;margin-top: 10px;white-space: pre-line;font-size: 15px">{{product.name}}</ion-row>
          <ion-row style="font-family: 'Tajawal';letter-spacing: 0.63px;text-transform: capitalize;"
            [ngStyle]="{'color':companyTheme?companyTheme:'black'}"></ion-row>
        </ion-col>
      </ion-row>
      <ion-row 
        style=" width: 100%; height: 46px; align-items: center; justify-content: space-around;">

        <span *ngIf="product && product.currency" style="color: #191825; font-weight: bold; font-size: 13px; margin-inline-end: 60px;">{{product.price_unit * cartItem.qty  | number : '1.2-2'}}{{product.currency[0]}}</span>

       
      <!-- Minus Icon -->
        <div style="border: 1px solid black; border-radius: 25px; display: flex; flex-direction: row; justify-content: space-between; zoom:1.2;">
          <ion-icon style="width: 25px; height: 25px; border-radius: 50%; background-color: #191825; display: flex; align-items: center; justify-content: center;color: #ffffff
            ;" (click)="qtyMenus()" name="remove-outline">
          </ion-icon>

          <!-- Quantity Display -->
          <ion-col size="1" style="display: flex; justify-content: center; padding-inline: 15px;">
            {{cartItem.qty}}
          </ion-col>
      
          <!-- Plus Icon -->
          <ion-icon
          style="width: 25px; height: 25px; border-radius: 50%; background-color: #191825; display: flex; align-items: center; justify-content: center;color: #ffffff;"
          (click)="qtyPlus()" name="add-outline">
          </ion-icon>
        </div>
        
      </ion-row>
    </ion-col>


  </ion-row>
</ion-item>