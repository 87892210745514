<ion-header >
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon  name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" mode="md">
  <img src="assets/icon/header.png" style="position: absolute; top: 0; right: 0; z-index: 9998;"/>

  <!-- Button with icon -->


  <div class="register-title" *ngIf="showSignUpForm">
    

    
    {{'REGISTER' | translate}}
  </div>
  <div class="register-title" *ngIf="showLogInForm">
    
    {{'WELCOME' | translate}}

  </div>

  <div class="register-subtitle">
    {{'MAKE_YOUR_DAILY_LIFE_EASY_HERE' | translate}}
  </div>


  <form [formGroup]="logInFormGroup" *ngIf="showLogInForm">
    <ion-grid>
      <!-- Phone Number -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none" >
            <ion-icon name="call-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'MOBILE_NUMBER' | translate}}</ion-label>
            <ion-input type="text" formControlName="mobile_number"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.mobile_number">
          <div class="error-message" *ngIf="
            logInFormGroup.get('mobile_number').hasError(validation.type) &&
                (logInFormGroup.get('mobile_number').dirty ||
                logInFormGroup.get('mobile_number').touched ||
                  submitAttempt)
              ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>

      <!-- Password -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="lock-closed-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'PASSWORD' | translate}}</ion-label>
            <ion-input type="password" formControlName="password"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.password">
          <div class="error-message" *ngIf="
            logInFormGroup.get('password').hasError(validation.type) &&
                (logInFormGroup.get('password').dirty ||
                logInFormGroup.get('password').touched ||
                  submitAttempt)
              ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>

      <!-- Login Button -->
      <ion-row class="row-style" style="background: linear-gradient(180deg, #3C4048 0%, #191825 100%);">
        <ion-col size="12" class="col-style" (click)="logIn()">
          <label style="color: #FFFFFF;font-size: 18px;padding: 0px 25px;font-weight: bold;">{{'LOGIN' | translate}}</label>
        </ion-col>
      </ion-row>

      <!-- Don't have an account -->
      <ion-row>
        <ion-col size="12" style="display: flex;justify-content: center;margin-top: 18px">
          <label style="color: #939090;font-size: 12px;">{{'I_DO_NOT_HAVE_ACCOUNT' | translate}}</label> 
        </ion-col>
      </ion-row>

      <!-- Register -->


    <ion-row class="row-style">
      <ion-col size="12" class="col-style">
        <ion-segment [value]="segmentValue" (ionChange)="segmentChanged($event)">
            <ion-segment-button value="signUp" mode="md">
              <label style="color: #1E2329;font-size: 18px;padding: 0px 25px;font-weight: bold;">{{'REGESTER' | translate}}</label>
            </ion-segment-button>
        </ion-segment>
    </ion-col>
  </ion-row>
      
    </ion-grid>
</form>

  <form [formGroup]="signUpFormGroup" *ngIf="showSignUpForm">

    <ion-grid>
      <!-- UserName  -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="person-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'NAME' | translate}}</ion-label>
            <ion-input type="text" formControlName="username"></ion-input>
          </ion-item>
      
        </ion-col>
      </ion-row>

      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.username">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('username').hasError(validation.type) &&
              (signUpFormGroup.get('username').dirty ||
                signUpFormGroup.get('username').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>

      <!-- Phone Number -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="call-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'MOBILE_NUMBER' | translate}}</ion-label>
            <ion-input type="text" formControlName="mobile_number"></ion-input>
          </ion-item>
      
        </ion-col>
      </ion-row>
      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.mobile_number">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('mobile_number').hasError(validation.type) &&
              (signUpFormGroup.get('mobile_number').dirty ||
                signUpFormGroup.get('mobile_number').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>
      
      <!-- Password -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="lock-closed-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'PASSWORD' | translate}}</ion-label>
            <ion-input type="password" formControlName="password"></ion-input>
          </ion-item>
        
        </ion-col>
      </ion-row>
      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.password">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('password').hasError(validation.type) &&
              (signUpFormGroup.get('password').dirty ||
                signUpFormGroup.get('password').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>
  
      <!-- Confirm Password -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="lock-closed-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'CONFIRMED_PASSWORD' | translate}}</ion-label>
            <ion-input type="password" formControlName="confirm_password"></ion-input>
          </ion-item>
          
        </ion-col>
      </ion-row>

      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.confirm_password">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('confirm_password').hasError(validation.type) &&
              (signUpFormGroup.get('confirm_password').dirty ||
                signUpFormGroup.get('confirm_password').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>
      
  
      <!-- Email -->
      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="mail-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'EMAIL' | translate}}</ion-label>
            <ion-input type="email" formControlName="email"></ion-input>
          </ion-item>
          
        </ion-col>
      </ion-row>
      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.email">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('email').hasError(validation.type) &&
              (signUpFormGroup.get('email').dirty ||
                signUpFormGroup.get('email').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>

      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="outline" slot="start"></ion-icon>
            <ion-select style="border-radius: 8px;width: 100%;height: 46px;padding: 10px;"
            placeholder="{{'SELECT_AREA' | translate}}" (ionChange)="selectArea($event)">
           <ion-select-option *ngFor="let area of areas" [value]="area">{{ area.name}}</ion-select-option>
         </ion-select>
            <ion-input type="number" [hidden]="true" formControlName="area_id"></ion-input>
          </ion-item>
          
        </ion-col>
      </ion-row>
      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.area_id">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('area_id').hasError(validation.type) &&
              (signUpFormGroup.get('area_id').dirty ||
                signUpFormGroup.get('area_id').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>



      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-icon name="person-outline" slot="start"></ion-icon>
            <ion-label position="floating" mode="md" >{{'address' | translate}}</ion-label>
            <ion-input type="text" formControlName="address"></ion-input>
          </ion-item>
      
        </ion-col>
      </ion-row>

      <div class="form-validation-err">
        <ng-container *ngFor="let validation of validation_messages.address">
          <div class="error-message" *ngIf="
              signUpFormGroup.get('address').hasError(validation.type) &&
              (signUpFormGroup.get('address').dirty ||
                signUpFormGroup.get('address').touched ||
                submitAttempt)
            ">
            <ion-icon name="information-circle-outline"></ion-icon>
            {{ validation.message | translate }}
          </div>
        </ng-container>
      </div>


     
  
      <!-- I agree -->
      <ion-row  class="row-agree-style">
        <ion-col size="12" class="col-style">
          <ion-item mode="md" lines="none">
            <ion-checkbox slot="start"></ion-checkbox>
            <ion-label style="font-family: Poppins; font-size: 14px; font-weight: 400; line-height: 21px;"> 
              <span style="color:#979797">{{'I_AGREE_WITH_TERMS_AND_POLICY' | translate}}</span>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
  
      <!-- Register Button -->

      <ion-row class="row-style" style="background: linear-gradient(180deg, #3C4048 0%, #191825 100%);">
        <ion-col size="12" class="col-style" (click)="signUp()">
          <label style="color: #FFFFFF;font-size: 18px;padding: 0px 25px;font-weight: bold;">{{'Register' | translate}}</label>
        </ion-col>
      </ion-row>
  
      <!-- Already have an account -->
      <ion-row>
        <ion-col size="12" style="display: flex;justify-content: center;margin-top: 18px">
          <label style="color: #939090;font-size: 12px;">{{'ALREADY_HAVE_AN_ACCOUNT' | translate}}</label> 
        </ion-col>
      </ion-row>
  
      <!-- Login -->

      <ion-row class="row-style">
        <ion-col size="12" class="col-style">
            <ion-segment [value]="segmentValue" (ionChange)="segmentChanged($event)">
                <ion-segment-button value="login" mode="md">
                  <label style="color: #1E2329;font-size: 18px;padding: 0px 25px;font-weight: bold;">{{'LOGIN' | translate}}</label>
                </ion-segment-button>
            </ion-segment>
        </ion-col>
    </ion-row>


    </ion-grid>
  </form>


  


  
</ion-content>


