import * as fromClientConfigurationActions from '../actions';

import {
  ClientConfigurationState,
  initialState,
  clientConfigurationAdapter
} from '../state/client-configuration.state';

export function ClientConfigurationReducer(
  state = initialState,
  action: fromClientConfigurationActions.ClientConfigurationActions
): ClientConfigurationState {
  switch (action.type) {
    case fromClientConfigurationActions.ClientConfigurationActionsType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromClientConfigurationActions.ClientConfigurationActionsType.UPDATE_OFFSET: {
      return {
        ...state,
        offset: action.offset
      };
    }

    case fromClientConfigurationActions.ClientConfigurationActionsType.UPDATE_LIMIT: {
      return {
        ...state,
        limit: action.limit
      };
    }

    case fromClientConfigurationActions.ClientConfigurationActionsType.UPDATE_DATA_LENGTH: {
      return {
        ...state,
        offset: action.dataLength
      };
    }

    case fromClientConfigurationActions.ClientConfigurationActionsType.LOAD_HTTP_SUCCESS: {
      // add clientConfiguration to entities
      return clientConfigurationAdapter.addMany(action.payload, state);
    }

    case fromClientConfigurationActions.ClientConfigurationActionsType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromClientConfigurationActions.ClientConfigurationActionsType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }
  }
  return state;
}
