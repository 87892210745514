<ion-header *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'">
  <ion-toolbar>
    <ion-title style="text-align: center;">{{'CART' | translate}}</ion-title>
    <ion-buttons slot="start" *ngIf="showBackButton">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-header *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'">
  <app-modern-template-header  title="{{'CART' | translate}}" [showCartButton]="false" [showBackButton]="showBackButton"></app-modern-template-header>
</ion-header>

<ion-content>
  <ion-list *ngIf="cartItems && cartItems.length > 0">
    <ng-container *ngFor="let cartItem of cartItems">
      <ion-item-sliding>
        <ion-item-options [ngStyle]="{'direction': lang=='en'?'ltr':'rtl'}" side="start">
          <ion-item-option color="danger" (click)="removeProduct(cartItem)">{{'REMOVE' | translate}}</ion-item-option>
        </ion-item-options>
        <app-cart-item *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'" (removeProduct)="removeProduct($event.value)" [productVariantsIds]="productVariantsIds" [cartItem]="cartItem"></app-cart-item>
        <app-modern-template-cart *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'" (removeProduct)="removeProduct($event.value)" [productVariantsIds]="productVariantsIds" [cartItem]="cartItem"></app-modern-template-cart>
      </ion-item-sliding>
    </ng-container>
  </ion-list>
  <ion-grid *ngIf=" cartItems.length == 0">
    <ion-row style="display: grid;justify-content: center;font-size: 80px;">
      <ion-col size="12">
        <ion-icon name="basket"></ion-icon>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <h4 style="font-family: 'Tajawal';display: grid;justify-content: center;">{{'YOUR_CART_IS_EMPTY'|translate}}</h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <h5  style="font-family: 'Tajawal';display: grid;justify-content: center;">{{'CONTINUE_SHOPPING'|translate}}</h5>
      </ion-col>
    </ion-row>
    
    <ion-row *ngIf="!sharedService.loginInfo ||  !sharedService.loginInfo.user_info " style="display:flex;justify-content: center;">
        <ion-button fill="solid"  style="font-family: 'Muslih';width: 95%;"
          (click)="openLoginForm()">{{'SIGNIN_REGISTER'|translate}}</ion-button>
    </ion-row>
    <ion-row style="display:flex;justify-content: center;">
        <ion-button fill="outline" style="font-family: 'Tajawal';width: 95%;" (click)="openHome()">{{'EXPLORE'|translate}}</ion-button>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons >
      <ion-button (click)="addOrder()" [disabled]="!cartItems || (cartItems && cartItems.length == 0)" style="width: 100%;height: 50px;border-radius: 8px;" [ngStyle]="{'background':companyTheme?companyTheme:'black'}">
        <ion-icon name="add-outline" slot="end" style="color: white;"></ion-icon>
          <div style="color: white;">
            {{'CHECK_OUT' | translate}}
          </div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>