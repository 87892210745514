import { Injectable } from '@angular/core';
import { OdooJsonRPC } from './odooJsonRPC';
import {  from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorState } from 'src/app/stores/error/store';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { modelURL, PublicOdooRequest } from '../models/ModelRemoteOdoo';
import { Storage } from '@ionic/storage';
import { SharedService } from 'src/app/services/sharedService';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class OdooAPI {
  
    
  last = 0;
  companyInfo: any;
  countryCode: string;
  language: string;
  constructor(
    private odooJsonRPC: OdooJsonRPC, // private fileOperations: FileOperations,
    private httpClient: HttpClient,
    private errorStore: Store<ErrorState>,
    private storage:Storage,
    private SharedService: SharedService,
    private translate:TranslateService


  ) {
    this.language = this.translate.currentLang == 'ar'?'ar_SY':'en_US'
  }

  loadAreaPublic(countryCode?) {
    let headers = new HttpHeaders().set('content-type', 'application/json');
    return this.httpClient.post(
      environment.url + '/storex_modules/area',
      {
        jsonrpc: '2.0',
        params: {
          country_code: countryCode?countryCode:this.countryCode,
        },
      },
      { headers: headers }
    ).pipe(map((data:any)=>{
      
      if(data.result)
        return data.result
      return false
    }));
  }

  loadSubAreaPublic(areaId) {
    let headers = new HttpHeaders().set('content-type', 'application/json');
    return this.httpClient.post(
      environment.url + '/rb_delivery/sub_area',
      {
        jsonrpc: '2.0',
        params: {
          area_id: areaId,
        },
      },
      { headers: headers }
    ).pipe(map((data:any)=>{
      if(data.result)
        return data.result
      return false
    }));
  }

  loadCountry(uid, offset, ids?: [], limit?: number, search?: any) {
    const modelName = 'rb_delivery.country';
    const fields = ['id', 'code', 'name'];
    let domain = [];
    if (search.length !== 0) {
      domain = [search];
    } else {
      domain = [];
    }
    if (!limit) {
      limit = 200;
    }
    return from(
      this.odooJsonRPC.searchRead(
        modelName,
        domain,
        fields,
        limit,
        offset,
        null
      )
    )
      .pipe(
        map((data) => {
          if (data.body.error) {
            const error = {
              message: 'Error getting country record',
              model: modelName,
              odoo: data,
            };
            throwError(JSON.stringify(error));
          }

          return data.body.result;
        })
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  loadCountryPublic() {
    return this.httpClient.get(
      this.companyInfo.website_url + '/rb_delivery/country'
    );
  }

   loadPublicRecords(requestData:PublicOdooRequest){
    const model = requestData.model
    var dataTosend: any = 
    { 
      model: requestData.model, 
      domain: requestData.domain, 
      offset: requestData.offset, 
      limit: requestData.limit, 
      order:requestData.order,
      fields: requestData.fields,
      country_code:this.countryCode,
      lang:this.language
    };
    if(this.SharedService.loginInfo && this.SharedService.loginInfo.user_info && this.SharedService.loginInfo.user_info['id']){
      dataTosend['user_id'] = this.SharedService.loginInfo.user_info['id']
    }
    if(requestData.search && !requestData.domain){
      dataTosend['domain'] = requestData.search
    }
    // This is a fix to fetch only published products 
    // TODO better way to do this 
    
    return this.httpClient
      .post(
        environment.url + modelURL[model],
        {params : dataTosend })
      .pipe(
        map((data: any) => {
          
          if ('error' in data){
            throw new Error(JSON.stringify(data.error));
          } else if('result' in data){
            if(model=="product.template")
            return data.result
            else
            return data.result.records;
          }
        })
      );
  }

  addPublicOrder(order_data: any) {
    order_data['country_code']=this.countryCode
    return this.httpClient
      .post(
        environment.url + '/storex/create/order/',
        {params :order_data}
      )
      .pipe(
        map((data: any) => {
          
          if ('error' in data){
              return  {error:data.error.data.message}
          } else if('result' in data){
              return data.result;
          }else{
              
            return {error:data}
          }
          
        })
      );
  }

  getIpAddress(){
    return this.httpClient.get("https://api64.ipify.org/?format=json").pipe(map((res:any)=>{
      
      return res.ip
    }));
  }

  getCountryByIp(ip){
    return this.httpClient.get("https://ipapi.co/"+ip+"/json/").pipe(map((res:any)=>{
      
      this.countryCode = res.country;
    }));
  }

  getClientConfigPublic() {
    return this.httpClient.get(
      environment.url + '/rb_delivery/get_client_config'
    );
  }

  async register(user) {
    user['db'] = environment.db
    const dataTosend = { params: user };
    let headers= new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
        })

      headers.set('Accept-Language', '')
    return this.httpClient
      .post(
        environment.url+'/rb_delivery/register',
        dataTosend,{headers:headers,withCredentials:true,reportProgress:true}
      )
      .pipe(
        map((data: any) => {
            if(data && data.result && data.result[0].message){
              return  data.result[0].message
            }
            else if(data.error && data.error.data && data.error.data.arguments && data.error.data.arguments[0]){
              return data.error.data.arguments[0]
            }else if(data.error){
              throw new Error(JSON.stringify(data.error));
            }
          return true;
        })
      );
  }


  async logIn(user) {
    const promise = new Promise((resolve, reject) => {
      this.odooJsonRPC.login(environment.db, user['mobile_number'], user['password']).then(
        data => {
            resolve(data.body.result);
        },
        err => {
          reject(err);
          console.log(err);
        }
      );
    });
    return promise;
  }
  async getSessionInfo() {
    const promise = new Promise((resolve, reject) => {
      this.odooJsonRPC.getSessionInfo().then(
        data => {
          
          if (data &&
            data.body &&
            data.body.result &&
            data.status == 200
          ) {
            resolve(data.body.result);
            
          }
          else {
            reject(data)
          }
        },
        err => {
          resolve(err);
          console.log(err);
        }
      );
    });
    return promise;
  }
  mobileLogoutAction() {
    const modelName = 'rb_delivery.user';
    return from(
      this.odooJsonRPC.logout()).pipe(
        map((data: any) => {
          // result here should be the server id
          if (data.body.error && data.body.error.data.arguments && data.body.error.data.arguments[0]) {
            throw new Error(JSON.stringify(data.body.error.data.arguments[0]));
          } else if (data.body.error) {
            throw new Error(JSON.stringify(data.body.error));
          }
          return data.body.result;
        })
      )
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
  loadPublicOneSignal() {
    
    const model = 'rb_delivery.one_signal'
    
    return this.httpClient
      .post(
        environment.url + "/storex/api/global/",
        {params : {
          model:model,
          domain:[],
          fields:['id','web_app_id'],
          limit:1,
          offset:0,
          sort:null,
          lang:this.language
        } })
      .pipe(
        map((data: any) => {
          
          if ('error' in data){
            throw new Error(JSON.stringify(data.error));
          } else if('result' in data){
            return data.result.records;
          }
        })
      );
  }

  updateUserOneSignal(id, userId) {
    
    const modelName = 'rb_delivery.user';
    console.log("userId", userId)
    
    return from(
      this.odooJsonRPC.updateRecord(modelName, id, { player_id: userId })
    )
      .pipe(
        map(data => {
          
          
          if (data.body.error) {
            const error = {
              message: 'Error adding record',

              model: modelName,
              odoo: data
            };
            throw new Error(JSON.stringify(error));
          }

          return data.body.result;
        })
      )
      .pipe(
        catchError((error: any) => {
          
          return throwError(error);
        })
      );
  }

  addProductToWishlist(id, product_id) {
    const modelName = 'rb_delivery.user';
    
    return from(
      this.odooJsonRPC.updateRecord(modelName, id, { wishlist_product_ids: [[4,product_id]] })
    )
      .pipe(
        map(data => {
          
          if (data.body.error) {
            const error = {
              message: 'Error adding record',

              model: modelName,
              odoo: data
            };
            throw new Error(JSON.stringify(error));
          }

          return data.body.result;
        })
      )
      .pipe(
        catchError((error: any) => {
          
          return throwError(error);
        })
      );
  }

  removeProductFromWishlist(id, product_id) {
    const modelName = 'rb_delivery.user';
    
    return from(
      this.odooJsonRPC.updateRecord(modelName, id, { wishlist_product_ids: [[3,product_id]] })
    )
      .pipe(
        map(data => {
          
          if (data.body.error) {
            const error = {
              message: 'Error adding record',

              model: modelName,
              odoo: data
            };
            throw new Error(JSON.stringify(error));
          }

          return data.body.result;
        })
      )
      .pipe(
        catchError((error: any) => {
          
          return throwError(error);
        })
      );
  }


  loadGeneralConfiguration(){
    const modelName = 'rb_delivery.general_configuration';
    return from(
      this.odooJsonRPC.searchRead(
        modelName,
        [],
        [],
        1,
        0,
        null
      )
    )
      .pipe(
        map(data => {
    
          if (data.body.error) {
            const error = {
              message: 'Error getting storex location record',
              model: modelName,
              odoo: data
            };
            throwError(JSON.stringify(error));
          }
    
          return data.body.result.records;
        })
      )
  }

  async loadOrders(domain?,offset?,limit?) {
    const promise = new Promise((resolve, reject) => {
      this.odooJsonRPC.searchRead('storex_modules.order',domain || [["status", "!=", "deleted"]],['status','sequence','create_date','order_line_ids','money_collection_cost','delivery_fee','currency_id'],limit || 0,offset || 0,"").then(
        data => {
            resolve(data.body.result);
            
          
        },
        err => {
          resolve(err);
          console.log(err);
        }
      );
    });
    return promise;
  }

  async loadOrderLines(domain?,offset?,limit?) {
    const promise = new Promise((resolve, reject) => {
      this.odooJsonRPC.searchRead('storex_modules.order.line',domain || [],['product_id','qty','order_id','price_subtotal','price_unit'],limit || 0,offset || 0,"").then(
        data => {
            
            resolve(data.body.result);
            
          
        },
        err => {
          resolve(err);
          console.log(err);
        }
      );
    });
    return promise;
  }
  loadStatuses() {
    const promise = new Promise((resolve, reject) => {
      this.odooJsonRPC.searchRead('rb_delivery.status',[['status_type','=','storex_order']],['name','title','role_segment_status_ids'],0,0,"").then(
        data => {
            
            resolve(data.body.result);
            
          
        },
        err => {
          resolve(err);
          console.log(err);
        }
      );
    });
    return promise;
  }

}
