import { Component, OnInit } from '@angular/core';
import {  ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { OrderState } from 'src/app/stores/order/store';
import * as OrderSelector from 'src/app/stores/order/store/selectors'
import * as OrderActions from 'src/app/stores/order/store/actions'

import { OrderLineState } from 'src/app/stores/order-line/store';
import * as OrderLineSelector from 'src/app/stores/order-line/store/selectors'
import * as OrderLineActions from 'src/app/stores/order-line/store/actions'

import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
})
export class OrdersPage implements OnInit {


  public orderss: any;
  selectedOrders='ar'
  prviousOrdersId;
  currentLang: any;
  env=environment
  selectedStatus='all'

  destroyed$ = new Subject<any>();
  orders=[]
  orderLineIds=[]
  orderLines=[]
  event: any;
  domain=[]
  statuses=[]
  allStatuses=[]
  constructor(
    public modalCont: ModalController,
    public translate:TranslateService,
    public storage:Storage,
    public navCtrl: NavController,
    private odooAPI:OdooAPI,
    public shared:SharedService,
    private orderStore : Store<OrderState>,
    private orderLineStore : Store<OrderState>,) {
    this.prviousOrdersId = localStorage.langId;
    this.orderss=[
      {
        name:'ARABIC',
        code:'ar'
      },
      {
        name:'ENGLISH',
        code:'en'
      }

    ]

  }
  ngOnInit(){
    this.loadStatuses()
    this.loadOrders()
    this.orderStore.select(OrderSelector.selectAllData).pipe(takeUntil(this.destroyed$)).subscribe(data=>{
      for(let order of data){
        if(!this.orderLineIds.includes(order.order_line_ids)){
          this.orderLineIds=this.orderLineIds.concat(order.order_line_ids)
          
        }
      }
      this.loadOrderLines(this.orderLineIds)
      
      if(this.event)
          this.event.target.complete()
      this.orders = data
        
    })
    this.selectOrderLines()
  }
  loadOrders(event?){
    
    if(event){
      this.orderStore.dispatch(new OrderActions.LoadHttp({}))
      this.event=event
      setTimeout(()=>{
        this.event.target.complete()
      },1000)
    }else{
      this.orderStore.dispatch(new OrderActions.LoadHttp({search:this.domain}))
    }
      
  }
  getStatusTitle(statusName){
    return this.allStatuses.filter(status=>status.name==statusName)[0].title
  }
  changeSegment(statusName){
    if(statusName=='all'){
      this.domain=[]
      
    }else{
      this.domain=[['status','=',statusName]]
    }
    this.selectedStatus=statusName
    
    this.loadOrders()
  }

  loadOrderLines(ids){
    this.orderLineStore.dispatch(new OrderLineActions.LoadHttp({search:[['id','in',ids]],limit:0,offset:0}))
  }

  selectOrderLines(){
    this.orderLineStore.select(OrderLineSelector.selectAllData).pipe(takeUntil(this.destroyed$)).subscribe(data=>{
      this.orderLines = data
    })
  }
  getLines(orderId){
    return this.orderLines.filter(x=>x.order_id[0]==orderId)
  }
  loadStatuses(){
    this.odooAPI.loadStatuses().then((response:any)=>{
      this.allStatuses=response.result
      this.statuses=this.allStatuses.filter(status=>status.role_segment_status_ids.includes(this.shared.loginInfo.user_info.group_id[0]))
    })
  }

  updateOrders(event) {
    
  }
  dismiss() {
    this.modalCont.dismiss();
  }
  ionViewDidLeave(): void {
    this.destroyed$.next({})
    this.destroyed$.unsubscribe()
  }


}
