import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { LanguagePage } from 'src/app/components/language/language.page';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Share } from '@capacitor/share'
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { SharedService } from 'src/app/services/sharedService';
import { OrdersPage } from 'src/app/components/orders/orders.page';
import { ModernTemplateLoginComponent } from '../modern-template-login/modern-template-login.component';
import { AboutUsComponent } from '../../about-us/about-us.component';
import { PrivacyAndPolicyComponent } from '../../privacy-and-policy/privacy-and-policy.component';
import { WishlistPage } from '../../wishlist/wishlist.page';


@Component({
  selector: 'app-modern-template-setting',
  templateUrl: './modern-template-setting.component.html',
  styleUrls: ['./modern-template-setting.component.scss'],
})
export class ModernTemplateSettingComponent implements OnInit {
  userName: any;
  userId: any;
  userMobile: any;
  loginStatus: string;
  whatsappMessage: any;
  @Input() showBackButton
  env = environment
  template: any;
  constructor(
    private translate: TranslateService,
    private storage: Storage,
    private modalCtrl: ModalController,
    private alertCtrl:AlertController,
    private odooApi: OdooAPI,
    public sharedService : SharedService
  ) { }

  ngOnInit() {
    this.getGeneralConfiguration()
    this.storage.get('selectedTemplates').then(selectedTemp => {
      if(selectedTemp && selectedTemp.length >0){
      this.template = selectedTemp[0].selected_landing_page_template
      }
    })
  }

  openLoginForm(){
    if(this.showBackButton){
      this.modalCtrl.dismiss()
    }
    const modal = this.modalCtrl.create({
      component: ModernTemplateLoginComponent,
      componentProps:{segmantValue:'login'}
    })
    modal.then(mod => {
      mod.present()
      mod.onDidDismiss().then(data=>{
        if (data.data && data.data.loggedIn){
          this.modalCtrl.dismiss()
        }
      });
    })
  }

  logOut(){
    this.odooApi.mobileLogoutAction().subscribe(response=>{
      if(response)
      this.storage.clear().then(() =>{
        window.location.reload()
      })
    })
  }

  openWishlistsModal(){
    const modal = this.modalCtrl.create({
      component: WishlistPage,
    })
    modal.then(mod=>{
      mod.present()
    })
  }

  openLanguageModal(){
    this.alertCtrl.create({
      header:this.translate.instant("SELECT_LANGUAGE"),
      mode:'ios',
      buttons:[
        {
          text:this.translate.instant('ARABIC'),
          handler:(()=>{
            this.storage.set('currentLanguage','ar')
            window.location.reload()
          }),
          role:this.translate.currentLang=='ar'?'destructive':'none'
        },
        {
          text:this.translate.instant('ENGLISH'),
          handler:(()=>{
            this.storage.set('currentLanguage','en')
            window.location.reload()
          }),
          role:this.translate.currentLang=='en'?'destructive':'none'
        }
      ]
    }).then(alert=>alert.present())
  }

  async shareApp(){
    let title = this.whatsappMessage
    let url=this.env.url
    await Share.share({
      title: title,
      url: url,
    }
  )
  }

  getGeneralConfiguration(){
    let requestData: PublicOdooRequest = {
      domain: [],
      fields: ['share_link_message'],
      limit: 1,
      model: "rb_delivery.general_configuration",
      offset: 0
    }
    this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(data =>{
      if(data && data.length > 0 && data[0].share_link_message){
        this.whatsappMessage = data[0].share_link_message
      }
    })
  }
  openOrdersModal(){
    const modal = this.modalCtrl.create({
      component: OrdersPage,
    })
    modal.then(mod=>{
      mod.present()
    })
  }
  openPage(x){

  }
  openAccountPage(){
    
  }
  openProductsPage(){

    
  }

  back(){
    this.modalCtrl.dismiss()
  }

  openAboutUs(){
    this.modalCtrl.create({
      component : AboutUsComponent,

    }).then(modal =>{
      modal.present()
    })
  }

  openPrivacyAndPolicy(){
    this.modalCtrl.create({
      component : PrivacyAndPolicyComponent,

    }).then(modal =>{
      modal.present()
    })
  }
}
