import * as fromCountryActions from '../actions';

import { CountryState, initialState, countryAdapter } from '../state/country.state';

export function CountryReducer(
  state = initialState,
  action: fromCountryActions.CountryActions
): CountryState {
  switch (action.type) {
    case fromCountryActions.CountryActionsType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromCountryActions.CountryActionsType.UPDATE_OFFSET: {
      return {
        ...state,
        offset: state.offset + state.limit
      };
    }

    case fromCountryActions.CountryActionsType.UPDATE_LIMIT: {
      return {
        ...state,
        limit: action.limit
      };
    }

    case fromCountryActions.CountryActionsType.UPDATE_DATA_LENGTH: {
      return {
        ...state,
        offset: action.dataLength
      };
    }

    case fromCountryActions.CountryActionsType.LOAD_HTTP_SUCCESS: {
      // add countrys to entities
      const offset = state.offset + action.payload.length;
      if (state.offset === 0) {
        return {
          ...countryAdapter.addMany(action.payload, state),
          loading: false,
          offset
        };
      } else {
        return {
          ...countryAdapter.upsertMany(action.payload, state),
          offset,
          loading: false
        };
      }
    }

    case fromCountryActions.CountryActionsType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromCountryActions.CountryActionsType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }
    case fromCountryActions.CountryActionsType.LOAD_PUBLIC_HTTP_SUCCESS: {
           let countryRegister=state.countryRegister
           countryRegister=action.payload.slice()
           return {...state,countryRegister}
          }

    case fromCountryActions.CountryActionsType.UPDATE_SEARCH: {
            return {
              ...state,
              search: action.search,
              offset: 0
            };
          }          
  }
  return state;
}
