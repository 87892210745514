<ng-container *ngIf="sharedService.loginInfo && sharedService.loginInfo.user_info && sharedService.loginInfo.user_info.id">
  <div class="username">{{sharedService.loginInfo.user_info.username}}</div>
</ng-container>
<ion-grid>

  <ion-row class="row-style" *ngIf="!sharedService.loginInfo ||  !sharedService.loginInfo.user_info">
    <ion-col class="col-style" size="12" (click)="openLoginForm()">
      <div class="icon-wrapper">
        <ion-icon src="../../../../assets/icon/login_reg_icon_settings.svg" class="icon-settings"></ion-icon>
      </div>
      <span class="text-style">{{"LOGIN_AND_REGISTRATION"|translate}}</span>
    </ion-col>
  </ion-row>

  <!-- Edit Profile -->
  <!-- <ion-row class="row-style" *ngIf="sharedService.loginInfo &&  sharedService.loginInfo.user_info">
    <ion-col class="col-style" size="12">
      <div class="icon-wrapper">
        <ion-icon name="create-outline" class="icon-style"></ion-icon>
      </div>
      <span class="text-style">{{"EDIT_PROFILE"|translate}}</span>
    </ion-col>
  </ion-row> -->

  <!-- Previous Order -->
  <ion-row (click)="openOrdersModal()" class="row-style" *ngIf="sharedService.loginInfo &&  sharedService.loginInfo.user_info">
    <ion-col class="col-style" size="12">
      <div class="icon-wrapper">
        <ion-icon src="../../../../assets/icon/login_reg_icon_settings.svg" class="icon-settings" ></ion-icon>
      </div>
      <span class="text-style">{{"PREVIOS_ORDERS"|translate}}</span>
    </ion-col>
  </ion-row>

  <!-- Favorite -->
  <ion-row *ngIf="sharedService.loginInfo && sharedService.loginInfo.user_info && sharedService.loginInfo.user_info.id" class="row-style" (click)="openWishlistsModal()">
    <ion-col class="col-style" size="12">
      <div class="icon-wrapper">
        <ion-icon src="../../../../assets/icon/login_reg_icon_settings.svg" class="icon-settings"></ion-icon>
      </div>
      <span class="text-style">{{"FAVORITE"|translate}}</span>
    </ion-col>
  </ion-row>
<!-- about Us -->
  <ion-row class="row-style" (click)="openAboutUs()">
    <ion-col class="col-style" size="12">
      <div class="icon-wrapper">
        <ion-icon src="../../../../assets/icon/about_us_icon.svg" class="icon-settings"></ion-icon>
      </div>
      <span class="text-style">{{"ABOUT_US"|translate}}</span>
    </ion-col>
  </ion-row>

  <!-- Privacy Policy -->
  <ion-row class="row-style" (click)="openPrivacyAndPolicy()">
    <ion-col class="col-style" size="12">
      <div class="icon-wrapper">
        <ion-icon src="../../../../assets/icon/privicy_policy_icon.svg" class="icon-settings"></ion-icon>
      </div>
      <span class="text-style">{{"PRIVACY_POLICY"|translate}}</span>
    </ion-col>
  </ion-row>

  <ion-row class="row-style">
    <ion-col class="col-style" size="12" (click)="openLanguageModal()">
      <div class="icon-wrapper">
        <ion-icon src="../../../../assets/icon/language_icon.svg" class="icon-settings"></ion-icon>
      </div>
      <span class="text-style">{{"LANGUAGE"|translate}}</span> 
    </ion-col>
  </ion-row>

  <ion-row class="row-style logout-row" *ngIf="sharedService.loginInfo &&  sharedService.loginInfo.user_info">
    <ion-col (click)="logOut()" size="12" class="col-style logout-col">
      <span style="color:#ffffff;font-weight: bold;margin: 0 30px;"> {{"LOGOUT"|translate}} </span>
      <div
        style=" border-radius: 100%; margin: 0 10px; width: 40px ;height: 40px; display: flex; align-items: center; justify-content: center;">
        <ion-icon name="log-out-outline" class="icon-style logout-icon"></ion-icon>
      </div>
    </ion-col>
  </ion-row>

</ion-grid>