<ion-content>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="back()">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-segment [value]="segmantValue" (ionChange)="segmentChanged($event)">
      <ion-segment-button value="login">
        <ion-label>{{'LOGIN' | translate}}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="signUp">
        <ion-label>{{'SIGN_UP' | translate}}</ion-label>
      </ion-segment-button>
    </ion-segment>
      <form [formGroup]="logInFormGroup" *ngIf="showLogInForm">
        <ion-grid>
          <ion-list>
            <ion-item lines="none">
              <ion-row
                style="text-transform: capitalize;letter-spacing: 2.28px;font-size: 20px;margin-bottom: 10px;font: normal normal bold 24px/12px Cairo;color: #1E2329;"
                >
                <ion-col size="12">
                  {{'LOGIN' | translate}}
                </ion-col>
                </ion-row>
            </ion-item>
            <ion-item lines="none">
              <ion-label
                style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
                position="stacked">{{'MOBILE_NUMBER' | translate}}</ion-label>
              <ion-input
                style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
                type="tel" required pattern="^[\u0621-\u064A\u0660-\u06690-9 ]+$"
                formControlName="mobile_number"></ion-input>
            </ion-item>
    
            <div class="form-validation-err">
              <ng-container *ngFor="let validation of validation_messages.mobile_number">
                <div class="error-message" *ngIf="
                logInFormGroup.get('mobile_number').hasError(validation.type) &&
                    (logInFormGroup.get('mobile_number').dirty ||
                    logInFormGroup.get('mobile_number').touched ||
                      submitAttempt)
                  ">
                  <ion-icon name="information-circle-outline"></ion-icon>
                  {{ validation.message | translate }}
                </div>
              </ng-container>
            </div>
    
            <ion-item lines="none">
              <ion-label style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
              position="stacked">{{'PASSWORD' | translate}}</ion-label>
              <ion-input style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
              required='true' type="password" formControlName="password"></ion-input>
            </ion-item>
            <div class="form-validation-err">
              <ng-container *ngFor="let validation of validation_messages.password">
                <div class="error-message" *ngIf="
                logInFormGroup.get('password').hasError(validation.type) &&
                    (logInFormGroup.get('password').dirty ||
                    logInFormGroup.get('password').touched ||
                      submitAttempt)
                  ">
                  <ion-icon name="information-circle-outline"></ion-icon>
                  {{ validation.message | translate }}
                </div>
              </ng-container>
            </div>
          </ion-list>
          <ion-row style="display: flex;justify-content: center;margin-top: 20px;">
            <ion-button [disabled]="loading" style="width: 345px;height: 50px;border-radius: 8px;" (click)="logIn()">
              {{'LOGIN' | translate}}
            </ion-button>
          </ion-row>
          <ion-row style="display: flex;justify-content: center;margin-top: 20px;">
            <!-- <ion-col size="12"> -->
              {{'I_DO_NOT_HAVE_ACCOUNT' | translate}}
            <!-- </ion-col> -->
          </ion-row>
          <ion-row style="display: flex;justify-content: center;margin-top: 20px;">
            <ion-button style="width: 345px;height: 50px;border-radius: 8px;" (click)="openRegistrationForm()">
              {{'SINGUP' | translate}}
            </ion-button>
    
          </ion-row>
        </ion-grid>
      </form>
    <form [formGroup]="signUpFormGroup" *ngIf="showSignUpForm">
      <ion-grid>
        <ion-list>
          <ion-item lines="none">
            <ion-row
              style="text-transform: capitalize;letter-spacing: 2.28px;font-size: 20px;margin-bottom: 10px;font: normal normal bold 24px/12px Cairo;color: #1E2329;"
              >
              <ion-col size="12">
                {{'SIGN_UP' | translate}}
              </ion-col>
              </ion-row>
          </ion-item>
          <ion-item lines="none">
            <ion-label
              style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-top: 10px;"
              position="stacked">{{'NAME' | translate}}</ion-label>
            <ion-input
              style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
              type="text" formControlName="username"></ion-input>
          </ion-item>
  
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.username">
              <div class="error-message" *ngIf="
                  signUpFormGroup.get('username').hasError(validation.type) &&
                  (signUpFormGroup.get('username').dirty ||
                    signUpFormGroup.get('username').touched ||
                    submitAttempt)
                ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
          <ion-item lines="none">
            <ion-label
              style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
              position="stacked">{{'MOBILE_NUMBER' | translate}}</ion-label>
            <ion-input
              style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
              type="tel" required pattern="^[\u0621-\u064A\u0660-\u06690-9 ]+$"
              formControlName="mobile_number"></ion-input>
          </ion-item>
  
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.mobile_number">
              <div class="error-message" *ngIf="
                  signUpFormGroup.get('mobile_number').hasError(validation.type) &&
                  (signUpFormGroup.get('mobile_number').dirty ||
                    signUpFormGroup.get('mobile_number').touched ||
                    submitAttempt)
                ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
          <ion-item lines="none">
            <ion-label
              style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
              position="stacked">{{'EMAIL' | translate}}</ion-label>
            <ion-input
              style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
              type="email" required 
              formControlName="email"></ion-input>
          </ion-item>
  
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.email">
              <div class="error-message" *ngIf="
                  signUpFormGroup.get('email').hasError(validation.type) &&
                  (signUpFormGroup.get('email').dirty ||
                    signUpFormGroup.get('email').touched ||
                    submitAttempt)
                ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
          <ion-item lines="none">
            <ion-label
              style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 20px;"
              position="stacked">{{'AREA' | translate}}</ion-label>
            <ion-select style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
               placeholder="{{'SELECT_AREA' | translate}}" (ionChange)="selectArea($event)">
              <ion-select-option *ngFor="let area of areas" [value]="area">{{ area.name}}</ion-select-option>
            </ion-select>
            <ion-input [hidden]="true" type="number" formControlName="area_id">
            </ion-input>
          </ion-item>
  
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.area_id">
              <div class="error-message" *ngIf="
                  signUpFormGroup.get('area_id').hasError(validation.type) &&
                  (signUpFormGroup.get('area_id').dirty ||
                    signUpFormGroup.get('area_id').touched ||
                    submitAttempt)
                ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
          <ion-item lines="none">
            <ion-label
              style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
              position="stacked">{{'ADDRESS' | translate}}</ion-label>
            <ion-input
              style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
              type="text" formControlName="address"></ion-input>
          </ion-item>
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.address">
              <div class="error-message" *ngIf="
                signUpFormGroup.get('address').hasError(validation.type) &&
                (signUpFormGroup.get('address').dirty ||
                  signUpFormGroup.get('address').touched ||
                  submitAttempt)
              ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
          <ion-item lines="none">
            <ion-label style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'PASSWORD' | translate}}</ion-label>
            <ion-input style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            required='true' type="password" formControlName="password"></ion-input>
          </ion-item>
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.password">
              <div class="error-message" *ngIf="
                  signUpFormGroup.get('password').hasError(validation.type) &&
                  (signUpFormGroup.get('password').dirty ||
                    signUpFormGroup.get('password').touched ||
                    submitAttempt)
                ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
          <ion-item lines="none">
            <ion-label style="text-transform: capitalize;letter-spacing: 1.33px;color: #F3A31A;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'CONFIRMED_PASSWORD' | translate}}</ion-label>
            <ion-input style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            required='true' type="password" formControlName="confirm_password"></ion-input>
          </ion-item>
          <div class="form-validation-err">
            <ng-container *ngFor="let validation of validation_messages.confirm_password">
              <div class="error-message" *ngIf="
                  signUpFormGroup.get('confirm_password').hasError(validation.type) &&
                  (signUpFormGroup.get('confirm_password').dirty ||
                    signUpFormGroup.get('confirm_password').touched ||
                    submitAttempt)
                ">
                <ion-icon name="information-circle-outline"></ion-icon>
                {{ validation.message | translate }}
              </div>
            </ng-container>
          </div>
        </ion-list>
        <ion-row style="display: flex;justify-content: center;margin-top: 20px;">
          <ion-button [disabled]="loading" style="width: 345px;height: 50px;border-radius: 8px;" (click)="signUp()">
            {{'SIGN_UP' | translate}}
          </ion-button>
  
        </ion-row>
      </ion-grid>
    </form>
</ion-content>