<ion-row style="display: flex;justify-content: center;margin-top: 10px;">
  <div
    style="font: normal normal 600 18px/12px Cairo;letter-spacing: 0.54px;color: #1E2329;text-transform: capitalize;margin-top: 10px;"
    *ngIf="isParent">
    {{'All' | translate}}
  </div>
  <div
    style="font: normal normal 600 18px/12px Cairo;letter-spacing: 0.54px;color: #1E2329;text-transform: capitalize;margin-top: 10px;"
    *ngIf="!isParent">
    {{category.name}}
  </div>
  <ion-col size="12">
    <ion-slides (ionSlideReachEnd)="loadMore($event,category)"  pager="false" scrollbar="true" [options]="{slidesPerView:2.5}">
      <ion-slide style="display: block" *ngFor="let product of products">
        <ion-row style="display: flex;justify-content: center;">
          <ion-card mode="ios" (click)="openQuickOrder(product.id)">
            <img
            style=" vertical-align: middle; background: white;width: 100%;"
            [src]="env.url+'/web/image/storex_modules.product/'+product.id+'/image/220x220'" alt="" />
          </ion-card>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;">
          <div>{{product.name}}</div>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;" [ngStyle]="{'color':companyTheme?companyTheme:'black'}">
          <div>{{product.price | number : '1.2-2'}}{{product.currency[0] }}</div>
        </ion-row>
      </ion-slide>
      <ion-slide *ngIf="loading" style="display: block;margin-block: auto;">
        <ion-row style="display: flex;justify-content: center;">
          <ion-spinner></ion-spinner>
        </ion-row>
      </ion-slide>
    </ion-slides>
  </ion-col>
</ion-row>