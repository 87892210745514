import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { CategoryState } from 'src/app/stores/category/store';
import { environment } from 'src/environments/environment';
import * as categorySelector from 'src/app/stores/category/store/selectors'
import { filter, take } from 'rxjs/operators';
import { ProductState } from 'src/app/stores/product/store';
import { IonSlides, ModalController, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { SearchPage } from 'src/app/pages/search/search.page';
import * as bannerSelector from 'src/app/stores/banner/store/selectors';
import { BannerState } from 'src/app/stores/banner/store';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import * as categoryActions from 'src/app/stores/category/store/actions';

@Component({
  selector: 'app-modern-category-slider-home',
  templateUrl: './modern-category-slider-home.component.html',
  styleUrls: ['./modern-category-slider-home.component.scss'],
})
export class ModernCategorySliderHomeComponent implements OnInit {

  @ViewChild('slides', {}) slides: IonSlides;
  categories: any;
  products: any;
  @Input() selected;
  env = environment;
  all='all'
  destroyed$ = new Subject<any>();
  sortValues = [{
    'id':'lowprice',
    'name':'Price Low To High'
  },{
    'id':'highprice',
    'name':'Price High To Low'
  },{
    'id':'newArrive',
    'name':'New Arraival'
  }]
  @Output() selectedCategory = new EventEmitter<any>();
  @Output() selectedSortValue = new EventEmitter<any>();
  categorySelected: any;
  childCategoriesIds: any;
  childCategories: any;
  companyTheme: string;
  tags = []
  banners: any;

  constructor(
    private categoryStore: Store<CategoryState>,
    private productStore: Store<ProductState>,
    public platform: Platform,
    public sharedService: SharedService,
    private bannerStore: Store<BannerState>,
    private modalCtrl: ModalController
  ) { }


  ngOnInit() {
    this.selected = 'all'
    this.categoryStore.select(categorySelector.selectParentCategory()).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.categories = data
    })
    this.companyTheme = this.sharedService.companyTheme

    this.bannerStore.select(bannerSelector.selectAllData).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.banners = data
    })

    
    this.loadCategoryTags()
  }

  sortProducts(enevt){
    this.selectedSortValue.emit({'value':enevt.detail.value.id})
  }
  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }

  loadCategoryTags() {
    let requestData: PublicOdooRequest = {
      domain: [],
      fields: [],
      limit: 10,
      model: "storex_modules.category_tag",
      offset: 0

    }
    this.categoryStore.dispatch(new categoryActions.LoadTagHttp(requestData))

    this.categoryStore.select(categorySelector.selectCategoryTags).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.tags = data
    })
  }

  viewProducts(category) {
    this.modalCtrl.create({
      component : SearchPage,
      componentProps:{showBackButton:true,
        categoryId:category.id}
      
    }).then(modal =>{
      modal.present()
      })
  }
}
