import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { CategoryState } from 'src/app/stores/category/store';
import { environment } from 'src/environments/environment';
import * as categorySelector from 'src/app/stores/category/store/selectors'
import { filter, take } from 'rxjs/operators';
import { ProductState } from 'src/app/stores/product/store';
import * as productActions from 'src/app/stores//product/store/actions';
import { IonSlides, Platform } from '@ionic/angular';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
@Component({
  selector: 'app-modern-category-slider',
  templateUrl: './modern-category-slider.component.html',
  styleUrls: ['./modern-category-slider.component.scss'],
})
export class ModernCategorySliderComponent implements OnInit {

  @ViewChild('slides', {}) slides: IonSlides;
  categories: any;
  @Input() selected;
  env = environment;
  all='all'
  destroyed$ = new Subject<any>();
  sortValues = [{
    'id':'lowprice',
    'name':'Price Low To High'
  },{
    'id':'highprice',
    'name':'Price High To Low'
  },{
    'id':'newArrive',
    'name':'New Arraival'
  }]
  @Output() selectedCategory = new EventEmitter<any>();
  @Output() selectedSortValue = new EventEmitter<any>();
  categorySelected: any;
  childCategoriesIds: any;
  childCategories: any;
  companyTheme: string;

  constructor(
    private categoryStore: Store<CategoryState>,
    private productStore: Store<ProductState>,
    public platform: Platform,
    public sharedService: SharedService,
  ) { }


  ngOnInit() {
    this.selected = 'all'
    this.categoryStore.select(categorySelector.selectParentCategory()).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {

      this.categories = data
    })
    this.companyTheme = this.sharedService.companyTheme
  }

  selectCategory(id) {
    if (id == 'all') {
      this.selected = 'all'
      this.childCategories = []
    }
      this.selectedCategory.emit({ 'id': id })
      this.categoryStore.select(categorySelector.selectCategoryById(id)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
        this.categorySelected = data[0]
        this.childCategoriesIds = this.categorySelected.child_categories
        if(this.childCategoriesIds.length > 0){
          this.categoryStore.select(categorySelector.selectCategoryByIds(this.childCategoriesIds)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
            this.childCategories = data
          })
        }
        else{
          this.childCategories = []
        }
        
      })
  
  }

  sortProducts(enevt){
    this.selectedSortValue.emit({'value':enevt.detail.value.id})
  }
  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }
}
