import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { CartPage } from 'src/app/pages/cart/cart.page';
import { LandingPagePage } from 'src/app/pages/landing-page/landing-page.page';

@Component({
  selector: 'app-modern-template-header',
  templateUrl: './modern-template-header.component.html',
  styleUrls: ['./modern-template-header.component.scss'],
})
export class ModernTemplateHeaderComponent implements OnInit {
  @Input() title:string
  @Input() showBackButton : boolean
  @Input() showCartButton : boolean = true
  @Input() makehomeClickable : boolean = true
  @Input() iconSrc : string = ''
  constructor(
    private modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
    
  }

  openCartPage(){
    const modal = this.modalCtrl.create({
      component: CartPage,
      componentProps:{
        showBackButton:true
      }
      
    })
    modal.then(mod => mod.present())
  }

  openHomePage(){
    if(this.makehomeClickable) {
      this.modalCtrl.dismiss()
    }
  }

  back(){
    this.modalCtrl.dismiss()
  }

  getIconSrc(){
    if (this.iconSrc != '') {
      if (this.iconSrc == 'home') {
        return '../../../../assets/icon/home_icon.svg'
      } else if (this.iconSrc == 'settings') {
        return '../../../../assets/icon/settings_dark.svg'
      } else if (this.iconSrc == 'category') {
        return '../../../../assets/icon/dashboard_dark.svg'
      } else {
        return '../../../../assets/icon/home_icon.svg'
      }
    } else {
      return '../../../../assets/icon/home_icon.svg'
    }
  }
}
