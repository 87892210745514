
<ion-slides class="slides">
  <ion-slide class="slide" *ngFor="let banner of banners">
    <div class="watch-card">
      <img class="watch-image" style="width: 95%;" [src]="env.url+'/web/image/storex_modules.banner/'+banner.id+'/image/'" />
      <ion-button *ngIf="banner.banner_link" expand="block" class="see-more-button"><a [href]="banner.banner_link" style="color:white; text-decoration:none">{{'See_More' | translate}}</a></ion-button>
    </div>
  </ion-slide>
</ion-slides>
<ion-slides class="slides home-catogary-container">
  <ion-slide class="slide category" *ngFor="let category of categories" (click)="viewProducts(category)">  
        <div class="category-img-container">
          <img class="category-img" [src]="env.url+'/web/image/storex_modules.category/'+ category.id +'/image'"/>
        </div>
        <div class="category-name">{{category.name}}</div>
  </ion-slide>
</ion-slides>
<div class="product-container">
  <ion-row class="product"  *ngFor="let category of categories" (click)="viewProducts(category)">
    <div class="product-img-container">
      <img class="category-img" [src]="env.url+'/web/image/storex_modules.category/'+ category.id +'/image'"/>
    </div>
  </ion-row>
</div>

