<ion-slides style="margin-block: 20px;" *ngIf="!platform.is('desktop')" pager="false" scrollbar="true" [options]="{slidesPerView:2.5}">
  <ion-slide style="display: block;">
    <ion-row style="display: block;">
      <ion-button fill="clear" (click)="selectCategory(all)" class="category" mode="ios"
       [ngStyle]="{'background-image': selected=='all'?'url(../../../../assets/imgs/all.jpg)':'linear-gradient(225deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 40%) 100%),url(../../../../assets/imgs/all.jpg)','color':selected=='all'?'black':'white'}"
       >
        <div class="category-name">{{'ALL' | translate}}</div>
      </ion-button>
    </ion-row>
    
  </ion-slide>
  <ion-slide style="display: block;" *ngFor="let category of categories">
    <ion-row style="display: block;">
      <ion-button fill="clear" class="category" (click)="selectCategory(category.id)" mode="ios" 
      [ngStyle]="{'background-image': selected==category.id?'url('+env.url+'/web/image/storex_modules.category/'+ category.id +'/image'+')':'linear-gradient(225deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 40%) 100%),url('+env.url+'/web/image/storex_modules.category/'+ category.id +'/image'+')','color':selected==category.id?'black':'white'}"
      >
        <div class="category-name">{{category.name}}</div>
      </ion-button>
    </ion-row>
  </ion-slide>
</ion-slides>
