import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-template-home-page',
  templateUrl: './modern-template-home.page.html',
  styleUrls: ['./modern-template-home.page.scss'],
})
export class ModernTemplateHomePage implements OnInit {
  selectedCategoryId;
  selectedStoreValue;
  changingCategory;
  changingSort;

  constructor() { }

  ngOnInit() {
  }

  changeCategory(event) {
    
    this.changingCategory = true
    this.selectedCategoryId = event.id
    setTimeout(() => {
      this.changingCategory = false
    }, 500)

  }

  sortProducts(event){
    this.changingSort = true
    this.selectedStoreValue = event.value
    setTimeout(() => {
      this.changingSort = false
    }, 500)
  }
}
