import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-success-order',
  templateUrl: './success-order.component.html',
  styleUrls: ['./success-order.component.scss'],
})
export class SuccessOrderComponent implements OnInit {

  @Input() order:any;
  companyMobile
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private sharedService:SharedService

  ) { }

  ngOnInit() {
    if(this.sharedService.companyInfo && this.sharedService.companyInfo.mobile_number){
      this.companyMobile=this.sharedService.companyInfo.mobile_number
    }
  }

  openProductsPage() {
    this.router.navigate(['landing-page'])
    this.modalCtrl.dismiss()
  }
}
