<ion-content *ngIf="!platform.is('desktop') || (platform.is('desktop') && productIds )">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ng-container *ngIf="productId">


    <ion-grid style="padding:5px 50px 0px 50px">
      <ion-row style="display: flex; align-items: center; justify-content: center;">
        <ion-col size="12" *ngIf="product && product.id"
          style="font-size:13px; margin: 0 0px; border-radius: 15px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); display: flex; justify-content: center; align-items: center;padding: 0 !important;">
          <img [src]="env.url+'/web/image/storex_modules.product/'+productId+'/image/'" alt="" style="width:100%" />
        </ion-col>
      </ion-row>
      <ion-row style="margin-top: 20px;">
        <ion-col size="8" text-start>
          <div>
            <span style="font-size: 16px;">{{product.name}}</span>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>


    <ion-grid>
      <ion-row *ngIf="categories">
        <ion-col size="12">
  
          <!-- Category Title Wrapper -->
          <div style="margin-bottom: 15px;">
            <ion-label
              style="color: #3c4048; margin-inline: 20px; font-size: 24px; margin-top: 20px; text-transform: uppercase; letter-spacing: 1.14px;">
              {{'CATEGORIES' | translate}}
            </ion-label>
          </div>
  
          <!-- Categories as Badges -->
          <ng-container *ngFor="let category of categories">
            <ion-badge style="color: white; background-color: #3c4048; font-size: 20px; margin-right: 10px;">
              {{category.name}}
            </ion-badge>
          </ng-container>
  
        </ion-col>
      </ion-row>
  
    </ion-grid>
  

  </ng-container>



  <form [formGroup]="formGroup">
    <ion-grid>
      <ion-list>
        <ion-item lines="none" *ngIf="productId">
          <ion-label [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
            style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;margin-bottom: 20px;"
            position="stacked">{{'VARIANTS' | translate}}</ion-label>
          <ion-select *ngIf="productVariants.length > 1"
            style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
            placeholder="{{'SELECT_VARIANT' | translate}}" (ionChange)="selectVariant($event)"
            [value]="selectedVariant">
            <ion-select-option *ngFor="let variant of productVariants" [value]="variant">{{
              variant.name}}</ion-select-option>
          </ion-select>
          <ion-input readonly="true" style="padding: 10px;" *ngIf="productVariants.length == 1"
            [value]="selectedVariant.name">
          </ion-input>
          <ion-input [hidden]="true" type="number" formControlName="product_id">
          </ion-input>
        </ion-item>
        <div class="form-validation-err" *ngIf="productId">
          <ng-container *ngFor="let validation of validation_messages.product_id">
            <div class="error-message" *ngIf="
              formGroup.get('product_id').hasError(validation.type) &&
              (formGroup.get('product_id').dirty ||
                formGroup.get('product_id').touched ||
                submitAttempt)
            ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none" *ngIf="selectedVariant && productId">
          <ion-label [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
            style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'STOCK' | translate}}</ion-label>
          <ion-row style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;">
            <ion-col size="3" style="display: flex;justify-content: center;align-items: center;">
              <ion-row>
                <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyPlus()"
                  name="add-outline"></ion-icon>
              </ion-row>
            </ion-col>
            <ion-col style="margin-block: auto;display: flex;justify-content: space-around;" size="6">
              {{quantity}}
            </ion-col>
            <ion-col style="display: flex;justify-content: center;align-items: center;" size="3">
              <ion-row>
                <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyMenus()"
                  name="remove-outline"></ion-icon>
              </ion-row>
            </ion-col>

          </ion-row>
        </ion-item>
        <ion-item *ngIf="productId">
          <ion-row style="display: contents;">
            <ion-col [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
              style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;">{{'PRICE:' |
              translate}}
            </ion-col>
            <ion-col style="margin-inline: 10px;display: flex;justify-content: end;">
              {{product.price}}{{product.currency[0] | number : '1.2-2'}} </ion-col>
          </ion-row>
        </ion-item>
        <ion-item *ngIf="productId && !product.matched_currency">
          <ion-row style="display: contents;">
            <ion-col size="12"
              style="font: 16px/11px Cairo;letter-spacing: 0.36px;color: #3B4046;text-transform: capitalize;display: flex;justify-content: center;">
              {{'THIS_PRODUCT_IS_UNAVAILABLE_IN_YOUR_CURRENCY_AND_USE' | translate}}{{product.currency[0]}}</ion-col>
          </ion-row>
        </ion-item>
        <ion-item lines="none">
          <ion-row
            style="font: normal normal bold 16px/8px Cairo;letter-spacing: 1.52px;color: #1E2329;text-transform: capitalize;opacity: 1;margin-top: 30px">
            {{'ORDER_INFORMATION' | translate}}
          </ion-row>
        </ion-item>

        <ion-item lines="none">
          <ion-label [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
            style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;margin-top: 10px;"
            position="stacked">{{'NAME' | translate}}</ion-label>
          <ion-input
            style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            type="text" formControlName="client_name"></ion-input>
        </ion-item>

        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_name">
            <div class="error-message" *ngIf="
                formGroup.get('client_name').hasError(validation.type) &&
                (formGroup.get('client_name').dirty ||
                  formGroup.get('client_name').touched ||
                  submitAttempt)
              ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none">
          <ion-label [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
            style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'MOBILE_NUMBER' | translate}}</ion-label>
          <ion-input
            style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            type="tel" required pattern="^[\u0621-\u064A\u0660-\u06690-9 ]+$"
            formControlName="client_mobile"></ion-input>
        </ion-item>

        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_mobile">
            <div class="error-message" *ngIf="
                formGroup.get('client_mobile').hasError(validation.type) &&
                (formGroup.get('client_mobile').dirty ||
                  formGroup.get('client_mobile').touched ||
                  submitAttempt)
              ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none">
          <ion-label [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
            style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;margin-bottom: 20px;"
            position="stacked">{{'AREA' | translate}}</ion-label>
          <ion-select style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px;"
            placeholder="{{'SELECT_AREA' | translate}}" [value]="selectedAreaId" (ionChange)="selectArea($event)">
            <ion-select-option *ngFor="let area of areas" [value]="area.id">{{ area.name}}</ion-select-option>
          </ion-select>
          <ion-input [hidden]="true" type="number" formControlName="client_area">
          </ion-input>
        </ion-item>

        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_area">
            <div class="error-message" *ngIf="
                formGroup.get('client_area').hasError(validation.type) &&
                (formGroup.get('client_area').dirty ||
                  formGroup.get('client_area').touched ||
                  submitAttempt)
              ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
        <ion-item lines="none">
          <ion-label [ngStyle]="{'color':companyTheme?companyTheme:'black'}"
            style="text-transform: capitalize;letter-spacing: 1.33px;font-size: 20px;margin-bottom: 10px;"
            position="stacked">{{'ADDRESS' | translate}}</ion-label>
          <ion-input
            style="background: #FAFAFA 0% 0% no-repeat padding-box;border: 1px solid #3B4046;border-radius: 8px;margin-top: 15px;padding: 5px !important;"
            type="text" formControlName="client_address"></ion-input>
        </ion-item>
        <div class="form-validation-err">
          <ng-container *ngFor="let validation of validation_messages.client_address">
            <div class="error-message" *ngIf="
              formGroup.get('client_address').hasError(validation.type) &&
              (formGroup.get('client_address').dirty ||
                formGroup.get('client_address').touched ||
                submitAttempt)
            ">
              <ion-icon name="information-circle-outline"></ion-icon>
              {{ validation.message | translate }}
            </div>
          </ng-container>
        </div>
      </ion-list>



      <ion-row class="row-style" style="background: linear-gradient(180deg, #3C4048 0%, #191825 100%);margin: 10px 30px;" >

        <ion-col size="12" class="col-style" (click)="sendOrderForm()">
          <label style="color: #FFFFFF;font-size: 18px;padding: 0px 25px;font-weight: bold;"> {{'ORDER_NOW' | translate}}</label>
          <ion-spinner *ngIf="loading"></ion-spinner>

        </ion-col>
    
      </ion-row>


    </ion-grid>
  </form>


</ion-content>