import { Action } from '@ngrx/store';
import { IOrder } from '../../model';

export enum OrderActionsType {
  ADD_HTTP = '[Order] Add  Http',
  ADD_SUCCESS_HTTP = '[Order] Add  Http Success',
  ADD_FAIL_HTTP = '[Order] Add  Http Fail',

  REMOVE_HTTP = '[Order] Remove  Http',
  REMOVE_SUCCESS_HTTP = '[Order] Remove  Http Success',
  REMOVE_FAIL_HTTP = '[Order] Remove  Http Fail',

  LOAD_HTTP = '[Order] Load Http',
  LOAD_SUCCESS_HTTP = '[Order] Load Http Success',
  LOAD_HTTP_FAIL = '[Order] Load Http Fail',

  UPDATE = '[Order] Update  ',
  UPDATE_SUCCESS = '[Order] Update  Success',
  UPDATE_FAIL = '[Order] Update  Fail',
}

export class AddHttp implements Action {
  readonly type = OrderActionsType.ADD_HTTP;
  constructor(public payload: IOrder) {}
}

export class AddHttpSuccess implements Action {
  readonly type = OrderActionsType.ADD_SUCCESS_HTTP;
  constructor(public payload: IOrder) {}
}

export class AddHttpFail implements Action {
  readonly type = OrderActionsType.ADD_FAIL_HTTP;
  constructor(public payload: IOrder) {}
}

export class RemoveHttp implements Action {
  readonly type = OrderActionsType.REMOVE_HTTP;
  constructor(public payload: IOrder) {}
}

export class RemoveHttpSuccess implements Action {
  readonly type = OrderActionsType.REMOVE_SUCCESS_HTTP;
  constructor(public payload: IOrder) {}
}

export class RemoveHttpFail implements Action {
  readonly type = OrderActionsType.REMOVE_FAIL_HTTP;
  constructor(public payload: IOrder) {}
}

export class LoadHttp implements Action {
  readonly type = OrderActionsType.LOAD_HTTP;
  constructor(public payload: any) {}
}

export class LoadHttpSuccess implements Action {
  readonly type = OrderActionsType.LOAD_SUCCESS_HTTP;
  constructor(public payload: IOrder[]) {}
}

export class LoadHttpFail implements Action {
  readonly type = OrderActionsType.LOAD_HTTP_FAIL;
  constructor(public payload: IOrder[]) {}
}

export class Update implements Action {
  readonly type = OrderActionsType.UPDATE;
  constructor(public payload: IOrder) {}
}

export class UpdateSuccess implements Action {
  readonly type = OrderActionsType.UPDATE_SUCCESS;
  constructor(public payload: IOrder) {}
}

export class UpdateFail implements Action {
  readonly type = OrderActionsType.UPDATE_FAIL;
  constructor(public payload: IOrder) {}
}

export type OrderActions =
  | AddHttp
  | AddHttpSuccess
  | AddHttpFail
  | RemoveHttp
  | RemoveHttpFail
  | RemoveHttpSuccess
  | LoadHttp
  | LoadHttpFail
  | LoadHttpSuccess
  | Update
  | UpdateFail
  | UpdateSuccess