import * as fromBannerActions from '../actions';

import { BannerState, initialState, BannerAdapter } from '../state/banner.state';

export function BannerReducer(
  state = initialState,
  action: fromBannerActions.BannerActions
): BannerState {
  switch (action.type) {
    case fromBannerActions.BannerActionsType.LOAD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    case fromBannerActions.BannerActionsType.UPDATE_OFFSET: {
      return {
        ...state,
        offset: state.offset + state.limit
      };
    }

    case fromBannerActions.BannerActionsType.UPDATE_LIMIT: {
      return {
        ...state,
        limit: action.limit
      };
    }

    case fromBannerActions.BannerActionsType.UPDATE_DATA_LENGTH: {
      return {
        ...state,
        offset: action.dataLength
      };
    }

    case fromBannerActions.BannerActionsType.LOAD_HTTP_SUCCESS: {
      // add Banners to entities
      
      const offset = state.offset + action.payload.length;
      if (state.offset === 0) {
        return {
          ...BannerAdapter.addMany(action.payload, state),
          loading: false,
          offset
        };
      } else {
        return {
          ...BannerAdapter.upsertMany(action.payload, state),
          offset,
          loading: false
        };
      }
    }

    case fromBannerActions.BannerActionsType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromBannerActions.BannerActionsType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }
    case fromBannerActions.BannerActionsType.LOAD_PUBLIC_HTTP_SUCCESS: {
           let BannerRegister=state.BannerRegister
           BannerRegister=action.payload.slice()
           return {...state,BannerRegister}
          }

    case fromBannerActions.BannerActionsType.UPDATE_SEARCH: {
            return {
              ...state,
              search: action.search,
              offset: 0
            };
          }          
  }
  return state;
}
