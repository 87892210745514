import {Injectable} from '@angular/core';
import {OdooAPI} from './odoo/services/odooAPI';
import { Storage } from '@ionic/storage';
import { OneSignal } from 'onesignal-ngx';
import { environment } from 'src/environments/environment';



@Injectable()
export class OnesignalService {
    
    
    constructor(
      private odooAPI : OdooAPI, 
      private storage:Storage,
      private oneSignal: OneSignal
      ) {}
    app_id: string;
    async init() {
        if(!this.app_id){
            console.log('Onesignal Warning:','Please set web_app_id on onesignal configuration')
            return
        }
        await this.oneSignal.init({
            appId: this.app_id,
            allowLocalhostAsSecureOrigin:true
        })
        
        
        let granted = (await this.oneSignal.getNotificationPermission()) == "granted"
        let playerId = ""
        
        if(granted){
            playerId = await this.oneSignal.getUserId()
            this.oneSignal.registerForPushNotifications()
        }
            
        else{
            this.oneSignal.showNativePrompt()
        }
        return playerId || false
    }


    

    


    setupPush() {
            
        this.storage.get('login_info').then(loginInfo => {
            
            
                this.odooAPI.loadPublicOneSignal().subscribe(data => {
                    
                    this.app_id = data[0].web_app_id
                    if (!loginInfo) {
                        this.init()
                    }else{
                        this.init().then(playerId=>{
                            
                            this.odooAPI.updateUserOneSignal(loginInfo.userInfo.id, playerId).subscribe()
                        })
                    }

                    
                });
                    
                    
            
        });
        
    }
}
