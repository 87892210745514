<ion-header *ngIf="!platform.is('desktop')" [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="product && product.name">
      {{'PRODUCT_DETAILS' | translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>
<span *ngIf="product && !platform.is('desktop')">

  <ion-fab *ngIf="selectedVariant"
    style="position: fixed;bottom: 65px;margin-bottom:50px" vertical="bottom" slot="fixed">

    <ion-fab-button style="--background: #1BD741;;" *ngIf="platform.is('desktop')"
      href="https://web.whatsapp.com/send?phone={{customerPhone}}&text={{whatsappMessage}}">
      <ion-icon name='logo-whatsapp'></ion-icon>
    </ion-fab-button>
    <ion-fab-button style="--background: #1BD741;;" *ngIf="!platform.is('desktop')"
      href="https://wa.me/+{{customerPhone}}?text={{whatsappMessage}}">
      <ion-icon name='logo-whatsapp'></ion-icon>
    </ion-fab-button>
  </ion-fab>


  <ion-grid>
    <ion-row style="display: flex; align-items: center; justify-content: center;">
      <ion-col size="12" *ngIf="product && product.id"
        style="font-size:13px; margin: 0 0px; border-radius: 15px; box-shadow: 0 4px 8px rgba(0,0,0,0.1); display: flex; justify-content: center; align-items: center;padding: 0 !important;">
        <img [src]="env.url+'/web/image/storex_modules.product/'+productId+'/image/'" alt="" style="width:100%" />
      </ion-col>
    </ion-row>
  </ion-grid>


  <div style="display: none;">
  <ion-grid style="padding:5px 50px 0px 50px">


    <ion-row style="margin-top: 20px;">
      <ion-col size="8" text-start>
        <div>
          <span style="font-size: 16px;">{{product.name}}</span>
        </div>
      </ion-col>
      <ion-col size="4" style="display: flex;justify-content: end;">
        <span style="font-weight: bold; font-size: 20px;">{{product.price | number : '1.2-2'}}{{product.currency[0] }}</span>
      </ion-col>


      <ion-col size="6" style="margin-top:15px;">
        <!-- ... -->
      </ion-col>

      <ion-col size="6" style="display: flex; justify-content: space-between; align-items: center;margin-top: 15px;">
        <!-- Minus Icon -->
        <ion-icon
          style="width: 32px; height: 32px; border-radius: 50%; background-color: #191825; display: flex; align-items: center; justify-content: center;color: #ffffff; margin-right: 10px;"
          (click)="qtyMenus()" name="remove-outline">
        </ion-icon>

        <!-- Quantity Display -->
        <ion-input text-center [value]="quantity" (ionInput)="onInput($event)"
          style="width: 40px; height: 30px; align-self: center; margin: 0 10px;text-align: center;padding: 0 !important;--padding-end:0">
        </ion-input>

        <!-- Plus Icon -->
        <ion-icon
          style="width: 32px; height: 32px; border-radius: 50%; background-color: #191825; display: flex; align-items: center; justify-content: center;color: #ffffff; margin-left: 10px;"
          (click)="qtyPlus()" name="add-outline">
        </ion-icon>
      </ion-col>
    </ion-row>





  </ion-grid>



  <ion-grid>
    <ion-row *ngIf="categories">
      <ion-col size="12">

        <!-- Category Title Wrapper -->
        <div style="margin-bottom: 15px;">
          <ion-label
            style="color: #3c4048; margin-inline: 20px; font-size: 24px; margin-top: 20px; text-transform: uppercase; letter-spacing: 1.14px;">
            {{'CATEGORIES' | translate}}
          </ion-label>
        </div>

        <!-- Categories as Badges -->
        <ng-container *ngFor="let category of categories">
          <ion-badge style="color: white; background-color: #3c4048; font-size: 20px; margin-right: 10px;">
            {{category.name}}
          </ion-badge>
        </ng-container>

      </ion-col>
    </ion-row>

  </ion-grid>



  <ion-row style="padding: 20px;" *ngIf="product.meta_description">
    <ion-col size="12">
      <!-- Description Title -->
      <div
        style="color: #3c4048; margin-bottom: 15px; font-size: 24px; text-transform: uppercase; letter-spacing: 1.14px;">
        {{'DESCRIPTION' | translate}}
      </div>
    </ion-col>

    <!-- Description Content -->
    <ion-col [ngStyle]="{'overflow': 'hidden', 'height': isReadMoreDesc ? 'auto' : '50px'}">
      <span style="font-size: 0.8rem;">
        {{product.meta_description}}
      </span>
    </ion-col>

    <!-- Toggle Button -->
    <ion-col style="display: flex;align-items: center;"
      *ngIf="product.meta_description.length>(sharedService.innerWidth/2.11)" size="auto">
      <ion-button size="small" fill="clear" (click)="isReadMoreDesc=!isReadMoreDesc">
        <ion-icon *ngIf="!isReadMoreDesc" name="caret-forward-outline"></ion-icon>
        <ion-icon *ngIf="isReadMoreDesc" name="caret-down-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>


  <ion-row *ngIf="productVariants && productVariants.length>0" style="padding-inline: 20px;margin-top: 20px;">
    <!-- Variants Title -->
    <ion-col size="12">
      <ion-label
        style="color: #3c4048; margin-bottom: 15px; font-size: 24px; text-transform: uppercase; letter-spacing: 1.14px;">
        {{'VARIANTS' | translate}}
      </ion-label>
    </ion-col>

    <!-- Variants Dropdown -->
    <ion-col size="12">
      <ion-select style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;padding: 10px" mode="ios"
        placeholder="{{'SELECT_VARIANT' | translate}}" (ionChange)="selectVariant($event)" [value]="selectedVariant">
        <ion-select-option *ngFor="let variant of productVariants" [value]="variant">
          {{ variant.name }}
        </ion-select-option>
      </ion-select>
    </ion-col>
  </ion-row>


  <ion-row class="row-style" style="background: linear-gradient(180deg, #3C4048 0%, #191825 100%);margin: 20px 30px;" *ngIf="sharedService.templateType == 'advance'">
      <ion-col size="12" [ngStyle]="!selectedVariant ? {'opacity': '0.5', 'pointer-events': 'none'} : {}"  class="col-style" (click)="openOrderForm(product.id)">

      <label style="color: #FFFFFF;font-size: 18px;padding: 0px 25px;font-weight: bold;">{{'ORDER_NOW' | translate}}</label>
    </ion-col>
  </ion-row>

    <ion-row class="row-style" style="background: linear-gradient(180deg, #3C4048 0%, #191825 100%);margin: 10px 30px;" *ngIf="sharedService.templateType == 'advance'">

      <ion-col size="12" class="col-style" [ngStyle]="!selectedVariant ? {'opacity': '0.5', 'pointer-events': 'none'} : {}" (click)="addToCart()">

      <label style="color: #FFFFFF;font-size: 18px;padding: 0px 25px;font-weight: bold;"> {{'ADD_TO_CART' | translate}}</label>
    </ion-col>

  </ion-row>
  
  </div>
</span>