import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { OrderLineActionsType } from '../actions';
import * as OrderLineActions from '../actions';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { OrderLineState } from '../state';
import * as OrderLineSelectors from '../selectors';
import { Storage } from '@ionic/storage';
import { IOrderLine } from '../../model';

@Injectable()
export class OrderLineEffects {
  constructor(
    private actions$: Actions,
    private odooAPI: OdooAPI,
    private OrderLineStore: Store<OrderLineState>,
    private storage:Storage,

    ) {}

    @Effect()
    addLocalHttp$ = this.actions$.pipe(
      ofType(OrderLineActionsType.ADD_LOCAL_HTTP),
      switchMap((addLocalHTTPAction: OrderLineActions.AddLocalHttp) => {
        return of({...addLocalHTTPAction.payload});
      }),
      switchMap((payload: any) => {
        var orderlineAdded = payload; 
        orderlineAdded.isCart = true;
        return this.storage.get('OrderLineLine').then(
          (data: any) => {
            if (!data) {
              data = [];
            }
            if (data.length > 0) {
              let orderLineAddQty = data.filter(cartItem => cartItem.id == orderlineAdded.id);
              if (orderLineAddQty && orderLineAddQty.length > 0) {
                for (let orderLine of data) {
                  if (orderLine.id == orderlineAdded.id) {
                    orderLine.qty = orderLine.qty + 1;
                  }
                }
              } else {
                data.push(orderlineAdded);
              }
            } else {
              data.push(orderlineAdded);
            }
            this.storage.set('OrderLineLine', data);
            return new OrderLineActions.AddLocalHttpSuccess(orderlineAdded);
          },
          err => {
            return new OrderLineActions.AddLocalHttpFail(err);
          }
        );
      })
    );
    

  @Effect()
  removeLocalHttp$ = this.actions$.pipe(
    ofType(OrderLineActionsType.REMOVE_LOCAL_HTTP),
    switchMap((removeLocalHTTPAction: OrderLineActions.RemoveLocalHttp) => {
      return of(removeLocalHTTPAction.variantIds);
    }),
    switchMap((variantIds) => {
      return this.storage.get('OrderLineLine').then(
          (data: any) => {
            
            let newCart=data.filter(cart=>!variantIds.includes(cart.id))
            let removedIds = []
            data.forEach(cart=>{
              if(variantIds.includes(cart.id)){
                removedIds.push(cart.id)
              }
            })
            this.storage.set('OrderLineLine',newCart)
            return new OrderLineActions.RemoveLocalHttpSuccess(removedIds);
          },
          err => {
            return new OrderLineActions.RemoveLocalHttpFail(err);
          }
        
      );
    })
  );

  @Effect()
  clearCartLocal$ = this.actions$.pipe(
    ofType(OrderLineActionsType.CLEAR_CART_LOCAL),
    switchMap((removeLocalHTTPAction: OrderLineActions.ClearCartLocal) => {
      return this.storage.remove('OrderLineLine').then(
          () => {

            return new OrderLineActions.ClearCartLocalSuccess();
          },
          err => {
            return new OrderLineActions.ClearCartLocalFail(err);
          }
        
      );
    }),
  );

  @Effect()
  loadLocalHttp$ = this.actions$.pipe(
    ofType(OrderLineActionsType.LOAD_LOCAL_HTTP),
    switchMap((loadLocalHTTPAction: OrderLineActions.LoadLocalHttp) => {
      return of(loadLocalHTTPAction.payload);
    }),
    switchMap((payload: any) => {
      return this.storage.get('OrderLineLine').then(
          (data: any) => {
            return new OrderLineActions.LoadLocalHttpSuccess(data || []);
          },
          err => {
            return new OrderLineActions.LoadLocalHttpFail(err);
          }
        
      );
    })
  );

  @Effect()
  loadHttp$ = this.actions$.pipe(
    ofType(OrderLineActionsType.LOAD_HTTP),
    switchMap((loadLocalHTTPAction: OrderLineActions.LoadHttp) => {
      return of(loadLocalHTTPAction.payload);
    }),
    
    withLatestFrom(     
      this.OrderLineStore.select(OrderLineSelectors.selectOffset),
      this.OrderLineStore.select(OrderLineSelectors.selectLimit),
      this.OrderLineStore.select(OrderLineSelectors.selectSearch),
    ),
    switchMap(([payload,offset,limit,search]) => {
      if(!payload.offset && typeof payload.offset != "number")
        payload.offset=offset
      if(!payload.limit)
        payload.limit=limit
      if(!payload.search)
        payload.search=search
      return this.odooAPI.loadOrderLines(payload.search,payload.offset,payload.limit).then(
          (data: any) => {
            return new OrderLineActions.LoadHttpSuccess(data.result || []);
          },
          err => {
            return new OrderLineActions.LoadHttpFail(err);
          }
        
      );
    })
  );


  @Effect()
  updateLocal$ = this.actions$.pipe(
    ofType(OrderLineActionsType.UPDATE_LOCAL),
    switchMap((updateLocalHTTPAction: OrderLineActions.UpdateLocal) => {
      return of(updateLocalHTTPAction.payload);
    }),
    switchMap((payload: any) => {
      var orderlineAdded = payload
      return this.storage.get('OrderLineLine').then(
          (data: any) => {
            if(!data){
              data = []
            }
            if(data.length > 0){
              let orderLineAddQty = data.filter(cartItem => cartItem.id == orderlineAdded.id)
              if(orderLineAddQty && orderLineAddQty.length > 0){
                for(let orderLine of data){
                  if(orderLine.id == orderlineAdded.id){
                    orderLine.qty = orderLine.qty-1
                  }
                }
              }
            }
            
            this.storage.set('OrderLineLine',data)
            return new OrderLineActions.UpdateLocalSuccess(orderlineAdded);
          },
          err => {
            return new OrderLineActions.UpdateLocalFail(err);
          }
        
      );
    })
  );
}
