<ion-app style="background: white; ">
  <ion-card *ngIf="countryCode" class="main" style="border-radius: 0; height: 100%; margin: 0 auto;width: 100%;">
    <ion-router-outlet></ion-router-outlet>
  </ion-card>
  <div *ngIf="!countryCode" class="spinner-container" color="secondray">
    <ion-spinner color="primary" name="lines"></ion-spinner>
    <p>{{ 'Loading' }}..</p>
  </div>
  
</ion-app>
