import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProductState, productAdapter } from '../state/product.state';

export const selectProductState = createFeatureSelector<ProductState>('product');

export const getProductEntities = (state: ProductState) => {
  
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getProductLoading = (state: ProductState) => state.loading;

export const getProductUploading = (state: ProductState) => state.uploading;
export const getOffset = (state: ProductState) => state.offset;
export const getLimit = (state: ProductState) => state.limit;
export const getDataLength = (state: ProductState) => state.dataLength;
export const getSearch = (state: ProductState) => state.search;


const {
  
  selectEntities,
  selectAll,
  selectTotal
} = productAdapter.getSelectors();


export const selectAllData = createSelector(
  selectProductState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectProductState,
  selectEntities
);

export const selectOffset = createSelector(
  selectProductState,
  getOffset
);
export const selectDataLength = createSelector(
  selectProductState,
  getDataLength
);

export const selectLimit = createSelector(
  selectProductState,
  getLimit
);


export const selectProductById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    
    return data.filter(product=>product.id == id);
  }
);
export const selectProductByIds =(ids) => createSelector (
  selectAllData,
  
  (data: any) => {
    
    return data.filter(product=>ids.includes(product.id));
  }
);
export const selectSearch = createSelector(
  selectProductState,
  getSearch
);

export const selectLoading = createSelector(
  selectProductState,
  getProductLoading
);

export const selectWishlistProducts =(userId)=> createSelector(
  selectAllData,
  (data:any)=>{
    return data.filter(product=>product.wishlist_receipients.includes(userId));
  }
);