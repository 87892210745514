import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { SharedService } from 'src/app/services/sharedService';
import { productFields } from 'src/app/stores/product/model';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-modern-template-landing-page',
  templateUrl: './modern-template-landing-page.component.html',
  styleUrls: ['./modern-template-landing-page.component.scss'],
})
export class ModernTemplateLandingPageComponent implements OnInit {
  @Input() type: string;
  @Input() template: string;
  @Input() products: any;
  @Input() banners: any;
  @Input() categories: any;
  env = environment
  loadingProducts: any;
  businessName: any;
  querySearchFilter: ConcatArray<string[]>;
 
  selectedStoreValue:string;
  homePage: string;

  constructor(private storage: Storage,) { }

  ngOnInit() {
    this.storage.get('selectedTemplates').then(selectedTemp => {
      if(selectedTemp && selectedTemp.length >0){
      this.homePage = selectedTemp[0].selected_home_page_template
      }
    })
  }
}
