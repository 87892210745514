export interface IProductVariant {
  price_unit: number;
  id: number;
  name: string;
  image: string;
  description: number;
  price: string;
  stock: string;
  commercial_id: number;
  category_ids: string;
  product_description: string;
  product_type: number;
  alternative_products_ids: string;
  accessory_products_ids: string;
  sequence: number;
  products_variant_ids: string;
  share_link: string;
  currency:string;
  matched_currency:boolean;
  virtual_quantity:number;
  image_url:string;
  product_attachments:any;
  product_id:number;
}
export const productVariantFields = [
  'id',
  'name',
  'price_unit',
  'commercial_id',
  'currency',
  'virtual_quantity',
  'product_attachments',
  'image_url',
  'product_id'
];