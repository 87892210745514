import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {

  context:any

  constructor(
    private odooApi: OdooAPI,
    private sanitizer:DomSanitizer,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    const parser = new DOMParser();
    let data ={ 
      model: 'storex_commerce.template_selector', 
      domain: [], 
      offset: 0, 
      limit: 1, 
      order:null,
      fields: ['about_us'],
      country_code:false
    }
    this.odooApi.loadPublicRecords(data).subscribe(aboutUsContext => {
      if(aboutUsContext && aboutUsContext.length > 0){
        this.context =  this.sanitizer.bypassSecurityTrustHtml(aboutUsContext[0].about_us);
      }
      
    })
  }

  back(){
    this.modalCtrl.dismiss()
  }

}
