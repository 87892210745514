import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetButton, ActionSheetController, AlertButton, AlertController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { filter, skip, take, takeUntil } from 'rxjs/operators';
import { SharedService } from 'src/app/services/sharedService';
import { ProductState } from 'src/app/stores/product/store';
import * as productSelector from 'src/app/stores/product/store/selectors';

import { ProductVariantState } from 'src/app/stores/product-variant/store';
import * as productVariantSelector from 'src/app/stores/product-variant/store/selectors';
import * as productVariantActions from 'src/app/stores/product-variant/store/actions';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { environment } from 'src/environments/environment';
import * as productActions from 'src/app/stores//product/store/actions';
import { OrderLineState } from 'src/app/stores/order-line/store';
import * as orderLineActions from 'src/app/stores/order-line/store/actions';
import * as orderLineSelectors from 'src/app/stores/order-line/store/selectors';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';
import { productVariantFields } from 'src/app/stores/product-variant/model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modern-template-search',
  templateUrl: './modern-template-search.component.html',
  styleUrls: ['./modern-template-search.component.scss'],
})
export class ModernTemplateSearchComponent implements OnInit,OnDestroy {

  destroyed$ = new Subject<any>();
  products= []
  env = environment
  loading = true
  loaded: boolean;
  inCartIds=[]
  wishlistIds=[]
  constructor(
    private productStore: Store<ProductState>,
    private productVariantStore: Store<ProductVariantState>,
    private router: Router,
    public sharedService: SharedService,
    private modalCtrl : ModalController,
    private orderLineStore: Store<OrderLineState>,
    private alertCtrl:ActionSheetController,
    private translate:TranslateService,
    private odooApi: OdooAPI,
    public shared:SharedService
  ) { }

  ngOnDestroy(): void {
    this.destroyed$.next({})
    this.destroyed$.unsubscribe()
  }

  ngOnInit() {
    this.selectProduct()
  }

  selectProduct(){
    this.productStore.select(productSelector.selectLoading).pipe(takeUntil(this.destroyed$)).subscribe(loading => {
      
      this.loading = loading
    })
    combineLatest([
      this.productStore.select(productSelector.selectAllData),
      this.orderLineStore.select(orderLineSelectors.selectAllCartProductIds)
    ])
    .pipe(takeUntil(this.destroyed$)).subscribe(([products,cartProductIds]) => {
            if(this.products.length==0&&this.loaded)
              this.loading=false
            
            if(!this.loading)
              this.loaded=true
            this.inCartIds = cartProductIds
            this.products = products
    })
  }

  checkAddedToInCartIds(products_variant_ids: string) {
    return this.inCartIds.some(id=>(new Set(products_variant_ids)).has(id))
  }


  openQuickOrder(id) {
    
    this.router.navigate(['quick-order/' + id])
    this.modalCtrl.dismiss()
    
  }

  loadMoreProducts(event){
    
    this.productStore.dispatch(new productActions.LoadHttp({}))
    setTimeout(() => {
      event.target.complete();
    }, 1000);
}

addToWishlist(product) {

  this.odooApi.addProductToWishlist(this.shared.loginInfo.user_info.id, product.id).subscribe(response => {
      if (response) {
          let updatedProduct = {
              ...product,
              wishlist_receipients: [...product.wishlist_receipients, this.shared.loginInfo.user_info.id]
          };
          product = updatedProduct;
          this.wishlistIds.push(product.id);
      }
  });
}


removeFromWishlist(product){
  this.odooApi.removeProductFromWishlist(this.shared.loginInfo.user_info.id, product.id).subscribe(response => {
    if(response){
      let index = product.wishlist_receipients.findIndex(x => x == this.shared.loginInfo.user_info.id);
      if (index != -1){
        product.wishlist_receipients.splice(index, 1); 
        this.wishlistIds = this.wishlistIds.filter(id => id !== product.id);
      }
    }
  });
}

chooseVariant(product){
  let buttons:ActionSheetButton[] = []
  let requestData: PublicOdooRequest = {
    domain: [['id', 'in', product.products_variant_ids]],
    fields: productVariantFields,
    limit: 10,
    model: "storex_modules.product_variant",
    offset: 0
  }
  this.productVariantStore.dispatch(new productVariantActions.LoadHttp(requestData))
  this.productVariantStore.select(productVariantSelector.selectProductVariantByProductId(product.id)).pipe(filter(variants=>variants.length==product.products_variant_ids.length),take(1)).subscribe(variants=>{
    variants.forEach(variant => {
      let button:ActionSheetButton = {
        text:variant.name,
        id:variant.id,
        handler:(()=>{
          this.qtyPlus(variant.id)
        })
      }
      buttons.push(button) 
    });
    this.alertCtrl.create({
      buttons,
      header:this.translate.instant("SELECT_VARIANT")
    }).then(alert=>alert.present())
  })
  
}

qtyPlus(variantId){
  this.orderLineStore.dispatch(new orderLineActions.AddLocalHttp({id:variantId,product_id:variantId,qty : 1}))

}

qtyMenus(variantId){

    this.orderLineStore.dispatch(new orderLineActions.UpdateLocal({id:variantId,product_id:variantId,qty : -1}))
  
}

removeFromCart(variantIds){
  variantIds = variantIds.filter(id=>this.inCartIds.includes(id))
  this.orderLineStore.dispatch(new orderLineActions.RemoveLocalHttp(variantIds))
  
}

containsArabic(name: string): boolean {
  // Regular expression to check if the name contains Arabic characters
  const arabicLetters = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
  return arabicLetters.test(name);
}

back(){
  this.modalCtrl.dismiss()
}

}
