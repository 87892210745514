
<ion-content style="--background:white; font-family: 'Tajawal';">
  <ion-list style="--background:white;">
    <ion-radio-group value="{{selectedLanguage}}" (ionChange)="updateLanguage($event)">
      <ion-item style="--background:white;" *ngFor="let l of languages">
        <ion-label style="font-family: 'Tajawal'">{{l.name | translate}}</ion-label>
        <ion-radio class="radio" value="{{l.code}}" ></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>