import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ProductVariantState } from 'src/app/stores/product-variant/store/state';
import { ProductState } from 'src/app/stores/product/store';
import { OrderLineState } from 'src/app/stores/order-line/store';
import * as orderLineActions from 'src/app/stores/order-line/store/actions';
import * as productSelector from 'src/app/stores/product/store/selectors';
import * as productVariantSelector from 'src/app/stores/product-variant/store/selectors';
import { environment } from 'src/environments/environment';
import { CategoryState } from 'src/app/stores/category/store';
import * as  CategorySelector from 'src/app/stores/category/store/selectors';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { SuccessOrderComponent } from '../success-order/success-order.component';
import { IProductVariant } from 'src/app/stores/product-variant/model';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { Storage } from '@ionic/storage';
import { FormValidationService } from 'src/app/services/formValidation.service';
import { PublicOdooRequest } from 'src/app/services/odoo/models/ModelRemoteOdoo';


@Component({
  selector: 'app-quick-order-form',
  templateUrl: './quick-order-form.component.html',
  styleUrls: ['./quick-order-form.component.scss'],
})
export class QuickOrderFormComponent implements OnInit {

  @Input() productId: any;
  product: any;
  productVariants: any;
  env = environment;
  categories: any;
  formGroup: FormGroup;
  validation_messages: any;
  selectedAreaId: any;
  selectedVariant: any;
  orderSuccess: boolean;
  areas: any[];
  quantity: number = 0;
  submitAttempt = false;
  destroyed$ = new Subject<any>();
  selectedAreaName: any;
  createUid: any;
  @Input() productIds: any;
  companyTheme: string;
  loading: boolean;
  senderArea: any;
  priceList: any;
  defaultOrderTypeId: any;
  deliveryFee: any;
  selectedSubAreaId: any;
  subAreas:any[];
  configurations:any[];
  showSecondClientMobile=false
  showSubArea=false

  constructor(
    private productStore: Store<ProductState>,
    private orderLineStore: Store<OrderLineState>,
    private productVarientStore: Store<ProductVariantState>,
    private categoryStore: Store<CategoryState>,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private odooApi: OdooAPI,
    private toastCtrl: ToastController,
    public platform: Platform,
    public sharedService:SharedService,
    private storage:Storage,

  ) { }

  ngOnInit() {
    this.loadClientConfig()
    this.initForm()
    if(this.selectedVariant){
      this.formGroup.get('product_id').setValue(this.selectedVariant.id)
    }
    this.loadProduct(this.productId)
    this.loadProductVariants(this.productId)
    this.loadCategories()
    this.loadAreas()
    this.getDefaultOrderType()
    this.formGroup.get('qty').setValue(this.quantity)
    if(this.productIds){
      this.formGroup.get('product_ids').setValue(this.productIds)
    }
    this.companyTheme = this.sharedService.companyTheme
    
  }
  loadProduct(id) {
    this.productStore.select(productSelector.selectProductById(id)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.product = data[0]
      this.getSenderDeliveryFee()
    })
  }
  loadProductVariants(productId) {

    this.productVarientStore.select(productVariantSelector.selectProductVariantByProductId(productId)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.productVariants = data
      if (this.productVariants.length == 1){
        this.selectedVariant = this.productVariants[0]
        
        this.formGroup.get('product_id').setValue(this.selectedVariant.id)
      }

    })
  }
  loadCategories() {
    if (this.product && this.product.category_ids) {
      this.categoryStore.select(CategorySelector.selectCategoryByIds(this.product.category_ids)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {

        this.categories = data
      })
    }
  }
  back() {
    this.modalCtrl.dismiss()
  }
  initForm() {
    this.formGroup = this.formBuilder.group({
      client_name: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),

      client_mobile: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10)
        ])
      ),
      second_client_mobile: new FormControl(
        '',
        Validators.compose([
          Validators.maxLength(10),
          Validators.minLength(10)
        ])
      ),

      client_area: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      client_sub_area: new FormControl(
        '',
        Validators.compose([])
      ),
      qty: new FormControl(
        '',
        Validators.compose([])
      ),
      client_address: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      product_id: new FormControl(
        '',
        Validators.compose([])
      ),
      product_ids: new FormControl(
        '',
        Validators.compose([])
      ),
    });


    this.validation_messages = {
      client_name: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      client_mobile: [
        { type: 'required', message: this.translate.instant('THIS_FIELD_IS_REQUIRED') },
        {
          type: 'minlength',
          message: this.translate.instant('PHONE_MUST_BE_AT_LEAST_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
        },
        {
          type: 'maxlength',
          message: this.translate.instant('PHONE_MUST_BE_') + ' ' + 10 + ' ' + this.translate.instant('NUMBERS')
        },
        {
          type: 'pattern',
          message: this.translate.instant('YOUR_PHONE_MUST_CONTAIN_ONLY_NUMBERS')
        },],
      client_area: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      client_address: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      qty: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      product_id: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],
      product_ids: [{ type: 'required', message: 'THIS_FIELD_IS_REQUIRED' }],

    }
  }

  selectArea(ev) {
    this.selectedAreaId = ev.detail.value
    this.formGroup.get('client_area').setValue(this.selectedAreaId)
    if (this.selectedAreaId){
      this.loadSubAreas()
    }
    if(this.senderArea && this.selectedAreaId && this.defaultOrderTypeId && this.priceList){
      let domain = [['from_area','=',this.senderArea[0]],['to_area','=',this.selectedAreaId],['order_type_id','=',this.defaultOrderTypeId],['pricelist_id','=',this.priceList[0]]]
      let requestData: PublicOdooRequest = {
        domain: domain,
        fields: ['id','price'],
        limit: 0,
        model: "rb_delivery.pricelist_item",
        offset: 0
      }
      this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(data =>{
        if(data && data.length > 0){
          this.deliveryFee = data[0].price
        }
      })
    }
  }

  selectVariant(ev) {
    this.selectedVariant = ev.detail.value
    this.formGroup.get('product_id').setValue(this.selectedVariant.id)

  }

  sendOrderForm() {
    this.submitAttempt = true
    if (this.formGroup.valid) {
      this.formGroup.get('client_mobile').setValue(String(this.formGroup.get('client_mobile').value))
      this.formGroup.get('second_client_mobile').setValue(String(this.formGroup.get('second_client_mobile').value))
      let values = {... this.formGroup.value}
      
      if(this.sharedService.userSharedLink){
        values['create_uid']=this.sharedService.userSharedLink
      }
      if(this.createUid){
        values['receipient_id']=this.createUid
      }
      this.loading=true
      this.odooApi.addPublicOrder(values).subscribe(order => {
        if('error' in order){
          this.toastCtrl.create({
            message:this.translate.instant(order.error),
            duration: 1000,
            position: 'top',
            mode:'ios'
          })
          .then(tst => {
            tst.present();
          });
          this.loading=false
          return
        }
        this.orderLineStore.dispatch(new orderLineActions.ClearCartLocal())
        const modal = this.modalCtrl.create({
          component: SuccessOrderComponent,
          componentProps: {
            order: order
          }
        })
        modal.then(mod => mod.present())
        this.modalCtrl.dismiss()
      })
    }
  }

  loadAreas() {

    this.odooApi.loadAreaPublic(this.odooApi.countryCode).pipe(take(1)).subscribe((areas: any[]) => {
      
      this.areas = areas
      this.checkUserInfo()
    })
  }

  qtyPlus() {
    
      this.quantity = this.quantity + 1
    this.formGroup.get('qty').setValue(this.quantity)
  }

  qtyMenus() {
    if(this.quantity>0)
      this.quantity = this.quantity - 1
      this.formGroup.get('qty').setValue(this.quantity)
    
  }

  compareById(o1, o2) {
    return o1.id === o2.id
  }

  checkUserInfo(){
    
   this.storage.get('login_info').then(data =>{
    if(data ){
      let userInfo = data.user_info
      if(userInfo['username']){
        this.formGroup.get('client_name').setValue(userInfo['username'])
      }
      if(userInfo['area_id']){
        this.selectedAreaId = userInfo['area_id'][0]
        this.formGroup.get('client_area').setValue(userInfo['area_id'][0])
      }
      if(userInfo['mobile_number']){
        this.formGroup.get('client_mobile').setValue(userInfo['mobile_number'])
      }
      if(userInfo['address']){
        this.formGroup.get('client_address').setValue(userInfo['address'])
      }
      if(userInfo['id']){
        this.createUid = userInfo['id']
      }
    }
   })

  }

  getSenderDeliveryFee(){
    let requestData: PublicOdooRequest = {
      domain: [['id','=',this.product['delivery_user_id'][0]]],
      fields: ['area_id','pricelist_id'],
      limit: 1,
      model: "rb_delivery.user",
      offset: 0
    }
    this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(data =>{
      if(data && data.length > 0){
        this.senderArea = data[0].area_id
        this.priceList = data[0].pricelist_id
      }
    })
  }

  getDefaultOrderType(){
    let requestData: PublicOdooRequest = {
      domain: [['default','=',true]],
      fields: ['code'],
      limit: 10,
      model: "rb_delivery.order_type",
      offset: 0
    }
    this.odooApi.loadPublicRecords(requestData).pipe(take(1)).subscribe(data =>{
      if(data && data.length > 0){

        this.defaultOrderTypeId = data[0].id
      }
    })
  }

  selectSubArea(ev) {
    this.selectedSubAreaId = ev.detail.value
    this.formGroup.get('client_sub_area').setValue(this.selectedSubAreaId)
  }

  loadSubAreas() {
    this.odooApi.loadSubAreaPublic(this.selectedAreaId).pipe(take(1)).subscribe((subAreas: any[]) => {
      this.subAreas = subAreas
    })
  }

  
  loadClientConfig(){
    this.odooApi.getClientConfigPublic().pipe(take(1)).subscribe((configurations: any[]) => {
      const mobileConfig = configurations.find(config => config.key === "show_second_client_mobile_in_ecommerce");
      const subAreaConfig = configurations.find(config => config.key === "show_second_client_sub_area_ecommerce");
  
      if (mobileConfig) {
        this.showSecondClientMobile = mobileConfig.value;
      }
      if (subAreaConfig) {
        this.showSubArea = subAreaConfig.value;
      }
  
    });
  }
  
}
