<div class="product-container">
  <ion-row class="product" *ngFor="let category of categories" (click)="viewProducts(category)">
    <div class="product-img-container">
      <img class="category-img" [src]="env.url+'/web/image/storex_modules.category/'+ category.id +'/image'"/>
      <div class="category-info">
        <ion-label class="category-name">{{category.name}}</ion-label>
      </div>
    </div>
  </ion-row>
</div>
