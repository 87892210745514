
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title >
      {{'ALL_ORDERS' | translate}}
    </ion-title>
    
  </ion-toolbar>
  
</ion-header>
  <div *ngIf="statuses.length>0" slot="top">
      <ion-slides pager="false" scrollbar="true"  [options]="{freeMode:true,slidesPerView:2.5}">
        <ion-slide style="text-align:center;" >
          <ion-button fill="clear" style="border-radius: 15px;" [ngStyle]="{'background':selectedStatus=='all'?shared.companyTheme?shared.companyTheme:'black':'#00000000'}" (click)="changeSegment('all')">
              <ion-label>{{'ALL_ORDERS' | translate}}</ion-label>
          </ion-button>
        </ion-slide>
        <ion-slide *ngFor="let status of statuses" style="text-align:center;" >
          <ion-button  fill="clear" style="border-radius: 15px;" [ngStyle]="{'background':selectedStatus==status.name?shared.companyTheme?shared.companyTheme:'black':'#00000000'}" (click)="changeSegment(status.name)">
              <ion-label>{{status.title}}</ion-label>
          </ion-button>
        </ion-slide>
      </ion-slides>
  </div>
<ion-content style="--background:white; font-family: 'Tajawal';">
  <ion-grid lines="none" style="--background:white;">
    <ion-row>
      <ion-card *ngFor="let order of orders" mode="ios" style="width: 100%; margin: 5px;">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label style="font-family: 'Tajawal';">{{getStatusTitle(order.status)}}</ion-label>
            </ion-col>
            <ion-col style="text-align:center;font-weight: bold;">
              <ion-label style="font-family: 'Tajawal';">{{order.sequence}}</ion-label>
            </ion-col>
            <ion-col style="text-align: end;">
              <ion-label [ngStyle]="{'color':shared.companyTheme?shared.companyTheme:'black'}" style="font-family: 'Tajawal';direction: ltr;">{{order.money_collection_cost}} {{order.currency_id[1]}}</ion-label>
            </ion-col>
            
          </ion-row>
          <ion-slides pager="false" scrollbar="true"  [options]="{freeMode:true,slidesPerView:4.5}">
            <ion-slide style="text-align:center;" *ngFor="let line of getLines(order.id)">
              <ion-grid>
                <ion-row>
                  <ion-badge mode="ios" style="position: absolute;z-index: 99999;">
                    {{line.qty}}
                  </ion-badge>
                  <ion-card style="margin: 5px;" mode="ios" >
                    
                    <img style="width: 100%;" [src]="env.url+'/web/image/storex_modules.product_variant/'+line.product_id[0]+'/image/100x100'" />
                  </ion-card>
                </ion-row>
                <ion-row>
                  <ion-col style="text-align: center;">
                    <ion-label [ngStyle]="{'color':shared.companyTheme?shared.companyTheme:'black'}" style="font-family: 'Tajawal';  direction: ltr;">{{line.price_unit}} {{order.currency_id[1]}}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-slide>
            <ion-slide style="text-align:center;">
              <ion-grid>
                <ion-row>
                  <ion-card style="margin: 5px; padding: 10px;" mode="ios" >
                    <img style="width: 100%;" src="../../../assets/icon/delivery.svg" />
                  </ion-card>
                </ion-row>
                <ion-row>
                  <ion-col style="text-align: center;">
                    <ion-label [ngStyle]="{'color':shared.companyTheme?shared.companyTheme:'black'}" style="font-family: 'Tajawal';  direction: ltr;">{{order.delivery_fee}} {{order.currency_id[1]}}</ion-label>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-slide>
          </ion-slides>
        </ion-grid>
      </ion-card>
    </ion-row>
  </ion-grid>
  <ion-infinite-scroll threshold="10px" (ionInfinite)="loadOrders($event)">
    <ion-infinite-scroll-content loadingText="{{ 'LOADING' | translate }}"></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>