
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="selectedProduct?backToWishlist():dismiss()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title >
      {{selectedProduct?selectedProduct.name:'MY_WISHLIST'}}
    </ion-title>
    
  </ion-toolbar>
  
</ion-header>
<ion-content style="--background:white; font-family: 'Tajawal';">
  <app-default-products (clicked)="showSelectedProduct($event.value)" [clickRoute]="false" *ngIf="products.length>0 && !selectedProduct" [products]="products" [isLoading]="false" style="margin: 20px;"></app-default-products>
  <app-default-quick-order-template *ngIf="selectedProduct"  [productId]="selectedProduct.id"></app-default-quick-order-template>
</ion-content>