import { Component, OnInit } from '@angular/core';
import {  ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { ProductState } from 'src/app/stores/product/store';
import * as ProductSelector from 'src/app/stores/product/store/selectors'
import * as ProductActions from 'src/app/stores/product/store/actions'

import { OrderLineState } from 'src/app/stores/order-line/store';
import * as OrderLineSelector from 'src/app/stores/order-line/store/selectors'
import * as OrderLineActions from 'src/app/stores/order-line/store/actions'

import { filter, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.page.html',
  styleUrls: ['./wishlist.page.scss'],
})
export class WishlistPage implements OnInit {


  public orderss: any;
  selectedOrders='ar'
  prviousOrdersId;
  currentLang: any;
  env=environment
  selectedStatus='all'

  destroyed$ = new Subject<any>();
  products=[]
  orderLineIds=[]
  orderLines=[]
  event: any;
  domain=[]
  selectedProduct: any;
  constructor(
    public modalCont: ModalController,
    public translate:TranslateService,
    public storage:Storage,
    public navCtrl: NavController,
    private odooAPI:OdooAPI,
    public shared:SharedService,
    private productStore : Store<ProductState>
  ) {

  }
  ngOnInit(){
    this.loadProducts()
    this.productStore.select(ProductSelector.selectWishlistProducts(this.shared.loginInfo.user_info.id)).pipe(takeUntil(this.destroyed$)).subscribe(data=>{
      let orderLineIds=[]

      
      if(this.event)
          this.event.target.complete()
      
      this.products = data
        
    })
  }
  loadProducts(event?){
    
    if(event){
      
      this.productStore.dispatch(new ProductActions.LoadHttp())
      this.event=event
      setTimeout(()=>{
        this.event.target.complete()
      },1000)
    }else{
      
      this.productStore.dispatch(new ProductActions.UpdateSearch([['wishlist_receipients','in',[this.shared.loginInfo.user_info.id]]]))
      
    }
      
  }

  changeSegment(statusName){
    if(statusName=='all'){
      this.domain=[]
      
    }else{
      this.domain=[['status','=',statusName]]
    }
    this.selectedStatus=statusName
    
    this.loadProducts()
  }


  getLines(orderId){
    return this.orderLines.filter(x=>x.order_id[0]==orderId)
  }


  updateOrders(event) {
    
  }
  dismiss() {
    this.productStore.dispatch(new ProductActions.UpdateSearch([]))
    setTimeout(()=>{
      this.modalCont.dismiss();
    },200)
  }
  ionViewDidLeave(): void {
    this.destroyed$.next({})
    this.destroyed$.unsubscribe()
  }

  backToWishlist(){
    this.selectedProduct=false
    this.loadProducts()
  }

  showSelectedProduct(product){
    
    this.selectedProduct=product
  }


}
