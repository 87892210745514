<div class="bottom-nav-container"> 
  <nav class="toolbar">
    <ul>
      <li [class.active]="activeRoute=='landing-page'">
        <ion-button fill="clear" (click)="selectedHome('landing-page')" [style]="activeRoute == 'landing-page' ? 'width: 100%' : 'width: 14vw; padding: 0'">
          <div [class]="activeRoute == 'landing-page' ? 'menu active' : 'menu right'">
            <ion-icon [src]="activeRoute == 'landing-page' ? '../../../../assets/icon/home_light.svg' : '../../../../assets/icon/home_dark.svg'"></ion-icon>
            <span> {{ 'Home' | translate }} </span>
          </div>
        </ion-button>
      </li>
      <li  [class.active]="activeRoute=='category'"> 
        <ion-button fill="clear" (click)="selectedHome('category')" [style]="activeRoute == 'category' ? 'width: 100%' : 'width: 14vw;'">
          <div [class]="activeRoute == 'category' ? 'menu active' : 'menu'">
            <ion-icon [src]="activeRoute == 'category' ? '../../../../assets/icon/dashboard_light.svg' : '../../../../assets/icon/dashboard_dark.svg'"></ion-icon>
            <span > {{ 'Catogary' | translate }}  </span>
          </div>
        </ion-button>
      </li>
      <li  [class.active]="activeRoute=='search'">
        <ion-button fill="clear" (click)="selectedHome('search')" [style]="activeRoute == 'search' ? 'width: 100%' : 'width: 14vw'">
          <div [class]="activeRoute == 'search' ? 'menu active' : 'menu'">
            <ion-icon [src]="activeRoute == 'search' ? '../../../../assets/icon/search_light.svg' : '../../../../assets/icon/search_dark.svg'"></ion-icon>
            <span > {{ 'Search' | translate }} </span>
          </div>
        </ion-button>
      </li>
      <li  [class.active]="activeRoute=='fav'" *ngIf="sharedService.loginInfo && sharedService.loginInfo.user_info && sharedService.loginInfo.user_info.id">
        <ion-button fill="clear" (click)="selectedHome('fav')">
          <div [class]="activeRoute == 'fav' ? 'menu active' : 'menu'">
            <ion-icon [src]="activeRoute == 'fav' ? '../../../../assets/icon/heart_tabs.svg' : '../../../../assets/icon/heart_tabs.svg'"></ion-icon>
            <span> {{ 'FAV' | translate }} </span>
          </div>
        </ion-button>
      </li>
      <li  [class.active]="activeRoute=='settings'">
        <ion-button fill="clear" (click)="selectedHome('settings')" >
          <div [class]="activeRoute == 'settings' ? 'menu active' : 'menu'">
            <ion-icon [src]="activeRoute == 'settings' ? '../../../../assets/icon/settings_light.svg' : '../../../../assets/icon/settings_dark.svg'"></ion-icon>
            <span> {{ 'Settings' | translate }} </span>
          </div>
        </ion-button>
      </li>
      
    </ul>
  </nav>
</div>