<ion-header>
  <ion-toolbar mode="ios">
    <ion-title>{{'PRIVACY_AND_POLICY'| translate }}</ion-title>
    
    <ion-buttons slot="end">
      <ion-button (click)="back()" slot="end">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content *ngIf="privacy">
  <div [innerHTML]="privacy"></div>
</ion-content>