import * as fromOrderActions from '../actions';

import { OrderState, initialState, OrderAdapter } from '../state/order.state';

export function OrderReducer(
  state = initialState,
  action: fromOrderActions.OrderActions
): OrderState {
  switch (action.type) {   
    
    case fromOrderActions.OrderActionsType.ADD_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    // case fromOrderActions.OrderActionsType.ADD_SUCCESS_HTTP: {
    //   if(state.entities[action.payload.id]){
    //     let changes={
    //       qty:action.payload.qty+state.entities[action.payload.id].qty
    //     }
    //     return {
    //       ...OrderAdapter.updateOne({id:action.payload.id,changes:changes},state),
    //       loading: false,
    //     };
    //   }
        
    //   else
    //     return {
    //       ...OrderAdapter.upsertOne(action.payload,state),
    //       loading: false,
    //     };
    // }

    case fromOrderActions.OrderActionsType.ADD_FAIL_HTTP: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOrderActions.OrderActionsType.REMOVE_HTTP: {
      return {
        ...state,
        loading: true
      };
    }

    // case fromOrderActions.OrderActionsType.REMOVE_SUCCESS_HTTP: {
    //   if(state.entities[action.payload.id]){
    //     return {
    //       ...OrderAdapter.removeOne(action.payload.id,state),
    //       loading: false,
    //     };
    //   }
        
    //   else
    //     return {
    //       ...OrderAdapter.upsertOne(action.payload,state),
    //       loading: false,
    //     };
    // }

    case fromOrderActions.OrderActionsType.REMOVE_FAIL_HTTP: {
      return {
        ...state,
        loading: false
      };
    }


    case fromOrderActions.OrderActionsType.LOAD_HTTP: {
      let st = state
      if(action.payload.search)
        st = OrderAdapter.removeAll(state)
      return {
        ...st,
        loading: true,
        search:action.payload.search || [],
        offset:action.payload.search?0:state.offset
      };
    }

    case fromOrderActions.OrderActionsType.LOAD_SUCCESS_HTTP: {
        return {
          ...OrderAdapter.upsertMany(action.payload,state),
          loading: false,
          offset:state.offset+action.payload.length
        };
    }

    case fromOrderActions.OrderActionsType.LOAD_HTTP_FAIL: {
      return {
        ...state,
        loading: false
      };
    }

    case fromOrderActions.OrderActionsType.UPDATE: {
      return {
        ...state,
        loading: true
      };
    }

    // case fromOrderActions.OrderActionsType.UPDATE_SUCCESS: {
    //   if(state.entities[action.payload.id]){
    //     let changes={
    //       qty:state.entities[action.payload.id].qty + action.payload.qty
    //     }
    //     return {
    //       ...OrderAdapter.updateOne({id:action.payload.id,changes:changes},state),
    //       loading: false,
    //     };
    //   }
        
    //   else
    //     return {
    //       ...OrderAdapter.upsertOne(action.payload,state),
    //       loading: false,
    //     };
    // }

    case fromOrderActions.OrderActionsType.UPDATE_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
  }
  return state;
}
