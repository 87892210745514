import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { IOrderLine } from '../../model';

export interface OrderLineState extends EntityState<IOrderLine> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  search: any;
  cartOrderLines
}

export const OrderLineAdapter: EntityAdapter<IOrderLine> = createEntityAdapter<IOrderLine>();

export const initialState: OrderLineState = OrderLineAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 50,
  search: [],
  cartOrderLines:[]
});
