import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductState } from 'src/app/stores/product/store';
import { environment } from 'src/environments/environment';
import * as productSelector from 'src/app/stores/product/store/selectors';
import { filter, map, skipUntil, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CategoryState } from 'src/app/stores/category/store';
import * as  CategorySelector from 'src/app/stores/category/store/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { IonCol, ModalController, Platform } from '@ionic/angular';
import * as productActions from 'src/app/stores//product/store/actions';
import { SharedService } from 'src/app/services/sharedService';
import { ClientConfigurationState } from 'src/app/stores/client-configuration/store/state';
import * as clientConfigurationSelectors from 'src/app/stores/client-configuration/store/selectors';
import { IClientConfiguration } from 'src/app/stores/client-configuration/model';
import { OrderLineState } from 'src/app/stores/order-line/store/state';
import * as orderLineActions from 'src/app/stores/order-line/store/actions';
import { Storage } from '@ionic/storage';
import * as OrderLineSelectors from 'src/app/stores/order-line/store/selectors';



@Component({
  selector: 'app-default-products',
  templateUrl: './default-products.component.html',
  styleUrls: ['./default-products.component.scss'],
})
export class DefaultProductsComponent implements OnInit, OnDestroy {
  @Input() clickRoute=true
  @Output() clicked = new EventEmitter<any>();
  @Input() selectedStoreValue: string;
  @Input() selectedCategoryId: any;
  env = environment
  @Input() products = [];
  destroyed$ = new Subject<any>();
  init$ = new Subject<any>();
  category: any;
  childCategories = [];
  @Input() isLoading: boolean;
  prodSubscriber: any;
  companyTheme: string;
  clientConfigurations: IClientConfiguration[];
  quickAddToCart: boolean;
  quantity : number = 0
  @ViewChild('col',{})productSize:IonCol
  @Input() showBackButton:any;
  constructor(
    private productStore: Store<ProductState>,
    private categoryStore: Store<CategoryState>,
    private router: Router,
    public platform: Platform,
    private modalCtrl: ModalController,
    public sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private clientConfigurationStore: Store<ClientConfigurationState>,
    private orderLineStore: Store<OrderLineState>,
    private storage:Storage,

  ) { }
  ngOnDestroy(): void {
    this.destroyed$.next({})
    this.destroyed$.unsubscribe()
  }
  ngOnInit() {
    
    if(this.products.length==0){
      this.selectProducts()
      this.activatedRoute.queryParams.pipe(take(1)).subscribe((res) => {
        if (res.domain) {
          let domainFilter = JSON.parse(res.domain)
          this.loadProducts( domainFilter)
        }
        else {
          this.loadProducts()
        }
      });
    }
    
    if (this.selectedStoreValue) {
      this.sharedService.selectedStoreValue = this.selectedStoreValue
    }
    this.loadConfigurations()
    this.companyTheme = this.sharedService.companyTheme
  }

  loadProducts(domainFilter?) {
    if(this.router.url != '/search'){
      this.isLoading = true
      if (domainFilter && !this.selectedCategoryId) {
        this.sharedService.productDomian = this.sharedService.productDomian.concat(domainFilter)
      }
      if (this.selectedCategoryId !== "all" && this.selectedCategoryId) {
        this.categoryStore.select(CategorySelector.selectCategoryById(this.selectedCategoryId)).pipe(take(1)).subscribe(data => {
          
          this.category = data[0]
            if (this.category.child_categories.length > 0) {
              this.getChildCategories(this.category.child_categories)
            }
            else {

            this.productStore.dispatch(new productActions.UpdateSearch([['delivery_user_id.storex_sub_domain', '=', environment.shopUrl],['category_ids','in',[this.selectedCategoryId]]]))
              this.init$.next({})
              this.init$.unsubscribe()
            }
        })
      }
      else{
        this.productStore.dispatch(new productActions.UpdateSearch(this.sharedService.productDomian))
        this.init$.next({})
        this.init$.unsubscribe()
      }
    }
    
  }


  getChildCategories(childIds) {
    this.categoryStore.select(CategorySelector.selectCategoryByIds(childIds)).pipe(filter(data => data && data.length > 0), take(1)).subscribe(data => {
      this.childCategories = data

    })
  }

  openQuickOrder(id) {
    if(this.showBackButton){
      this.modalCtrl.dismiss()
    }
    if(this.clickRoute)
      this.router.navigate(['quick-order/' + id])
    else{
      let product = this.products.filter(prod=>prod.id==id)[0]
      this.clicked.emit({value:product})
    }
      
    
  }

  sortProduct() {
    if (this.selectedStoreValue && this.products) {
      if (this.selectedStoreValue == 'lowprice') {
        this.products = this.products.sort((a, b) => {
          if (a.price > b.price) {
            return 1;
          }

          if (a.price < b.price) {
            return -1;
          }

          return 0;
        });
      }
      else if (this.selectedStoreValue == 'highprice') {
        this.products = this.products.sort((a, b) => {
          if (b.price > a.price) {
            return 1;
          }

          if (b.price < a.price) {
            return -1;
          }

          return 0;
        })
      }
      if (this.selectedStoreValue == 'newArrive') {
        this.products = this.products.sort((a, b) => {
          if (a.id < b.id) {
            return 1;
          }

          if (a.id > b.id) {
            return -1;
          }

          return 0;
        });
      }
    }
  }

  selectProducts() {

      this.productStore.select(productSelector.selectAllData).pipe( skipUntil(this.init$),takeUntil(this.destroyed$)).subscribe(data => {
        this.products = data
        this.sortProduct()
        this.isLoading = false
        for(let product of this.products){
          product['quantity'] = this.quantity
          if(product['products_variant_ids'].length == 1){
            this.orderLineStore.select(OrderLineSelectors.selectOrderLineById(product['products_variant_ids'][0])).pipe(takeUntil(this.destroyed$)).subscribe(data =>{
              if(data && data.length > 0){
                product['quantity'] = data[0]['qty']
              }
            })
        }
      }
      })

  }

  loadMoreProducts(event){
      this.productStore.dispatch(new productActions.LoadHttp({}))
      setTimeout(() => {
        event.target.complete();
      }, 1000);
  }

  loadConfigurations(){
    this.clientConfigurationStore
      .select(clientConfigurationSelectors.selectAllData)
      .pipe(
        takeUntil(this.destroyed$),
        map(data => {
          if (data.length > 0) {
            this.clientConfigurations = data;
            this.getClientConfiguration();

          }
        })
      )
      .subscribe();
  }

  getClientConfiguration() {
    if (this.clientConfigurations) {
      for (const item of this.clientConfigurations) {
        switch (item.key) {
          case 'speed_add_to_cart':
            if (item.value) {
              this.quickAddToCart = item.value
            } 
            break;
          default:
            break;
      }
    }
    }

  }

  qtyPlus(productId){
    for(let product of this.products){
      if(product.id == productId){
        product['quantity'] = product['quantity'] + 1
        this.orderLineStore.dispatch(new orderLineActions.AddLocalHttp({id:product['products_variant_ids'][0],product_id:product['products_variant_ids'][0],qty : 1}))

      }
    }
  }

  qtyMenus(productId){
    for(let product of this.products){
      if(product.id == productId){
        if(product['quantity'] > 0){
          product['quantity'] = product['quantity'] - 1
          this.orderLineStore.dispatch(new orderLineActions.UpdateLocal({id:product['products_variant_ids'][0],product_id:product['products_variant_ids'][0],qty : -1}))
        }
      }
    }
    
  }
}
