import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { OdooAPI } from './services/odoo/services/odooAPI';
import { OdooJsonRPC } from './services/odoo/services/odooJsonRPC';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { sharedComponentModule } from './components/shared.module';
import { BannerModule } from './stores/banner/banner.module';
import { CategoryModule } from './stores/category/category.module';
import { CountryModule } from './stores/country/country.module';
import { ErrorModule } from './stores/error/error.module';
import { ProductModule } from './stores/product/product.module';
import { ProductVariantModule } from './stores/product-variant/product-variant.module';
import { EffectsModule } from '@ngrx/effects';
import { SharedService } from './services/sharedService';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ClientConfigurationModule } from './stores/client-configuration/client-configuration.module';
import { OrderLineModule } from './stores/order-line/order.module';
import { OnesignalService } from './services/Onesignal';
import { OrderModule } from './stores/order/order.module';
import { VersionService } from './services/versionService';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.url+'/olivery_commerce/xstore_translation/', '.json');}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot( reducers, { metaReducers }),
    HttpClientModule,
    TranslateModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BannerModule,
    CategoryModule,
    CountryModule,
    ErrorModule,
    ProductModule,
    ProductVariantModule,
    ClientConfigurationModule,
    OrderLineModule,
    OrderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),

  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // LoadRecordsService,
    OdooAPI,
    OdooJsonRPC,
    HttpClient,
    Storage,
    sharedComponentModule,
    SharedService,
    OnesignalService,
    VersionService

    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
