<ion-header *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'">
  <ion-toolbar style="--background:white;">

    <ion-title style="color: black;text-align: center;">
      {{'MY_ACCOUNT'| translate }}
    </ion-title>
    <ion-buttons slot="start" *ngIf="showBackButton">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-header *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'">
  <app-modern-template-header iconSrc="settings" [makehomeClickable]="false" title="{{'SETTING' | translate}}" [showBackButton]="true" [showCartButton]="false"></app-modern-template-header>
</ion-header>
<ion-content>
  <ng-container *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'">

    <ion-grid style="margin-top: 25px;">
      <ion-row>
        <ion-col style="display: flex; align-items: center; gap: 10px;">
          <ion-icon size="large" *ngIf="sharedService.loginInfo &&  sharedService.loginInfo.user_info" (click)="logOut()" name="log-out-outline"></ion-icon>
          <ion-label>
            <strong *ngIf="!sharedService.loginInfo ||  !sharedService.loginInfo.user_info " style="font-size: medium;padding: 3%;"
              (click)="openLoginForm()">{{'LOGIN_AND_REGISTRATION'|translate}}</strong>
            <strong *ngIf="sharedService.loginInfo &&  sharedService.loginInfo.user_info" style="font-size: medium;"
              (click)="openAccountPage()">{{sharedService.loginInfo.user_info.username}}</strong>
          </ion-label>
          
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid style="margin-top: 25px;">
      <ion-label><strong style="font-size: medium;padding: 3%;">{{'SETTINGS'|translate}}</strong></ion-label>
      <ion-row style="margin-top: 10px;">
        <ion-col size="5" (click)="openLanguageModal()" style="display: flex;align-items: center; gap: 10px;">
          <ion-row>
            <ion-icon size="large" name="globe-outline"></ion-icon>
          </ion-row>
          <ion-row>
            <ion-label>{{'LANGUAGE'|translate}}</ion-label>
          </ion-row>
        </ion-col>
        <ion-col  size="7" style="display: flex;align-items: center;gap: 10px;">
          <ion-row>
            <ion-icon size="large" name="notifications-off-outline"></ion-icon>
          </ion-row>
          <ion-row>
            <ion-label>{{'TURN_ON_NOTIFICATIONS'|translate}}</ion-label>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row style="margin-top: 10px;">
        <ion-col  size="5" (click)="openPage('about-us')" style="display: flex;align-items: center;gap: 10px;">
          <ion-row>
            <ion-icon size="large" name="help-circle-outline"></ion-icon>
          </ion-row>
          <ion-row>
            <ion-label>{{'ABOUT_US'|translate}}</ion-label>
          </ion-row>
        </ion-col>
        <ion-col  size="7" (click)="shareApp()" style="display: flex;align-items: center;gap: 10px;">
          <ion-row>
            <ion-icon size="large" name="share-social-outline"></ion-icon>
          </ion-row>
          <ion-row >
            <ion-label>{{'SHARE_APP'|translate}}</ion-label>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid (click)="openOrdersModal()" *ngIf="sharedService.loginInfo && sharedService.loginInfo.user_info" style="margin-top: 25px;">
      <ion-label><strong style="font-size: medium;padding: 3%;">{{'MY_ORDERS'|translate}}</strong></ion-label>
      <ion-row style="margin-top: 10px;" >
        <ion-col size="6"  style="display: flex;align-items: center; gap: 10px;">
          <ion-row>
            <ion-icon size="large" name="logo-dropbox"></ion-icon>
          </ion-row>
          <ion-row>
            <ion-label>{{'VIEW_ALL_ORDERS'|translate}}</ion-label>
          </ion-row>
        </ion-col>
        <ion-col size="6" (click)="openWishlistsModal()" style="display: flex;align-items: center; gap: 10px;">
          <ion-row>
            <ion-icon size="large" name="heart"></ion-icon>
          </ion-row>
          <ion-row>
            <ion-label>{{'MY_WISHLIST'|translate}}</ion-label>
          </ion-row>
        </ion-col>

      </ion-row>

    </ion-grid>
  </ng-container>

  <ng-container *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'">
    <app-modern-template-setting></app-modern-template-setting>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <span>
      {{'VERSION' | translate}} : {{version}}
    </span>
  </ion-toolbar>
</ion-footer>