import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { IOrder } from '../../model';

export interface OrderState extends EntityState<IOrder> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  search: any;
}

export const OrderAdapter: EntityAdapter<IOrder> = createEntityAdapter<IOrder>();

export const initialState: OrderState = OrderAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 5,
  search: [],
});
