import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CountryState, countryAdapter } from '../state/country.state';

export const selectCountryState = createFeatureSelector<CountryState>('country');

export const getCountryEntities = (state: CountryState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getCountryLoading = (state: CountryState) => state.loading;

export const getCountryUploading = (state: CountryState) => state.uploading;
export const getOffset = (state: CountryState) => state.offset;
export const getLimit = (state: CountryState) => state.limit;
export const getDataLength = (state: CountryState) => state.dataLength;
export const getCountryRegister = (state: CountryState) => state.countryRegister;
export const getSearch = (state: CountryState) => state.search;


const {
  selectEntities,
  selectAll,
  selectTotal
} = countryAdapter.getSelectors();


export const selectAllData = createSelector(
  selectCountryState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectCountryState,
  selectEntities
);

export const selectOffset = createSelector(
  selectCountryState,
  getOffset
);
export const selectDataLength = createSelector(
  selectCountryState,
  getDataLength
);

export const selectLimit = createSelector(
  selectCountryState,
  getLimit
);

export const selectDataCountryedById = createSelector(
  selectAllData,
  (data: any) => {
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);
export const selectCountryById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    return data.filter(country=>country.id == id);
  }
);
export const selectCountryRegister = createSelector(
  selectCountryState,
  getCountryRegister
);
export const selectSearch = createSelector(
  selectCountryState,
  getSearch
);