import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchPage } from 'src/app/pages/search/search.page';
import { SharedService } from 'src/app/services/sharedService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modern-template-categories',
  templateUrl: './modern-template-categories.component.html',
  styleUrls: ['./modern-template-categories.component.scss'],
})
export class ModernTemplateCategoriesComponent implements OnInit {

  @Input() categories : any
  env = environment
  constructor(
    private modalCtrl: ModalController,
    public sharedService: SharedService,
  ) { }

  ngOnInit() {}
  viewProducts(category){
    this.modalCtrl.create({
      component : SearchPage,
      componentProps:{
        showBackButton:true,
        categoryId:category.id
      }
    }).then(modal =>{
      modal.present()
      })
    }
}
