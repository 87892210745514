<div  class="spinner-container" *ngIf="loading && !loaded">
  <ion-spinner name="crescent"></ion-spinner>
    <P >
      {{'LOADING' | translate}}
    </P>
</div>
<div class="product-card-container" *ngIf="loaded && products.length> 0">
  <div class="product-card-search"
  *ngFor="let product of products"
  [ngClass]="{
      'size-6': sharedService.innerWidth <= 800,
      'size-4': sharedService.innerWidth > 800 && sharedService.innerWidth <= 1000,
      'size-3': sharedService.innerWidth > 1000
  }">    <ion-col>
      <ion-col style="margin: 0;padding: 0">
        <ion-row style="display: block">
          <ion-row style="display: flex;justify-content: center;">
            <ion-card style="margin:-2px" mode="ios" (click)="openQuickOrder(product.id)">
              <div class="image"
              [ngStyle]="{'background': 'url('+env.url+'/web/image/storex_modules.product/'+product.id+'/image'+') center center / cover no-repeat'}" 
              ></div>
            </ion-card>
          </ion-row>
        </ion-row>
      </ion-col>
    </ion-col>
    <ion-col>
      <ion-row>
        <ion-row  style="display: flex;" (click)="openQuickOrder(product.id)">
          <ion-label class="product-name" [style.direction]="containsArabic(product.name) ? 'rtl' : 'ltr'">
            {{product.name}}
          </ion-label>
        </ion-row>
        <ion-row style="text-align: center;width:55vw">
          <ion-col style="text-align: center;width:70vw">
            <ion-row  style="display: flex;">
              <ion-col (click)="openQuickOrder(product.id)" style="display: flex;">
              <ion-label class="product-price-new"  [style.direction]="containsArabic(product.name) ? 'rtl' : 'ltr'">
                {{product.currency[0]}}{{product.price | number : '1.2-2'}}
              </ion-label>
            </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-row>
    </ion-col>
  </div>
</div>
<ion-infinite-scroll threshold="10px" (ionInfinite)="loadMoreProducts($event)">
  <ion-infinite-scroll-content loadingText="{{ 'LOADING' | translate }}"></ion-infinite-scroll-content>
</ion-infinite-scroll>