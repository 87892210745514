import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { OrderActionsType } from '../actions';
import * as OrderActions from '../actions';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';
import { Store } from '@ngrx/store';
import { OrderState } from '../state';
import * as OrderSelectors from '../selectors';
import { Storage } from '@ionic/storage';
import { IOrder } from '../../model';

@Injectable()
export class OrderEffects {
  constructor(
    private actions$: Actions,
    private odooAPI: OdooAPI,
    private OrderStore: Store<OrderState>,
    private storage:Storage,

    ) {}

  @Effect()
  addHttp$ = this.actions$.pipe(
    ofType(OrderActionsType.ADD_HTTP),
    switchMap((addHTTPAction: OrderActions.AddHttp) => {
      return of(addHTTPAction.payload);
    }),
    switchMap((payload: any) => {
      var orderAdded = payload
      return this.storage.get('OrderLine').then(
          (data: any) => {
            if(!data){
              data = []
            }
            if(data.length > 0){
              let orderAddQty = data.filter(cartItem => cartItem.id == orderAdded.id)
              if(orderAddQty && orderAddQty.length > 0){
                for(let order of data){
                  if(order.id == orderAdded.id){
                    order.qty = order.qty+1
                  }
                }
              }
              else{
                data.push(orderAdded)
              }
            }
            else{
              data.push(orderAdded)
            }
            this.storage.set('OrderLine',data)
            return new OrderActions.AddHttpSuccess(orderAdded);
          },
          err => {
            return new OrderActions.AddHttpFail(err);
          }
        
      );
    })
  );

  @Effect()
  removeHttp$ = this.actions$.pipe(
    ofType(OrderActionsType.REMOVE_HTTP),
    switchMap((removeHTTPAction: OrderActions.RemoveHttp) => {
      return of(removeHTTPAction.payload);
    }),
    switchMap((payload: any) => {
      var order = payload
      return this.storage.get('OrderLine').then(
          (data: any) => {
            
            data=data.filter(cart=>cart.id!=payload.id)
            this.storage.set('OrderLine',data)
            return new OrderActions.RemoveHttpSuccess(order);
          },
          err => {
            return new OrderActions.RemoveHttpFail(err);
          }
        
      );
    })
  );

  @Effect()
  loadHttp$ = this.actions$.pipe(
    ofType(OrderActionsType.LOAD_HTTP),
    switchMap((loadHTTPAction: OrderActions.LoadHttp) => {
      return of(loadHTTPAction.payload);
    }),
    
    withLatestFrom(     
      this.OrderStore.select(OrderSelectors.selectOffset),
      this.OrderStore.select(OrderSelectors.selectLimit),
      this.OrderStore.select(OrderSelectors.selectSearch),
    ),
    switchMap(([payload,offset,limit,search]) => {
      if(payload.offset && typeof payload.offset == "number")
        offset=payload.offset
      if(payload.limit)
      limit=payload.limit
      if(payload.search)
        search=payload.search

      return this.odooAPI.loadOrders(payload.search,payload.offset,payload.limit).then(
          (data: any) => {
            
            return new OrderActions.LoadHttpSuccess(data.result || []);
          },
          err => {
            return new OrderActions.LoadHttpFail(err);
          }
        
      );
    })
  );

  @Effect()
  update$ = this.actions$.pipe(
    ofType(OrderActionsType.UPDATE),
    switchMap((updateHTTPAction: OrderActions.Update) => {
      return of(updateHTTPAction.payload);
    }),
    switchMap((payload: any) => {
      var orderAdded = payload
      return this.storage.get('OrderLine').then(
          (data: any) => {
            if(!data){
              data = []
            }
            if(data.length > 0){
              let orderAddQty = data.filter(cartItem => cartItem.id == orderAdded.id)
              if(orderAddQty && orderAddQty.length > 0){
                for(let order of data){
                  if(order.id == orderAdded.id){
                    order.qty = order.qty-1
                  }
                }
              }
            }
            
            this.storage.set('OrderLine',data)
            return new OrderActions.UpdateSuccess(orderAdded);
          },
          err => {
            return new OrderActions.UpdateFail(err);
          }
        
      );
    })
  );
}
