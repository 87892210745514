<ion-item lines="full" >
  <ion-row style="width:100%;display: flex;align-items: center;">
    <ion-col size="3" text-start style="font-size:13px ;padding: 0;margin: 10px 0 5px 0;" *ngIf="product&&product.id"> <img
        style="border-radius: 4px; border:1px solid #3D3937;width:100%;"
        [src]="env.url+'/web/image/storex_modules.product_variant/'+product.id+'/image/220x220'" />
    </ion-col>
    <ion-col size="4" *ngIf="product&&product.id" text-start style="font-size:13px ;margin: 10px 0 5px 0;white-space: nowrap; 
                width: 50px; 
                overflow: hidden;
                text-overflow: ellipsis; 
                border: 1px solid white;padding-inline: 20px;">
                <ion-row style="font-family: 'Tajawal';color: #1E2329;text-transform: capitalize; overflow: hidden;text-overflow: ellipsis;height: 44px;margin-top: 10px;white-space: pre-line;">{{product.name}}</ion-row>
                <ion-row style="font-family: 'Tajawal';letter-spacing: 0.63px;text-transform: capitalize;" [ngStyle]="{'color':companyTheme?companyTheme:'black'}" >{{product.price_unit  | number : '1.2-2'}}{{product.currency[0]}}</ion-row>
    </ion-col>

    <ion-col size="5" text-center style="font-size:13px ;margin: 10px 0 5px 0;">
      <ion-row style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;">
        <ion-col size="3" style="display: flex;justify-content: center;align-items: center;">
          <ion-row>
            <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyPlus()"
              name="add-outline"></ion-icon>
          </ion-row>
        </ion-col>
        <ion-col style="margin-block: auto;display: flex;justify-content: space-around;" size="6">
          {{cartItem.qty}}
        </ion-col>
        <ion-col style="display: flex;justify-content: center;align-items: center;" size="3">
          <ion-row>
            <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyMenus()"
              name="remove-outline"></ion-icon>
          </ion-row>
        </ion-col>

      </ion-row>
    </ion-col>
    
  </ion-row>
</ion-item>
