<ion-grid *ngIf="selectedCategoryId == undefined || childCategories.length == 0" [ngStyle]="{'padding-inline':platform.is('desktop') && sharedService.innerWidth > 1300?'230px':platform.is('desktop') && sharedService.innerWidth > 1150?'120px':'0px'}">
  
  <ion-spinner *ngIf="isLoading"color="primary" name="lines"></ion-spinner>
  <ng-container *ngIf="products.length > 0">

    <ion-row>
      <ion-col *ngFor="let product of products" #productSize [size]="sharedService.innerWidth <= 800?'6':sharedService.innerWidth <= 1000?'4':'3'"
        style="text-align: center;">
        <ion-row style="display: block">
          <ion-row style="display: flex;justify-content: center;">
            <ion-card mode="ios" (click)="openQuickOrder(product.id)">
              <div 
              [ngStyle]="{'background': 'url('+env.url+'/web/image/storex_modules.product/'+product.id+'/image'+') center center / cover no-repeat'}" 
              style="width: 160px; height: 160px;background-position: center;
              background-size: cover; vertical-align: middle; background: white;"
               ></div>
            </ion-card>
          </ion-row>
          <ion-row style="display: flex;justify-content: center;">
            <ion-card mode="ios" *ngIf="quickAddToCart && product.products_variant_ids.length == 1" style="display: flex;justify-content: center;margin: 0;">
              <ion-row style="border-radius: 8px;border: 1px solid #3B4046;width: 100%;height: 46px;">
                <ion-col size="3" style="display: flex;justify-content: center;align-items: center;">
                  <ion-row>
                    <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyPlus(product.id)"
                      name="add-outline"></ion-icon>
                  </ion-row>
                </ion-col>
                <ion-col style="margin-block: auto;display: flex;justify-content: space-around;" size="6">
                  {{product.quantity}}
                </ion-col>
                <ion-col style="display: flex;justify-content: center;align-items: center;" size="3">
                  <ion-row>
                    <ion-icon style="margin-inline: 15px;margin-bottom: 5px;" (click)="qtyMenus(product.id)"
                      name="remove-outline"></ion-icon>
                  </ion-row>
                </ion-col>
        
              </ion-row>
            </ion-card>
          </ion-row>
        </ion-row>
        <ion-row  style="display: flex;justify-content: center;">
          <ion-label style="font: normal normal 600 20px/21px Cairo;color: #1E2329;text-transform: capitalize; overflow: hidden;text-overflow: ellipsis;height: 44px;margin-top: 10px;">
            {{product.name}}
          </ion-label>
        </ion-row>
        <ion-row style="display: flex;justify-content: center;margin-bottom: 20px;">
          <ion-label
          style="font: normal normal 600 21px/21px Cairo;letter-spacing: 0.63px;text-transform: capitalize;" [ngStyle]="{'color':companyTheme?companyTheme:'black'}">{{product.price | number : '1.2-2'}}{{product.currency[0]}}</ion-label>
        </ion-row>
      </ion-col>
    </ion-row>
  </ng-container>
</ion-grid>
<span *ngIf="childCategories.length > 0">
  <app-default-child-category [selectedStoreValue]="selectedStoreValue" [showBackButton]="showBackButton" [categoryId]="selectedCategoryId"></app-default-child-category>
  <ng-container *ngFor="let category of childCategories">
    <span *ngIf="category.product_ids.length > 0">
      <app-default-child-category [selectedStoreValue]="selectedStoreValue" [showBackButton] = "showBackButton" [categoryId]="category.id"></app-default-child-category>
    </span>
  </ng-container>
</span>
<ion-infinite-scroll *ngIf="childCategories.length==0" threshold="10px" (ionInfinite)="loadMoreProducts($event)">
  <ion-infinite-scroll-content loadingText="{{ 'LOADING' | translate }}"></ion-infinite-scroll-content>
</ion-infinite-scroll>