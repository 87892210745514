import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { IClientConfiguration } from '../../model';

export interface ClientConfigurationState extends EntityState<IClientConfiguration> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
}

export const clientConfigurationAdapter: EntityAdapter<IClientConfiguration> = createEntityAdapter<
  IClientConfiguration
>();

export const initialState: ClientConfigurationState = clientConfigurationAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 10
});

