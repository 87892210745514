import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { OdooAPI } from 'src/app/services/odoo/services/odooAPI';

@Component({
  selector: 'app-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.scss'],
})
export class PrivacyAndPolicyComponent implements OnInit {
  privacy:any

  constructor(
    private odooApi: OdooAPI,
    private sanitizer:DomSanitizer,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    const parser = new DOMParser();
    let data ={ 
      model: 'storex_commerce.template_selector', 
      domain: [], 
      offset: 0, 
      limit: 1, 
      order:null,
      fields: ['privacy_and_policy'],
      country_code:false
    }
    this.odooApi.loadPublicRecords(data).subscribe(privacyContext => {
      if(privacyContext && privacyContext.length > 0){
        this.privacy =  this.sanitizer.bypassSecurityTrustHtml(privacyContext[0].privacy_and_policy);
      }
      
    })
  }

  back(){
    this.modalCtrl.dismiss()
  }

}
