<ion-header *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'">
  <ion-grid class="ion-no-padding">
    <ion-row  class="ion-no-padding">
      <ion-col class="ion-no-padding" size="12">
    <ion-searchbar placeholder="{{'SEARCH' | translate}}" [ngStyle]="{'padding-inline':platform.is('desktop') && sharedService.innerWidth > 800?'100px':'0px'}" [formControl]="searchControl" showCancelButton="focus" [cancelButtonText]="('CANCEL'|translate)" name="search" placeholder="{{'SEARCH'|translate}}" >
    </ion-searchbar>
      </ion-col>
      <ion-col>
        <ion-buttons slot="start" *ngIf="showBackButton">
          <ion-button (click)="back()">
            <ion-icon slot="icon-only" name="arrow-back-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>

<ion-header *ngIf="sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'">
  <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;background: #EAEAEA;">
    <ion-searchbar placeholder="{{'SEARCH' | translate}}" showClearButton="focus" [formControl]="searchControl" mode="ios" style="flex-grow: 1; background: #EAEAEA;"></ion-searchbar>
    <ion-buttons *ngIf="showBackButton" style="background: #EAEAEA;flex-grow: 0;">
      <ion-button (click)="back()">
        <ion-icon style="background:#9a9a9a;border-radius:35px;border-color:black;transform: rotate(180deg);"  name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </div>
</ion-header>


<ion-content style="background: #EAEAEA;">
<ion-grid class="ion-no-padding" *ngIf="!isEmpty && sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'default_template'" >
    <app-default-products [products]="products" [isLoading]="loading" style="margin: 20px;"></app-default-products>

</ion-grid>
<ion-grid  *ngIf="isEmpty && !loading">
  <ion-row class="empty-message">
    <ion-icon size="large" name="sad-outline"></ion-icon>
    <ion-label>{{'NO_PRODUCTS_TO_SHOW' | translate}}</ion-label>
  </ion-row>
</ion-grid>

  <app-modern-template-search *ngIf="!isEmpty && sharedService.landingPagetemplate && sharedService.landingPagetemplate == 'modern_template'" ></app-modern-template-search>

</ion-content>