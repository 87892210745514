<ion-grid *ngIf="selectedCategoryId == undefined || childCategories.length == 0" [ngStyle]="{'padding-inline':platform.is('desktop') && sharedService.innerWidth > 1300?'230px':platform.is('desktop') && sharedService.innerWidth > 1150?'120px':'0px'}">
  
  <ion-spinner *ngIf="isLoading" color="primary" name="crescent"  class="spinner">
    
  </ion-spinner>
  <ng-container *ngIf="products.length > 0">
    <ion-row  class="category-container">
      <ion-col size="6">
        <ion-label *ngIf="category" class="category-name">{{category.name}}ss</ion-label>
        <ion-label *ngIf="!category" class="category-name">{{'ALL_PRODUCT' | translate}}</ion-label>
      </ion-col>
      <ion-col size="6" class="view-more">
        <ion-button fill="clear" (click)="viewProducts(category)">
          <ion-label *ngIf="category">{{'VIEW_MORE' | translate}}</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
    
    <ion-row *ngIf="!category">
      <ion-col *ngFor="let product of products" #productSize [size]="sharedService.innerWidth <= 800?'6':sharedService.innerWidth <= 1000?'4':'3'"
        style="text-align: center;">
        <ion-row style="display: block">
          <ion-row style="display: flex;justify-content: center;">
            <ion-card mode="ios" (click)="openQuickOrder(product.id)">
              <div class="image"
              [ngStyle]="{'background': 'url('+env.url+'/web/image/storex_modules.product/'+product.id+'/image'+') center center / cover no-repeat'}" 
               ></div>
            </ion-card>
          </ion-row>
        </ion-row>
        <ion-row  style="display: flex;justify-content: center;">
          <ion-label class="product-name">
            {{product.name}}
          </ion-label>
        </ion-row>
        <ion-row *ngIf="product.meta_description">
          <ion-col size="12" class="description">
            <ion-label>{{product.meta_description}}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row style="display: flex;justify-content: center">
          
          <ion-col size="6">
            <ion-label class="product-price">{{product.currency[0] }}{{product.price | number : '1.2-2'}}</ion-label>
          </ion-col>
          <ion-col size="6">
            <button (click)="qtyPlus(product.id)" [ngStyle]="{'background': product.addedToCart?'black':'#d9d9d9'}" style="border-radius: 7px;">
              <ion-icon *ngIf="!product.addedToCart" src="../../../../assets/icon/shop-add.svg"></ion-icon>
              <ion-icon *ngIf="product.addedToCart" src="../../../../assets/icon/shop-remove.svg"></ion-icon>
            </button>
            
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="category">

      <ion-row>
        <ion-col *ngFor="let product of products,let i =index" #productSize [size]="sharedService.innerWidth <= 800?'6':sharedService.innerWidth <= 1000?'4':'3'"
          style="text-align: center;">
        <ng-container *ngIf="i < 2">
          <ion-row style="display: block">
            <ion-row style="display: flex;justify-content: center;">
              <ion-card mode="ios" (click)="openQuickOrder(product.id)">
                <div class="image"
                [ngStyle]="{'background': 'url('+env.url+'/web/image/storex_modules.product/'+product.id+'/image'+') center center / cover no-repeat'}" 
                 ></div>
              </ion-card>
            </ion-row>
          </ion-row>
          <ion-row  style="display: flex;justify-content: center;">
            <ion-label class="product-name">
              {{product.name}}
            </ion-label>
          </ion-row>
          <ion-row *ngIf="product.meta_description">
            <ion-col size="12" class="description">
              <ion-label>{{product.meta_description}}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row style="display: flex;justify-content: center">
            
            <ion-col size="6">
              <ion-label class="product-price">{{product.currency[0]}}{{product.price | number : '1.2-2'}}</ion-label>
            </ion-col>
            <ion-col size="6">
              <button class="add-to-cart" (click)="qtyPlus(product.id)" [ngStyle]="{'background': product.addedToCart?'black':'#d9d9d9'}" style="border-radius: 7px;">
                <ion-icon *ngIf="!product.addedToCart" src="../../../../assets/icon/shop-add.svg"></ion-icon>
                <ion-icon *ngIf="product.addedToCart" src="../../../../assets/icon/shop-remove.svg"></ion-icon>
              </button>
              
            </ion-col>
          </ion-row>
        </ng-container>
        </ion-col>
      </ion-row>
      <ion-row  class="category-container">
        <ion-col size="6">
          <ion-label class="category-name">{{'RECOMMENDED' | translate}}</ion-label>
        </ion-col>
        <ion-col size="6" class="view-more">
          <ion-button fill="clear">
            <ion-label>{{'VIEW_MORE' | translate}}</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let product of products,let i =index">
        <ng-container *ngIf="i >= 2 && i<4">
            <ion-col style="display: flex;justify-content: center;" size="4">
              <ion-card mode="ios" (click)="openQuickOrder(product.id)">
                <div class="recommend-image"
                [ngStyle]="{'background': 'url('+env.url+'/web/image/storex_modules.product/'+product.id+'/image'+') center center / cover no-repeat'}" 
                 ></div>
              </ion-card>
            </ion-col>
            <ion-col size="8">
              <ion-row  style="display: flex;margin-block: 12px;">
                <ion-label class="product-name">
                  {{product.name}}
                </ion-label>
              </ion-row>
              <ion-row *ngIf="product.meta_description" style="margin-block-end: 12px;">
                  <ion-label>{{product.meta_description}}</ion-label>
              </ion-row>
              <ion-row>
                
                <ion-col size="4">
                  <ion-label class="product-price">{{product.currency[0]}}{{product.price | number : '1.2-2'}}</ion-label>
                </ion-col>
                <ion-col size="6" style="display: flex;justify-content: end;">
                  <button class="add-to-cart" (click)="chooseVariant(product.id)" [ngStyle]="{'background': product.addedToCart?'black':'#d9d9d9'}" style="border-radius: 7px;">
                    <ion-icon *ngIf="!product.addedToCart" src="../../../../assets/icon/shop-add.svg"></ion-icon>
                    <ion-icon *ngIf="product.addedToCart" src="../../../../assets/icon/shop-remove.svg"></ion-icon>
                  </button>
                  
                </ion-col>
              </ion-row>
            </ion-col>
        </ng-container>
      </ion-row>
    </ng-container>
  </ng-container>
</ion-grid>

<ion-infinite-scroll *ngIf="!category" threshold="10px" (ionInfinite)="loadMoreProducts($event)">
  <ion-infinite-scroll-content loadingText="{{ 'LOADING' | translate }}"></ion-infinite-scroll-content>
</ion-infinite-scroll>