import { Action } from '@ngrx/store';
import { IOrderLine } from '../../model';

export enum OrderLineActionsType {
  ADD_LOCAL_HTTP = '[OrderLine] Add Local Http',
  ADD_LOCAL_SUCCESS_HTTP = '[OrderLine] Add Local Http Success',
  ADD_LOCAL_FAIL_HTTP = '[OrderLine] Add Local Http Fail',

  REMOVE_LOCAL_HTTP = '[OrderLine] Remove Local Http',
  REMOVE_LOCAL_SUCCESS_HTTP = '[OrderLine] Remove Local Http Success',
  REMOVE_LOCAL_FAIL_HTTP = '[OrderLine] Remove Local Http Fail',

  CLEAR_CART_LOCAL = '[OrderLine] Clear Cart Local',
  CLEAR_CART_LOCAL_SUCCESS = '[OrderLine] Clear Cart Local Success',
  CLEAR_CART_LOCAL_FAIL = '[OrderLine] Clear Cart Local Fail',

  LOAD_LOCAL_HTTP = '[OrderLine] Load Local Http',
  LOAD_LOCAL_SUCCESS_HTTP = '[OrderLine] Load Local Http Success',
  LOAD_LOCAL_FAIL_HTTP = '[OrderLine] Load Local Http Fail',

  LOAD_HTTP = '[OrderLine] Load Http',
  LOAD_SUCCESS_HTTP = '[OrderLine] Load Http Success',
  LOAD_FAIL_HTTP = '[OrderLine] Load Http Fail',

  UPDATE_LOCAL = '[OrderLine] Update Local ',
  UPDATE_LOCAL_SUCCESS = '[OrderLine] Update Local Success',
  UPDATE_LOCAL_FAIL = '[OrderLine] Update Local Fail',
}

export class AddLocalHttp implements Action {
  readonly type = OrderLineActionsType.ADD_LOCAL_HTTP;
  constructor(public payload: IOrderLine) {}
}

export class AddLocalHttpSuccess implements Action {
  readonly type = OrderLineActionsType.ADD_LOCAL_SUCCESS_HTTP;
  constructor(public payload: IOrderLine) {}
}

export class AddLocalHttpFail implements Action {
  readonly type = OrderLineActionsType.ADD_LOCAL_FAIL_HTTP;
  constructor(public payload: IOrderLine) {}
}

export class RemoveLocalHttp implements Action {
  readonly type = OrderLineActionsType.REMOVE_LOCAL_HTTP;
  constructor(public variantIds: number[]) {}
}

export class RemoveLocalHttpSuccess implements Action {
  readonly type = OrderLineActionsType.REMOVE_LOCAL_SUCCESS_HTTP;
  constructor(public removedIds:number[]) {}
}

export class RemoveLocalHttpFail implements Action {
  readonly type = OrderLineActionsType.REMOVE_LOCAL_FAIL_HTTP;
  constructor(public payload: IOrderLine) {}
}

export class ClearCartLocal implements Action {
  readonly type = OrderLineActionsType.CLEAR_CART_LOCAL;
  constructor() {}
}

export class ClearCartLocalSuccess implements Action {
  readonly type = OrderLineActionsType.CLEAR_CART_LOCAL_SUCCESS;
  constructor() {}
}

export class ClearCartLocalFail implements Action {
  readonly type = OrderLineActionsType.CLEAR_CART_LOCAL_FAIL;
  constructor(public error:any) {}
}


export class LoadLocalHttp implements Action {
  readonly type = OrderLineActionsType.LOAD_LOCAL_HTTP;
  constructor(public payload: any) {}
}

export class LoadLocalHttpSuccess implements Action {
  readonly type = OrderLineActionsType.LOAD_LOCAL_SUCCESS_HTTP;
  constructor(public payload: IOrderLine[]) {}
}

export class LoadLocalHttpFail implements Action {
  readonly type = OrderLineActionsType.LOAD_LOCAL_FAIL_HTTP;
  constructor(public payload: IOrderLine[]) {}
}

export class UpdateLocal implements Action {
  readonly type = OrderLineActionsType.UPDATE_LOCAL;
  constructor(public payload: IOrderLine) {}
}

export class UpdateLocalSuccess implements Action {
  readonly type = OrderLineActionsType.UPDATE_LOCAL_SUCCESS;
  constructor(public payload: IOrderLine) {}
}

export class UpdateLocalFail implements Action {
  readonly type = OrderLineActionsType.UPDATE_LOCAL_FAIL;
  constructor(public payload: IOrderLine) {}
}

export class LoadHttp implements Action {
  readonly type = OrderLineActionsType.LOAD_HTTP;
  constructor(public payload: any) {}
}

export class LoadHttpSuccess implements Action {
  readonly type = OrderLineActionsType.LOAD_SUCCESS_HTTP;
  constructor(public payload: IOrderLine[]) {}
}

export class LoadHttpFail implements Action {
  readonly type = OrderLineActionsType.LOAD_FAIL_HTTP;
  constructor(public payload: IOrderLine[]) {}
}

export type OrderLineActions =
  | AddLocalHttp
  | AddLocalHttpSuccess
  | AddLocalHttpFail
  | RemoveLocalHttp
  | RemoveLocalHttpFail
  | RemoveLocalHttpSuccess
  | ClearCartLocal
  | ClearCartLocalSuccess
  | ClearCartLocalFail
  | LoadLocalHttp
  | LoadLocalHttpFail
  | LoadLocalHttpSuccess
  | UpdateLocal
  | UpdateLocalFail
  | UpdateLocalSuccess
  | LoadHttp
  | LoadHttpFail
  | LoadHttpSuccess