import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { EntityAdapter } from '@ngrx/entity/src/models';
import { ICategory } from '../../model';

export interface CategoryState extends EntityState<ICategory> {
  loading: boolean;
  uploading: boolean;
  offset: number;
  limit: number;
  dataLength: number;
  CategoryRegister:any;
  search: any;
  CategoryTags: [];
}

export const CategoryAdapter: EntityAdapter<ICategory> = createEntityAdapter<ICategory>();

export const initialState: CategoryState = CategoryAdapter.getInitialState({
  uploading: false,
  loading: false,
  offset: 0,
  dataLength: 0,
  limit: 50,
  search: [],
  CategoryRegister:[],
  CategoryTags: []
});
