import { Component, OnInit } from '@angular/core';
import {  ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-language',
  templateUrl: './language.page.html',
  styleUrls: ['./language.page.scss'],
})
export class LanguagePage implements OnInit {


  public languages: any;
  selectedLanguage='ar'
  prviousLanguageId;
  currentLang: any;
  constructor(
    public modalCont: ModalController,
    public translate:TranslateService,
    public storage:Storage,
    public navCtrl: NavController,) {
    this.prviousLanguageId = localStorage.langId;
    this.languages=[
      {
        name:'ARABIC',
        code:'ar'
      },
      {
        name:'ENGLISH',
        code:'en'
      }

    ]

  }
  ngOnInit(){
    this.currentLang=this.translate.currentLang
    this.selectedLanguage=this.currentLang
  }

  updateLanguage(event) {
    let langCode=event.detail.value
    this.storage.set('currentLanguage',langCode)
    window.location.reload()
  }
  dismiss() {
    this.modalCont.dismiss();
  }


}
