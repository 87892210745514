import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OdooAPI } from './services/odoo/services/odooAPI';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SEOService } from 'src/app/services/SEOservice';
import { SharedService } from './services/sharedService';
import { ClientConfigurationState } from './stores/client-configuration/store/state';
import * as clientConfigurationsActions from 'src/app/stores/client-configuration/store/actions';
import * as OrderLineActions from 'src/app/stores/order-line/store/actions'
import { OrderLineState } from 'src/app/stores/order-line/store';
import { OnesignalService } from './services/Onesignal';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  keyboardShown: boolean;
  keyboardHeight: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.sharedService.innerWidth = window.innerWidth;
  }
  countryCode
  env = environment
  productId: any;
  selectedTemplates: any;
  constructor(
    private odooApi: OdooAPI,
    private storage:Storage,
    private translate : TranslateService,
    private sharedService:SharedService,
    private route: ActivatedRoute,
    private clientConfigurationStore: Store<ClientConfigurationState>,
    private orderLineStore : Store<OrderLineState>,
    private oneSignalService: OnesignalService,
    private platform: Platform
  ) {
    this.storage.create();
    this.storage.get('login_info').then(data =>{
      if (data){
        this.sharedService.loginInfo = data
      }
    })
    
    oneSignalService.setupPush()
  }
  initKeyboard(){
    this.platform.keyboardDidShow.subscribe(ev => {
      const { keyboardHeight } = ev;
      this.keyboardHeight=keyboardHeight.toString()
      this.keyboardShown=true
    });
  
    this.platform.keyboardDidHide.subscribe(() => {
      this.keyboardShown=false
    });
  }
  
  async ngOnInit(): Promise<void> {
    if(!this.platform.is('cordova')){
      this.initKeyboard()
    }
    this.sharedService.innerWidth = window.innerWidth;
    if(window.location.href.includes('/user')){
      let userId = window.location.href.split('/user/')[1]
      if(userId){
        userId = userId.split('/')[0]
        this.sharedService.userSharedLink = userId
      }
    }
    if(window.location.href.includes('/ar_SY')){
      this.translate.use('ar')
      document.documentElement.dir = 'rtl';
    }
    else if (window.location.href.includes('/en_US')){
      this.translate.use('en')
      document.documentElement.dir = 'ltr';
    }
    else{
      this.storage.get('currentLanguage').then(currentLanguage =>{
        if(currentLanguage){
          this.translate.use(currentLanguage)
          if(currentLanguage == 'ar'){
            document.documentElement.dir = 'rtl';
          }
          else{
            document.documentElement.dir = 'ltr';
          }
        }
        else{
          this.translate.use('en')
          document.documentElement.dir = 'ltr';
        }

      })
      
    }
    this.storage.get('countryCode').then(countryCode=>{
      if(countryCode && countryCode!="auto"){
        this.odooApi.countryCode=countryCode
        this.countryCode=countryCode
      }else{
          this.odooApi.getIpAddress().subscribe(ip=>{
            this.odooApi.getCountryByIp(ip).subscribe(()=>{
              this.countryCode=this.odooApi.countryCode
            })
          })
      }
    })

    let data ={ 
      model: 'storex_commerce.template_selector', 
      domain: [], 
      offset: 0, 
      limit: 1, 
      order:null,
      fields: ['selected_landing_page_template','selected_home_page_template', 'selected_filter_page_template','selected_package_page_template','selected_quick_order_template','template_type'],
      country_code:false
    }
    this.odooApi.loadPublicRecords(data).subscribe(data => {
          if(data && data.length>0)  {
            this.storage.set('selectedTemplates',data)
            if(data && data[0] && data[0].template_type == 'basic'){
              this.sharedService.templateType = 'basic'
            }
            else if(data && data[0] && data[0].template_type == 'advance'){
              this.sharedService.templateType = 'advance'
            }

            if(data && data[0] && data[0].selected_landing_page_template){
              this.sharedService.landingPagetemplate = data[0].selected_landing_page_template
            }
          }
    })

     

    this.route.queryParams.subscribe(params => {
      if(params && params.domain)
      {
        let domain = JSON.parse(params.domain)
        if(domain && domain.length > 0){
          this.sharedService.productDomian.concat(params.domain)
        }
      }
    })
    this.odooApi.loadPublicRecords({model:'res.company',domain:[],fields:['name','social_facebook','social_instagram','mobile_number'],limit:1,offset:0}).subscribe(data =>{
      this.sharedService.companyInfo = data[0]
    })
    this.clientConfigurationStore.dispatch(new clientConfigurationsActions.LoadPublicHttp());
    this.orderLineStore.dispatch(new OrderLineActions.LoadLocalHttp({}))
    this.storage.get('login_info').then(data=>{
      this.sharedService.loginInfo = data
    })
    }
    

  }
