import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OrderLineState, OrderLineAdapter } from '../state/order-line.state';

export const selectOrderLineState = createFeatureSelector<OrderLineState>('OrderLine');

export const getOrderLineEntities = (state: OrderLineState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getOrderLineLoading = (state: OrderLineState) => state.loading;

export const getOrderLineUploading = (state: OrderLineState) => state.uploading;
export const getOffset = (state: OrderLineState) => state.offset;
export const getLimit = (state: OrderLineState) => state.limit;
export const getDataLength = (state: OrderLineState) => state.dataLength;
export const getSearch = (state: OrderLineState) => state.search;


const {
  
  selectEntities,
  selectAll,
  selectTotal
} = OrderLineAdapter.getSelectors();


export const selectAllData = createSelector(
  selectOrderLineState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectOrderLineState,
  selectEntities
);

export const selectOffset = createSelector(
  selectOrderLineState,
  getOffset
);
export const selectDataLength = createSelector(
  selectOrderLineState,
  getDataLength
);

export const selectLimit = createSelector(
  selectOrderLineState,
  getLimit
);

export const selectDataOrderLineedById = createSelector(
  selectAllData,
  (data: any) => {
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);
export const selectOrderLineById =(id) => createSelector (
  selectAllData,
  
  (data: any) => {
    return data.filter(OrderLine=>OrderLine.id == id);
  }
);
export const selectSearch = createSelector(
  selectOrderLineState,
  getSearch
);

export const selectCartOrderLines = createSelector(
  selectAllData,
  (data:any)=>{
    return data.filter(orderLine=>orderLine.isCart)
  }
);

export const selectAllCartProductIds = createSelector(
  selectAllData,
  (data:any)=>{
    let ids=[]
    data.filter(orderLine=>{
      if(orderLine.isCart){
        ids.push(orderLine.product_id)
      }
    })
    return ids
  }
);
