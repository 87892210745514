import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ClientConfigurationState, clientConfigurationAdapter } from '../state/client-configuration.state';

export const selectClientConfigurationState = createFeatureSelector<ClientConfigurationState>(
  'client-configuration'
);

export const getClientConfigurationEntities = (state: ClientConfigurationState) => {
  // tslint:disable-next-line:no-unused-expression
  state.entities;
};

export const getClientConfigurationLoading = (state: ClientConfigurationState) => state.loading;

export const getClientConfigurationloading = (state: ClientConfigurationState) => state.uploading;
export const getOffset = (state: ClientConfigurationState) => state.offset;
export const getLimit = (state: ClientConfigurationState) => state.limit;
export const getDataLength = (state: ClientConfigurationState) => state.dataLength;

const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = clientConfigurationAdapter.getSelectors();

export const selectAllIDS = createSelector(
  selectClientConfigurationState,
  selectIds
);

export const selectAllData = createSelector(
  selectClientConfigurationState,
  selectAll
);

export const selectAllEntities = createSelector(
  selectClientConfigurationState,
  selectEntities
);

export const selectOffset = createSelector(
  selectClientConfigurationState,
  getOffset
);
export const selectDataLength = createSelector(
  selectClientConfigurationState,
  getDataLength
);

export const selectLimit = createSelector(
  selectClientConfigurationState,
  getLimit
);

export const selectDataClientConfigurationedById = createSelector(
  selectAllData,
  (data: any) => {
    data.sort((a, b) => b.id - a.id);
    return data;
  }
);
