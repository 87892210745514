/*errortype
1 Get user type http fail
2 Authenticattion failur
3 Logout Fail
4 Unknown ( not catched error)
5 Registerd not success
6 load area error
7 update workflow status in order error
8  error adding order
9  ***** TODO NOT_IMPLEMENTED YET [Order] Update Driver Fail
*/
import * as fromErrorActions from '../actions';

import { ErrorState, initialState, errorAdapter } from '../state/error.state';
import { IError } from '../../model';

export function ErrorReducer(state = initialState, action: any): ErrorState {
  switch (action.type) {
    case '[Order] Update Workflow Status Http Fail': {
      const entity = state.entities[7];
      let message = "UPDATE_ORDER_STATUS_FAILED"
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }

      const error: IError = {
        id: '7',
        message: message,
        payload: action.error,
        model: 'order',
        type: 'Update Workflow Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[APP_SETTING] Register Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '5',
        message: 'REGISTRATION_WAS_NOT_SUCCESSFULL',
        payload: JSON.stringify(action.payload),
        model: 'app_setting',
        type: 'Register',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case fromErrorActions.ErrorActionsType.ADD: {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '4',
        message: 'Unknown Error - Contact Us',
        payload: JSON.stringify(action.payload),
        model: 'unknown',
        type: 'unknown',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[APP_SETTING] Authenticate Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Authenticate failur';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.payload && action.payload.code && action.payload.message){
        
        switch (action.payload.message) {
          case 'Mobile number is not registered in the system.' :
            msg = 'USER_NOT_REGISTERED'
            break;
            case 'Email is not registered in the system.' :
              msg = 'USER_NOT_REGISTERED'
              break;  
          case 'Your account is not confirmed yet, please contact your administrator.' :
            msg = 'USER_NOT_CONFIRMED'
            break;  
          case 'User not authorized' :
            msg = 'Authenticate failur'
            break;              
          default:
            msg = 'Authenticate failur'
            break;
        }
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.payload),
        model: 'app_setting',
        type: 'Authenticate',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[APP_SETTING] Get User Type Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '1',
        message: 'Getting User Type Error',
        payload: JSON.stringify(action.payload),
        model: 'app_setting',
        type: 'user_type',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[APP_SETTING] Logout Fail': {
      const entity = state.entities[7];

      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }

      const error: IError = {
        id: '3',
        message: 'Error logout',
        payload: JSON.stringify(action.payload),
        model: 'app_setting',
        type: 'Logout',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Area] Load Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }

      const error: IError = {
        id: '6',
        message: 'Load Area Error',
        payload: JSON.stringify(action.payload),
        model: 'area',
        type: 'Load Area',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Area] Load Public Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }

      const error: IError = {
        id: '6',
        message: 'Load Public Area Error',
        payload: JSON.stringify(action.payload),
        model: 'area',
        type: 'Load Area Public',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Add Update Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Add Order',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Update Order] Update Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'UPDATE_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Update Order',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[APP_SETTING] Register Fail': {
      const entity = state.entities[9];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }

      const error: IError = {
        id: '9',
        message: 'REGISTRATION_FAILED_MOBILE_NUMBER_EXISTS',
        payload: JSON.stringify(action.payload),
        model: 'Setting',
        type: 'Register',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Order] Load Http Fail': {
      const entity = state.entities[10];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '10',
        message: 'LOAD_ORDER_ERROR',
        payload: action.error,
        model: 'Setting',
        type: 'Load Order',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Status] Load Http Fail': {
      const entity = state.entities[10];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '10',
        message: 'LOAD_STATUS_ERROR',
        payload: action.error,
        model: 'Setting',
        type: 'Load Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[App seting] Load Http Fail': {
      const entity = state.entities[10];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '10',
        message: 'LOAD_APP_ERROR',
        payload: action.error,
        model: 'Setting',
        type: 'add_http',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[APP_SETTING] Get User Type Http Success': {
      // should clear the state
      return errorAdapter.removeOne(1, state);
    }
    case '[Error] Clear User Type Error': {
      // should clear the state
      return errorAdapter.removeOne(1, state);
    }
    case '[Order Tracking] Get Order Status History Http Success Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }

      const error: IError = {
        id: '6',
        message: action.error.Message,
        payload: JSON.stringify(action.error.Message),
        model: 'Order Tracking',
        type: 'Order Tracking',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Order Tracking] Get Order Status History Http Fail': {
      const entity = state.entities[10];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }
      const error: IError = {
        id: '10',
        message: 'Unknown Error - Contact Us',
        payload: action.error,
        model: 'Setting',
        type: 'Order Tracking',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Add Driver Http Success Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      if (entity) {
        repeated = entity.repeated;
      }

      const error: IError = {
        id: '6',
        message: action.error,
        payload: JSON.stringify(action.error),
        model: 'Order Tracking',
        type: 'Order Add Driver',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Add Driver Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'ADD_ORDER_FAIL';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Order Add Driver',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[User] Change Password Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: 'Unknown Error - Contact Us',
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Change Password',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[KashefElTahseel] Change Money Collector Status Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Change Money Collector Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[KashefElTahseel] Update Driver Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Collection Update Driver',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[User] Add Update Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '30',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'User',
        type: 'Add User',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Route] Add Update Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Update Route',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[ReturnedMoneyCollection] Change Returned Money Collector Status Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Change Returned Money Collector Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[User] Forgot Password Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error['result'] && action.error['result'].message){
        msg = action.error['result'].message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'user',
        type: 'Forgot Password ',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[AgentMoneyCollection] Change Agent Money Collector Status Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Change Agent Money Collector Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[AgentReturnedCollection] Change Agent Returned Collector Status Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Change Agent Returned Collector Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Add Customer Payment Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Add Customer Payment',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    
    case '[Order] Update Workflow Payment Type Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Workflow Payment Type',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Update Workflow Stuck And Payment Type Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Workflow Stuck And Payment Type',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Update Workflow Returned Service Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Workflow Returned Service ',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Order] Update Workflow Returned Service And Payment Type Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Workflow Returned Service And Payment Type',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Update Workflow Returned Service And Stuck And Payment Type Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Workflow Returned Service And Stuck And Payment Type',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Order] Update Workflow Returned Service And Stuck Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Workflow Returned Service And Stuck And Payment Type',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[Pickup Request Orders] Load Pickup Request Orders Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Load Pickup Request Orders',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }

    case '[PickupRequest] Add Update Http Fail': {
      const entity = state.entities[2];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Create Pickup Request',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    
    case '[PickupRequest] Load Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'app_setting',
        type: 'Load Pickup Request',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[PickupRequest] Update Workflow Status With Check Orders Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'PickupRequest',
        type: 'Update Work Flow Pickup Request',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[PickupRequest] Update Workflow Status Http Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'PickupRequest',
        type: 'Update Work Flow Pickup Request',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[PickupRequest] Update Workflow Status With Check Orders Http Success Fail': {
      const entity = state.entities[7];
      let repeated = 0;
      let msg = 'Unknown Error - Contact Us';
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        msg = action.error.message     
      }
      const error: IError = {
        id: '2',
        message: msg,
        payload: JSON.stringify(action.error),
        model: 'PickupRequest',
        type: 'Update Work Flow Pickup Request',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[Order] Update Workflow Reschedule Status Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'order',
        type: 'Update Workflow Reschedule Status',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[ResGroups] Load Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_ORDER_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'Groups',
        type: 'Load Groups',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[DeliveryCompany] Load Http Fail': {
      const entity = state.entities[8];
      let repeated = 0;
      let message = 'ADD_DELIVERY_COMPANY_FAIL'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '8',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'Delivery Company',
        type: 'Load Delivery Company',
        repeated: ++repeated
      };
      return errorAdapter.upsertOne(error, state);
    }
    case '[APP_SETTING] OliverySetupCode Fail': {
      const entity = state.entities[20];
      let repeated = 0;
      let message = 'INCORRECT_CODE_USED_PLEASE_CHECK_THE_CODE'
      if (entity) {
        repeated = entity.repeated;
      }
      if(action && action.error && action.error.message){
        message = action.error.message
      }
      const error: IError = {
        id: '20',
        message: message,
        payload: JSON.stringify(action.payload),
        model: 'Olivery',
        type: 'COMPANY_CODE',
        repeated: ++repeated
      };
      return errorAdapter.addMany([error], state);
    }
    


    

  }
  return state;
}
